import { type FilterItem } from "@components/navigation/LbMainDrawer/partials/LbWishListFilters/LbWishlistFilters"
import { AddCircle, RemoveCircle } from "@mui/icons-material"
import { Box, Chip, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { upperCaseFirstLetter } from "utils/globals"

export interface LbWishlistFiltersCardProps {
  label?: string
  filterItem?: FilterItem
  variant?: "filled" | "outlined"
  readonly?: boolean
  py?: number
}

export const LbWishlistFiltersCard = (props: LbWishlistFiltersCardProps) => {
  const { label, filterItem, variant = "filled", readonly, py = 2 } = props

  const [selectedChips, setSelectedChips] = useState<string[]>([])

  useEffect(() => {
    const allLabels = filterItem?.items?.map((value) => value.label)

    if (allLabels) setSelectedChips(allLabels)
  }, [filterItem])

  const toggleChip = (chipLabel: string) => {
    if (selectedChips.includes(chipLabel)) {
      setSelectedChips((prev) => prev.filter((label) => label !== chipLabel))
    } else {
      setSelectedChips((prev) => [...prev, chipLabel])
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        px: variant === "outlined" ? 0 : 3,
        py,
        backgroundColor: variant === "outlined" ? "transparent" : "paper",
      }}
    >
      {label && (
        <Typography
          fontFamily="Hanken Grotesk"
          fontWeight="bold"
          variant="subtitle2"
        >
          {label}
        </Typography>
      )}

      <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {filterItem?.items?.map((value, index) => (
          <Chip
            sx={{
              opacity: selectedChips.includes(value.label) ? 1 : 0.5,
              pointerEvents: readonly ? "none" : "auto",
              fontWeight: 700,
            }}
            avatar={
              readonly ? undefined : selectedChips.includes(value.label) ? (
                <RemoveCircle />
              ) : (
                <AddCircle />
              )
            }
            key={value.label + index}
            label={upperCaseFirstLetter(value.label)}
            variant={readonly ? "filled" : "outlined"}
            onClick={() => toggleChip(value.label)}
            color={value?.highlighted ? "primary" : "default"}
          />
        ))}
      </Box>
    </Paper>
  )
}
