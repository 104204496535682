import { type RootState } from "@redux/store"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { type PostAuthPasswordResetChangePasswordApiArg } from "./leadbayApi"

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_LEADBAY_API_BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    postAuthPasswordResetChangePassword: builder.mutation<
      Response,
      {
        payload: PostAuthPasswordResetChangePasswordApiArg
        headers: Record<string, string>
      }
    >({
      query: ({ payload, headers = {} }) => ({
        url: "/auth/password_reset/change_password",
        method: "POST",
        headers,
        body: payload.passwordReset,
      }),
    }),

    postAuthVerifyEmail: builder.mutation<
      Response,
      {
        headers: Record<string, string>
      }
    >({
      query: ({ headers = {} }) => ({
        url: "/auth/verify_email",
        method: "POST",
        headers,
      }),
    }),

    finishOauthHubspot: builder.mutation<
      Response,
      {
        code: string
      }
    >({
      query: ({ code }) => ({
        url: `/hubspot/oauth/callback?code=${code}`,
      }),
    }),
  }),
})
