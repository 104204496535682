import { type LeadStatus, type Mappings } from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL } from "@constants/index"
import { Box, Modal, TextField, Typography } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { LbStatusRow } from "../../../../../../components/display/LbStatusRow/LbStatusRow"

export interface EditDataSourceModalProps
  extends Omit<
    Parameters<typeof useEditDataSource>[0],
    "onSaveSuccess" | "onSaveError"
  > {
  icon: JSX.Element
  open: boolean
  onClose: (config?: { noConfirm: boolean }) => void
}

export const EditIntegrationModal = ({
  icon,
  open,
  onClose,
  ...data
}: EditDataSourceModalProps) => {
  const { isSaving, name, mappings, setName, setMapping, save, canSave } =
    useEditDataSource({
      ...data,
      onSaveSuccess: onClose,
      onSaveError: () => {},
    })

  return (
    <Modal onClose={() => onClose()} open={open} sx={{ ...CENTERED_FLEX_COL }}>
      <Box
        className="hide-scrollbar"
        sx={{
          position: "relative",
          backgroundColor: "white",
          width: "100%",
          maxWidth: "900px",
          maxHeight: "90vh",
          borderRadius: "20px",
          py: "40px",
          px: 4,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontFamily="Hanken Grotesk">
            Edit data sources
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box flexShrink={0} sx={{ display: "flex", alignItems: "center" }}>
            {icon}
          </Box>

          <TextField
            fullWidth
            label="Data source name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6" fontFamily="Hanken Grotesk">
            Status mapping
          </Typography>
          {Object.entries(mappings ?? {}).map(
            ([field, mappingField], index) => (
              <LbStatusRow
                key={field + index}
                value={{ [field]: mappingField }}
                onChange={(mapping) =>
                  mapping && setMapping(...Object.entries(mapping)[0])
                }
                row={{
                  title: field,
                  userFiled: [mappingField],
                }}
              />
            ),
          )}
        </Box>

        <Box component="footer">
          <LbButton
            loading={isSaving}
            size="large"
            onClick={save}
            disabled={!canSave}
          >
            Save
          </LbButton>
        </Box>
      </Box>
    </Modal>
  )
}

function useEditDataSource({
  onSaveSuccess,
  onSaveError,
  ...data
}: {
  name: string
  mappings: Mappings["statuses"]
  save: ({
    name,
    mappings,
  }: {
    name: string
    mappings: Mappings["statuses"]
  }) => Promise<void>
  onSaveSuccess: () => void
  onSaveError: () => void
}) {
  const [name, setName] = useState(data.name)

  const [mappings, setMappings] = useState<Mappings["statuses"]>(data.mappings)
  const setMapping = useCallback((field: string, value: LeadStatus) => {
    setMappings((prev) => ({ ...prev, [field]: value }))
  }, [])

  const [isSaving, setIsSaving] = useState(false)
  const save = useCallback(async () => {
    try {
      setIsSaving(true)
      await data.save({ name, mappings })
      onSaveSuccess()
    } catch (error) {
      onSaveError()
    } finally {
      setIsSaving(false)
    }
  }, [name, mappings])

  const canSave = useMemo(
    () =>
      !isSaving &&
      name.length > 0 &&
      Object.values(mappings).every((mapping) => mapping.length > 0),
    [isSaving, name, mappings],
  )

  return {
    isSaving,
    name,
    mappings,
    setName,
    setMapping,
    save,
    canSave,
  }
}
