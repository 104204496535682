import {
  leadbayApi,
  usePostAuthLogoutMutation,
  type PostAuthLogoutApiArg,
} from "@api/leadbayApi"
import { setToken, setUser } from "@redux/authSlice"
import localforage from "localforage"

import { ROUTES } from "@constants/index"
import { setCurrentLensId } from "@redux/commonsSlice"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch } from "./useAppDispatch"

export const useLogout = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [authLogout] = usePostAuthLogoutMutation()

  const logout = async (toastContent?: string) => {
    try {
      const payload: PostAuthLogoutApiArg = {
        authLogoutPostRequest: {
          all: true,
        },
      }

      await authLogout(payload).unwrap()

      if (toastContent) toast(toastContent)
    } catch (error) {
      console.log(error)

      toast.error("An unexpected error occurred.")
    } finally {
      dispatch(setToken(undefined))
      dispatch(setUser(undefined))
      dispatch(setCurrentLensId(""))

      await localforage.clear()

      dispatch(leadbayApi.util.resetApiState())

      navigate(ROUTES.ROOT + ROUTES.PUBLIC.LOGIN)
    }
  }

  return { logout }
}
