import { useGetLensesQuery } from "@api/leadbayApi"
import { DEFAULT_LENS } from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Tune } from "@mui/icons-material"
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  type SelectChangeEvent,
} from "@mui/material"
import { selectAuthState } from "@redux/authSlice"
import {
  selectCommonsState,
  setCurrentLensId,
  toggleNavDrawer,
} from "@redux/commonsSlice"
import { nameLens } from "@utils/renameDefaultLens"
import { useMemo } from "react"

export const LbLensSelector = () => {
  const { token } = useAppSelector(selectAuthState)

  const { data: lenses } = useGetLensesQuery(undefined, {
    skip: !token,
  })

  const { currentLensId } = useAppSelector(selectCommonsState)

  const dispatch = useAppDispatch()

  const handleChangeLens = async (event: SelectChangeEvent) => {
    if (event.target.value) {
      dispatch(setCurrentLensId(event.target.value))
    }
  }

  const userLenses = useMemo(
    () =>
      lenses
        ? [...lenses].sort((a, b) => (a.name === DEFAULT_LENS ? 1 : -1))
        : [],
    [lenses],
  )

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <FormControl sx={{ width: "100%" }} size="small">
        <InputLabel>Lenses</InputLabel>

        <Select
          value={currentLensId}
          label="Lense"
          onChange={handleChangeLens}
          sx={{
            fontSize: "0.9rem",
            height: "2.5rem",
          }}
        >
          {userLenses.map((lens) => (
            <MenuItem
              sx={{
                fontSize: "0.9rem",
              }}
              key={lens.id}
              value={lens.id}
            >
              {nameLens(lens.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip title="Lens settings" placement="left">
        <IconButton
          sx={{
            alignSelf: "center",
            borderRadius: "50%",
            height: "2.5rem",
            width: "2.5rem",
          }}
          color="primary"
          size="small"
          onClick={() =>
            dispatch(
              toggleNavDrawer({
                isOpen: true,
                partial: "LENS_CRUD",
              }),
            )
          }
        >
          <Tune />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
