import { useGetUsersMeQuery } from "@api/leadbayApi"
import { LbLottie } from "@components/animations/LbLottie/LbLottie"
import { CENTERED_FLEX_COL, ROUTES } from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { useQuery } from "@hooks/useQuery"
import { Box, Typography } from "@mui/material"
import { selectAuthState, setUser } from "@redux/authSlice"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

export const VerifyEmail = () => {
  const query = useQuery()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { token: appUserToken, user } = useAppSelector(selectAuthState)

  const [isVerified, setIsVerified] = useState(false)

  const { data: userData } = useGetUsersMeQuery(undefined, {
    skip: !appUserToken,
    pollingInterval: 2000,
  })

  const redirectUser = async () => {
    dispatch(setUser(userData))

    navigate(ROUTES.ROOT + ROUTES.PRIVATE.ONBOARDING)
  }

  useAsyncEffect(async () => {
    if (isVerified || userData?.verified) {
      navigate(ROUTES.ROOT + ROUTES.PRIVATE.ONBOARDING)

      return
    }

    const token = query.get("token")

    if (!token) {
      navigate(ROUTES.ROOT)

      toast.error("Verify token not found")

      return
    }

    const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/auth/verify_email`

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.ok) {
      setIsVerified(true)

      await redirectUser()

      return
    }

    if (!response.ok) {
      const data = await response.json()

      if (data.error && data.error.code === "already_verified") {
        await localforage.clear()

        navigate(ROUTES.ROOT + ROUTES.PUBLIC.LOGIN)

        toast("Email already verified, please login")

        setIsVerified(true)

        return
      }

      navigate(ROUTES.ROOT)

      toast.error("An error occurred while verifying email")
    }
  }, [])

  if (user?.organization?.onboarding_step === "FINISHED") {
    return <Navigate to={ROUTES.APP} />
  }

  if (user?.verified || isVerified || userData?.verified) {
    return <Navigate to={ROUTES.ROOT + ROUTES.PRIVATE.ONBOARDING} />
  }

  return (
    <Box sx={{ CENTERED_FLEX_COL }} id="verify-email-screen">
      <LbLottie type="loading" loop style={{ width: 350 }} />

      <Typography
        sx={{ textAlign: "center" }}
        className="animate-pulse"
        color="text.secondary"
      >
        Please wait while we verify your email
      </Typography>
    </Box>
  )
}
