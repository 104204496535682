import { CENTERED_FLEX_ROW } from "@constants/index"
import { Box, Typography } from "@mui/material"
import { LightLead } from "@typing/index"
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps"

interface MarkerWithInfoWindowProps {
  position: google.maps.LatLngLiteral
  lead: LightLead
  onClick?: () => void
}

export const LbMarker = ({
  position,
  lead,
  onClick,
}: MarkerWithInfoWindowProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef()

  return (
    <>
      <AdvancedMarker
        onClick={onClick}
        ref={markerRef}
        position={position}
        title={lead.name}
      />

      <InfoWindow anchor={marker} maxWidth={300} headerDisabled>
        <Box className="map-marker" sx={{ ...CENTERED_FLEX_ROW, gap: 1 }}>
          {lead.logo && (
            <img
              style={{
                objectFit: "contain",
                height: "30px",
                marginRight: "8px",
                borderRadius: "5px",
              }}
              src={lead.logo}
              alt={lead.name}
            />
          )}

          <Typography fontWeight="bold" variant="body2">
            {lead.name}
          </Typography>
        </Box>
      </InfoWindow>
    </>
  )
}
