import { recalculatePageSize } from "@redux/commonsSlice"
import { useEffect } from "react"
import { useAppDispatch } from "./useAppDispatch"

export const useGetWindowSize = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(recalculatePageSize())
    })
  }, [dispatch])
}
