import { LbButton } from "@components/feedback/LbButton/LbButton"
import { useAppSelector } from "@hooks/useAppSelector"
import { useCheckLense } from "@hooks/useCheckLense"
import { Add } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { selectCommonsState } from "@redux/commonsSlice"
import { useMemo } from "react"

export const LbCreateDraftLensButton = () => {
  const { currentLensId } = useAppSelector(selectCommonsState)

  const { checkIfDraftLensNeeded, findDefaultDraftLens } = useCheckLense()

  const draftExists = useMemo(
    () => !!findDefaultDraftLens()?.id,
    [currentLensId],
  )

  const createDraftLens = async () => {
    await checkIfDraftLensNeeded()
  }

  return (
    <Box sx={{ mt: 2.5, mb: 2.5 }}>
      <LbButton
        onClick={createDraftLens}
        startIcon={!draftExists && <Add />}
        size="small"
      >
        {draftExists ? "Go to draft lens" : "Create draft lens"}
      </LbButton>

      <Typography
        component="p"
        sx={{ mt: 1, mr: 10, ml: 0.5 }}
        color="text.secondary"
        variant="caption"
      >
        {draftExists
          ? "You have a draft lens based on your default lens, go to draft lens to edit it"
          : "This view is read-only. To apply filters using the default settings, please create a draft lens"}
      </Typography>
    </Box>
  )
}
