import { usePostInteractionsMutation, type Interaction } from "@api/leadbayApi"
import { useCallback } from "react"

export const useRecordUserInteractions = () => {
  const [postInteractions] = usePostInteractionsMutation()

  const handleRecordUserInteractions = useCallback(
    async (interactions: Interaction[]) => {
      try {
        await postInteractions({ body: interactions })
      } catch (error) {
        console.error(error)
      }
    },
    [postInteractions],
  )

  return { handleRecordUserInteractions }
}
