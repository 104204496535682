import {
  useGetCrmCustomFieldsQuery,
  useGetImportsByImportIdQuery,
  usePostImportsByImportIdUpdateFieldMappingsMutation,
  type FieldMappings,
} from "@api/leadbayApi"
import { LbMappingRow } from "@components/display/LbMappingRow/LbMappingRow"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { MAPPING_STEP } from "@constants/index"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box, Tooltip, Typography } from "@mui/material"
import { selectOnboardingState, setMappingStep } from "@redux/onboardingSlice"
import { Step } from "@screens/onboarding/OnboardingScreen/partials/Step/Step"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { useEffect, useReducer, useState } from "react"
import { getHintForKey } from "utils/globals"

export const YourFields = () => {
  const { importId } = useAppSelector(selectOnboardingState)

  const { data: status, refetch } = useGetImportsByImportIdQuery(
    {
      importId: importId!,
    },
    { skip: !importId },
  )
  const dispatch = useAppDispatch()
  const { data: customFields = [] } = useGetCrmCustomFieldsQuery()

  const [crmUpdateFieldMappings, { isSuccess, error }] =
    usePostImportsByImportIdUpdateFieldMappingsMutation()

  const [statusFieldHints, setStatusFieldHints] = useState<
    Record<string, string> | undefined
  >(undefined)

  const [statusFields, setStatusFields] = useState<
    Record<string, string> | undefined
  >(undefined)

  const [loading, setLoading] = useState(false)
  const [checkStatus, setCheckStatus] = useState(false)
  const [name, setName] = useState<string | null>(
    getHintForKey("LEAD_NAME", statusFieldHints),
  )
  const [fieldStatus, setFieldStatus] = useState<string | null>(
    getHintForKey("LEAD_STATUS", statusFieldHints),
  )
  const [website, setWebsite] = useState<string | null>(
    getHintForKey("LEAD_WEBSITE", statusFieldHints),
  )
  const [location, setLocation] = useState<string | null>(
    getHintForKey("LEAD_LOCATION", statusFieldHints),
  )
  const [email, setEmail] = useState<string | null>(
    getHintForKey("EMAIL", statusFieldHints),
  )
  const [streetNumber, setStreetNumber] = useState<string | null>(
    getHintForKey("LEAD_LOCATION_STREET_NUM", statusFieldHints),
  )
  const [street, setStreet] = useState<string | null>(
    getHintForKey("LEAD_LOCATION_STREET", statusFieldHints),
  )
  const [postCode, setPostCode] = useState<string | null>(
    getHintForKey("LEAD_LOCATION_POST_CODE", statusFieldHints),
  )
  const [city, setCity] = useState<string | null>(
    getHintForKey("LEAD_LOCATION_CITY", statusFieldHints),
  )
  const [customFieldsMapping, setCustomFieldsMapping] = useReducer(
    (
      state: Record<string, string | null>,
      action: { name: string; value: string | null },
    ) => ({
      ...state,
      [action.name]: action.value,
    }),
    {},
  )

  const [selectedFields, setSelectedFields] = useState<
    Record<string, string | null>
  >({
    name,
    fieldStatus,
    website,
    location,
    email,
    streetNumber,
    street,
    postCode,
    city,
  })

  const [availableFields, setAvailableFields] = useState<string[]>([])

  useEffect(() => {
    setStatusFieldHints(status?.pre_processing?.hints?.fields)
    setStatusFields(status?.pre_processing?.samples?.[0])

    const fieldsArray = statusFields ? Object.keys(statusFields) : []

    setAvailableFields(
      fieldsArray.filter(
        (field) => !Object.values(selectedFields).includes(field),
      ),
    )
  }, [status, statusFields])

  const setCustomFieldMapping = (name: string) => (value: string | null) =>
    setCustomFieldsMapping({ name, value })

  const { data: crmStatusData } = useGetImportsByImportIdQuery(
    {
      importId: importId!,
    },
    {
      pollingInterval: 2000,
      skip: !checkStatus,
    },
  )

  const [showMore, setShowMore] = useState(false)

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  const handleSetField = (
    field: string | null,
    previousField: string | null = null,
    fieldName: string,
  ) => {
    setSelectedFields((prev) => ({
      ...prev,
      [fieldName]: field,
    }))

    setAvailableFields((prev) => {
      const updatedFields = new Set(prev)

      if (field) updatedFields.delete(field)
      if (previousField) updatedFields.add(previousField)

      return Array.from(updatedFields).reverse()
    })
  }

  const handleNext = async () => {
    try {
      setLoading(true)

      if (!name) return

      const testExist = (field: string | null) => field && field.length > 0

      const generateFieldMapping = (
        field: string | null,
        mappingValue: string,
      ) => (testExist(field) ? { [field as string]: mappingValue } : {})

      const fieldMappings = {
        fields: {
          [name]: "LEAD_NAME",
          ...generateFieldMapping(fieldStatus, "LEAD_STATUS"),
          ...generateFieldMapping(website, "LEAD_WEBSITE"),
          ...generateFieldMapping(location, "LEAD_LOCATION"),
          ...generateFieldMapping(email, "EMAIL"),
          ...generateFieldMapping(streetNumber, "LEAD_LOCATION_STREET_NUM"),
          ...generateFieldMapping(street, "LEAD_LOCATION_STREET"),
          ...generateFieldMapping(postCode, "LEAD_LOCATION_POSTCODE"),
          ...generateFieldMapping(city, "LEAD_LOCATION_CITY"),
          ...Object.fromEntries(
            Object.entries(customFieldsMapping).map(([key, value]) => [
              value,
              `CUSTOM.${key}`,
            ]),
          ),
        },
      } as unknown as FieldMappings

      await crmUpdateFieldMappings({
        importId: importId!,
        fieldMappings,
      })
    } catch (error) {
      setLoading(false)

      console.error(error)
    } finally {
      setCheckStatus(true)
    }
  }

  useAsyncEffect(async () => {
    if (!crmStatusData?.mappings?.fields) return

    await localforage.setItem(MAPPING_STEP, 1)

    setTimeout(async () => {
      await refetch()

      dispatch(setMappingStep(1))

      setLoading(false)
    }, 2000)
  }, [crmStatusData])

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      statusCodeConfig: {
        400: {
          message: {
            text: "Bad Request",
            type: "error",
          },
          action: () => {
            setLoading(false)
          },
        },
        401: {
          message: {
            text: "Missing or invalid Authorization header, or invalid token",
            type: "error",
          },
          action: () => {
            setLoading(false)
          },
        },
        403: {
          message: {
            text: "Forbidden",
            type: "error",
          },
          action: () => {
            setLoading(false)
          },
        },
      },
    },
  })

  return (
    <Step
      stepIndex={2}
      title={`We are going to match your CRM data together (1/2) 📊`}
      ctaTitle="Submit your fields"
      onValidate={handleNext}
      ctaLoading={loading}
      ctaDisabled={loading || !name}
      sx={{
        margin: "0 auto",
      }}
    >
      <Box
        className="hide-scrollbar"
        sx={{
          display: "flex",
          gap: 1.5,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            paddingLeft: "35px",
          }}
        >
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              fontSize: "14px",
              width: 160,
            }}
          >
            Our field
          </Typography>

          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              width: 225,
              fontSize: "14px",
            }}
          >
            Your field
          </Typography>

          <Tooltip title="Sample values corresponding to these criteria can be aligned with our specified fields.">
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{
                ml: 5.5,
                fontSize: "14px",
              }}
            >
              Sample value
            </Typography>
          </Tooltip>
        </Box>

        <LbMappingRow
          value={name}
          samples={status?.pre_processing?.samples}
          onChange={(value: string | null) => {
            if (value === null) return

            handleSetField(value, selectedFields["name"], "name")
            setName(value)
          }}
          row={{
            title: "Company",
            userFiled: availableFields,
            hint: getHintForKey("LEAD_NAME", statusFieldHints),
            tooltip: "The name of your lead",
          }}
        />

        <LbMappingRow
          value={fieldStatus}
          samples={status?.pre_processing?.samples}
          onChange={(value: string | null) => {
            if (value === null) return

            handleSetField(value, selectedFields["fieldStatus"], "fieldStatus")
            setFieldStatus(value)
          }}
          row={{
            title: "Status",
            userFiled: availableFields,
            hint: getHintForKey("LEAD_STATUS", statusFieldHints),
            tooltip: "The status of your lead, if it's new or not",
          }}
        />

        <LbMappingRow
          value={website}
          samples={status?.pre_processing?.samples}
          onChange={(value: string | null) => {
            if (value === null) return

            handleSetField(value, selectedFields["website"], "website")

            setWebsite(value)
          }}
          row={{
            title: "Website",
            userFiled: availableFields,
            hint: getHintForKey("LEAD_WEBSITE", statusFieldHints),
            tooltip: "The website of your lead, ",
          }}
        />

        <LbMappingRow
          value={location}
          samples={status?.pre_processing?.samples}
          onChange={(value: string | null) => {
            if (value === null) return

            handleSetField(value, selectedFields["location"], "location")
            setLocation(value)
          }}
          row={{
            title: "Complete location",
            userFiled: availableFields,
            hint: getHintForKey("LEAD_LOCATION", statusFieldHints),
            tooltip: "The location of your lead",
          }}
        />

        {customFields.map((customField) => (
          <LbMappingRow
            key={customField.id}
            value={customFieldsMapping[customField.id]}
            samples={status?.pre_processing?.samples}
            onChange={() => {
              setCustomFieldMapping(customField.id)

              handleSetField(
                customField.id,
                selectedFields[customField.id],
                customField.id,
              )
            }}
            row={{
              title: customField.name,
              userFiled: availableFields,
              tooltip: "",
            }}
          />
        ))}

        {showMore && (
          <>
            <LbMappingRow
              value={streetNumber}
              samples={status?.pre_processing?.samples}
              onChange={(value: string | null) => {
                if (value === null) return

                handleSetField(
                  value,
                  selectedFields["streetNumber"],
                  "streetNumber",
                )
                setStreetNumber(value)
              }}
              row={{
                title: "Street number",
                userFiled: availableFields,
                hint: getHintForKey(
                  "LEAD_LOCATION_STREET_NUM",
                  statusFieldHints,
                ),
                tooltip: "The street number of your lead",
              }}
            />

            <LbMappingRow
              value={street}
              samples={status?.pre_processing?.samples}
              onChange={(value: string | null) => {
                if (value === null) return

                handleSetField(value, selectedFields["street"], "street")
                setStreet(value)
              }}
              row={{
                title: "Street",
                userFiled: availableFields,
                hint: getHintForKey("LEAD_LOCATION_STREET", statusFieldHints),
                tooltip: "The street of your lead",
              }}
            />

            <LbMappingRow
              value={postCode}
              samples={status?.pre_processing?.samples}
              onChange={(value: string | null) => {
                if (value === null) return

                handleSetField(value, selectedFields["postCode"], "postCode")
                setPostCode(value)
              }}
              row={{
                title: "Post code",
                userFiled: availableFields,
                hint: getHintForKey(
                  "LEAD_LOCATION_POST_CODE",
                  statusFieldHints,
                ),
                tooltip: "The post code of your lead",
              }}
            />

            <LbMappingRow
              value={city}
              samples={status?.pre_processing?.samples}
              onChange={(value: string | null) => {
                if (value === null) return

                handleSetField(value, selectedFields["city"], "city")
                setCity(value)
              }}
              row={{
                title: "City",
                userFiled: availableFields,
                hint: getHintForKey("LEAD_LOCATION_CITY", statusFieldHints),
                tooltip: "The city of your lead",
              }}
            />

            <LbMappingRow
              value={email}
              samples={status?.pre_processing?.samples}
              onChange={(value: string | null) => {
                if (value === null) return

                handleSetField(value, selectedFields["email"], "email")
                setEmail(value)
              }}
              row={{
                title: "Email",
                userFiled: availableFields,
                hint: getHintForKey("EMAIL", statusFieldHints),
                tooltip: "The email of your lead",
              }}
            />
          </>
        )}

        <Box>
          <LbButton
            onClick={handleShowMore}
            sx={{
              textTransform: "none",
            }}
          >
            {showMore ? "Show less fields" : "Show more fields"}
          </LbButton>
        </Box>
      </Box>
    </Step>
  )
}
