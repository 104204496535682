import {
  useGetGeoSearchQuery,
  useGetKeywordsSearchQuery,
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
  useGetLensesByLensIdScoringQuery,
  useGetSectorsAllQuery,
  usePostLensesByLensIdScoringMutation,
  type LeadStatus,
  type ScoringCriterion,
} from "@api/leadbayApi"
import { CENTERED_FLEX_COL } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import { useCheckLense } from "@hooks/useCheckLense"
import { Add } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Popper,
  TextField,
  Typography,
  type PopperProps,
} from "@mui/material"
import { selectCommonsState } from "@redux/commonsSlice"
import { analytics } from "@utils/analytics"
import {
  findLocationByValue,
  generateLocationString,
  parseLocationString,
} from "@utils/generateWishListRows"
import { useAsyncEffect, useDebounceFn } from "ahooks"

import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

const fixedOptions = [
  {
    label: `Paris, Île-de-France, FR`,
    value: `Paris, Île-de-France, FR`,
  },
  {
    label: `Lyon, Auvergne-Rhône-Alpes, FR`,
    value: `Lyon, Auvergne-Rhône-Alpes, FR`,
  },
  {
    label: `Marseille, Provence-Alpes-Côte d'Azur, FR`,
    value: `Marseille, Provence-Alpes-Côte d'Azur, FR`,
  },
  {
    label: `Toulouse, Occitanie, FR`,
    value: `Toulouse, Occitanie, FR`,
  },

  {
    label: `Nice, Provence-Alpes-Côte d'Azur, FR`,
    value: `Nice, Provence-Alpes-Côte d'Azur, FR`,
  },
  {
    label: `Nantes, Pays de la Loire, FR`,
    value: `Nantes, Pays de la Loire, FR`,
  },
  {
    label: `Bordeaux, Nouvelle-Aquitaine, FR`,
    value: `Bordeaux, Nouvelle-Aquitaine, FR`,
  },
  {
    label: `Biarritz, Nouvelle-Aquitaine, FR`,
    value: `Biarritz, Nouvelle-Aquitaine, FR`,
  },
  {
    label: `Montpellier, Occitanie, FR`,
    value: `Montpellier, Occitanie, FR`,
  },
]

export type ScoringType =
  | "size"
  | "location"
  | "similar_to_status"
  | "keywords"
  | "sectors"
  | "custom_field"

export const typesDict: ScoringType[] = [
  "size",
  "keywords",
  "sectors",
  "location",
]

export const statusDict: LeadStatus[] = ["WANTED", "LOST", "WON"]

export interface AutoCompleteOption {
  label: string
  value: string
}

interface LbScoringCrudProps {
  selectedType: ScoringType
}

export const LbScoringCrud = ({ selectedType }: LbScoringCrudProps) => {
  const { paginationModel, pageSize, currentLensId, currentFilter, sortModel } =
    useAppSelector(selectCommonsState)

  const { checkIfDraftLensNeeded } = useCheckLense()

  const [removedScoringParameter, setRemovedScoringParameter] = useState<
    string[]
  >([])
  const [sectors, setSectors] = useState<string[]>([])
  const [localSizes, setLocalSizes] = useState<
    Array<{ min: number; max: number }>
  >([])
  const [minSize, setMinSize] = useState("")
  const [maxSize, setMaxSize] = useState("")
  const [keywords, setKeywords] = useState<string[]>([])
  const [inputKeywordSearch, setInputKeywordSearch] = useState<string>("")
  const [status, setStatus] = useState<LeadStatus | undefined>()
  const [selectedLocations, setSelectedLocations] =
    useState<AutoCompleteOption | null>(null)
  const [inputGeoSearch, setInputGeoSearch] = useState<string>("")
  const [localGeoSearch, setLocalGeoSearch] = useState<string>("")

  const { data: allSector } = useGetSectorsAllQuery()

  const { data, refetch: refetchScoring } = useGetLensesByLensIdScoringQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const { refetch } = useGetLensesByLensIdLeadsWishlistQuery(
    {
      lensId: currentLensId,
      count: pageSize,
      page: paginationModel.page,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
    },
  )
  const { refetch: refetchLeads } = useGetLensesByLensIdLeadsQuery(
    {
      lensId: currentLensId,
      page: paginationModel.page,
      count: pageSize,
      wished: false,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
    },
  )

  const { data: geoSearchResult, isFetching: geoSearchFetching } =
    useGetGeoSearchQuery(
      {
        q: localGeoSearch,
      },
      {
        skip: localGeoSearch === "",
        refetchOnMountOrArgChange: true,
      },
    )

  const { data: keywordsResult, isFetching: keywordSearchFetching } =
    useGetKeywordsSearchQuery(
      {
        q: inputKeywordSearch,
      },
      {
        skip: inputKeywordSearch === "",
        refetchOnMountOrArgChange: true,
      },
    )
  const keywordOptions = useMemo(
    () =>
      keywordsResult?.map((keyword) => ({
        label: keyword,
        value: keyword,
      })) ?? [],
    [keywordsResult],
  )

  const [postOrganizationsByOrgIdScoring, { isLoading }] =
    usePostLensesByLensIdScoringMutation()

  const handleAddSize = () => {
    if (!minSize || !maxSize) return

    setLocalSizes([{ min: Number(minSize), max: Number(maxSize) }])
  }

  const handleAddKeyword = (newKeyword: AutoCompleteOption | null) => {
    if (!newKeyword) return

    setKeywords([...keywords, newKeyword?.value])
    setInputKeywordSearch("")
  }

  const handleAddLocation = (selectedLocations: AutoCompleteOption | null) => {
    setSelectedLocations(selectedLocations)
  }

  const handleAddSector = (sectors: AutoCompleteOption | null) => {
    if (!sectors) return

    setSectors([sectors?.value])
  }

  useAsyncEffect(async () => {
    if (localSizes.length > 0) {
      await handleCreateScoringParameter()

      setLocalSizes([])
      setMinSize("")
      setMaxSize("")
    }

    if (keywords.length > 0) {
      await handleCreateScoringParameter()

      setKeywords([])
    }

    if (status) {
      await handleCreateScoringParameter()

      setStatus(undefined)
    }

    if (sectors.length > 0) {
      await handleCreateScoringParameter()

      setSectors([])
    }

    if (selectedLocations) {
      await handleCreateScoringParameter()

      setSelectedLocations(null)
    }
  }, [localSizes, keywords, status, sectors, selectedLocations])

  const handleCreateScoringParameter = async () => {
    try {
      const lensId = await checkIfDraftLensNeeded()

      if (!data?.criteria) throw new Error("No criteria found")

      const newCriteria: ScoringCriterion[] = []

      switch (selectedType) {
        case "size":
          newCriteria.push({
            type: "size",
            importance: 50,
            sizes: localSizes,
          })
          break
        case "keywords":
          newCriteria.push({
            importance: 50,
            type: selectedType,
            keywords,
          })
          break
        case "similar_to_status":
          if (!status) throw new Error("No status found")

          newCriteria.push({
            importance: 50,
            type: selectedType,
            status,
          })
          break
        case "sectors":
          newCriteria.push({
            importance: 50,
            type: selectedType,
            sectors,
          })
          break
        case "location":
          if (!selectedLocations) throw new Error("No location found")

          const newLocation = findLocationByValue(
            [
              ...(geoSearchResult ?? []),
              ...fixedOptions.map((option) =>
                parseLocationString(option.value),
              ),
            ],
            selectedLocations,
          )

          if (!newLocation) return

          analytics.scoringParameterUpdated({
            parameterName: selectedType,
          })

          newCriteria.push({
            importance: 50,
            type: selectedType,
            locations: [newLocation],
          })
          break
      }

      if (!newCriteria) throw new Error("No new criteria found")

      await postOrganizationsByOrgIdScoring({
        lensId,
        scoringConfig: {
          criteria: [...data.criteria, ...newCriteria],
        },
      })

      await refetchScoring()
      await refetch()
      await refetchLeads()
    } catch (error) {
      console.error(error)
      toast.error("Error updating scoring parameters")
    } finally {
      setRemovedScoringParameter([])
    }
  }

  const allSectorsData = useMemo(() => {
    const savedSectors = data?.criteria.find(
      (criterion) => criterion.type === "sectors",
    )

    if (!savedSectors && allSector)
      return allSector.map((sector) => ({
        label: sector,
        value: sector,
      }))
    else if (savedSectors && allSector)
      return (
        allSector
          // @ts-ignore:next-line
          .filter((sector) => !savedSectors.sectors.includes(sector))
          .map((sector) => ({
            label: sector,
            value: sector,
          }))
      )
    else return []
  }, [allSector, data?.criteria, removedScoringParameter, sectors])

  const allStatusData = useMemo(() => {
    const savedStatus = data?.criteria
      .filter((criterion) => criterion.type === "similar_to_status")
      // @ts-ignore:next-line
      .map((criterion) => criterion.status)

    if (!savedStatus) return statusDict
    else if (savedStatus)
      return statusDict.filter((status) => !savedStatus.includes(status))
    else return []
  }, [data?.criteria, removedScoringParameter, status])

  const allStatusDataNonPresents = statusDict.filter(
    (element) => !allStatusData.includes(element),
  )

  useEffect(() => {
    // if all sectors or all status are selected, remove the corresponding scoring parameter
    if (allSectorsData.length === 0)
      setRemovedScoringParameter([...removedScoringParameter, "sectors"])
    if (allStatusData.length === 0)
      setRemovedScoringParameter([
        ...removedScoringParameter,
        "similar_to_status",
      ])
  }, [allSectorsData, allStatusData])

  const locationOptions = useMemo(() => {
    if (geoSearchResult?.length) {
      return geoSearchResult.map(({ city, state, country }) => {
        const locationString = generateLocationString({ city, state, country })
        return { label: locationString, value: locationString }
      })
    }

    return [...fixedOptions]
  }, [geoSearchResult])

  const { run } = useDebounceFn(
    () => {
      setLocalGeoSearch(inputGeoSearch)
    },
    {
      wait: 500,
    },
  )

  useEffect(() => {
    if (inputGeoSearch === "") return
    run()
  }, [inputGeoSearch])

  const showErrorSize =
    minSize !== "" && maxSize !== "" && Number(minSize) >= Number(maxSize)

  return (
    <Box sx={{ flex: 1, mb: 2.5 }}>
      {selectedType === "size" && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <TextField
              type="number"
              size="small"
              label="Min employees"
              value={minSize}
              onChange={(e) => setMinSize(e.target.value)}
            />

            <TextField
              error={showErrorSize}
              type="number"
              size="small"
              label="Max employees"
              value={maxSize}
              onChange={(e) => setMaxSize(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && minSize !== "" && maxSize !== "")
                  handleAddSize()
              }}
            />

            <Box sx={{ ...CENTERED_FLEX_COL }}>
              <IconButton
                color="primary"
                onClick={handleAddSize}
                size="small"
                disabled={minSize === "" || maxSize === ""}
                sx={{ alignSelf: "flex-center" }}
              >
                {!isLoading ? (
                  <Add style={{ fontSize: "1.2rem" }} />
                ) : (
                  <CircularProgress size={12} />
                )}
              </IconButton>
            </Box>
          </Box>

          {showErrorSize && (
            <Typography color="error" sx={{ mt: 1, fontSize: "0.8rem" }}>
              Min size must be less than max size
            </Typography>
          )}
        </>
      )}
      {selectedType === "keywords" && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Autocomplete
            fullWidth
            size="small"
            disablePortal
            inputValue={inputKeywordSearch}
            loading={keywordSearchFetching && inputKeywordSearch.length > 2}
            options={keywordOptions}
            noOptionsText={
              inputKeywordSearch.length <= 2
                ? "Type at least 3 characters"
                : `No keyword found for ${inputKeywordSearch}`
            }
            onChange={(_, newValue) => {
              handleAddKeyword(newValue)
            }}
            ListboxProps={{
              sx: {
                fontSize: "0.9rem",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={`Add  ${selectedType}`}
                onChange={(e) => setInputKeywordSearch(e.target.value ?? "")}
              />
            )}
            PopperComponent={(props: PopperProps) => (
              <Popper
                {...props}
                disablePortal
                sx={{
                  display: inputKeywordSearch === "" ? "none" : "block",
                }}
              />
            )}
          />
        </Box>
      )}
      {selectedType === "similar_to_status" && (
        <>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {allStatusData.map((statusData, index) => (
              <Chip
                sx={{ fontWeight: "bold" }}
                label={statusData}
                variant={statusData !== status ? "outlined" : undefined}
                onClick={() => setStatus(statusData)}
                key={statusData + index}
              />
            ))}

            {allStatusDataNonPresents.map((statusData, index) => (
              <Chip
                sx={{ fontWeight: "bold" }}
                label={statusData}
                variant={statusData !== status ? "outlined" : undefined}
                key={statusData + index}
                disabled
              />
            ))}
          </Box>
        </>
      )}
      {selectedType === "sectors" && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Autocomplete
            key={allSectorsData.length + JSON.stringify(sectors)}
            fullWidth
            size="small"
            disablePortal
            options={allSectorsData}
            ListboxProps={{
              sx: {
                fontSize: "0.9rem",
              },
            }}
            onChange={(_, newValue) => {
              handleAddSector(newValue)
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth label={`Add  ${selectedType}`} />
            )}
          />
        </Box>
      )}

      {selectedType === "location" && (
        <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
          <Autocomplete
            fullWidth
            size="small"
            options={locationOptions}
            loading={geoSearchFetching}
            filterOptions={(options) => options}
            noOptionsText={`No location found for ${inputGeoSearch}`}
            onChange={(_, newValue) => {
              handleAddLocation(newValue)
            }}
            ListboxProps={{
              sx: {
                fontSize: "0.9rem",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={`Add ${selectedType}`}
                value={inputGeoSearch}
                onChange={(e) => setInputGeoSearch(e.target.value ?? "")}
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}
