import { LbFade } from "@components/animations/LbFade/LbFade"
import { type ReactNode } from "react"
import { LbAppError } from "../LbAppError/LbAppError"

interface LbQueryProps {
  isLoading: boolean
  isError: boolean
  skeleton?: ReactNode
  content: ReactNode
}

export const LbQuery = (props: LbQueryProps) => {
  const { isLoading, isError, skeleton, content } = props

  return (
    <>
      {isLoading ? (
        <LbFade fullWidth delay={1.5}>
          {skeleton}
        </LbFade>
      ) : isError ? (
        <LbAppError />
      ) : (
        content
      )}
    </>
  )
}
