import { FeatureFlag } from "@api/leadbayApi"
import { selectCommonsState } from "@redux/commonsSlice"
import { useMemo } from "react"
import { useAppSelector } from "./useAppSelector"

export enum FeatureFlagEnum {
  "HUBSPOT" = "HUBSPOT",
  "MAP_VIEW" = "MAP_VIEW",
}

export const useFeatureFlagCheck = (toCheck: FeatureFlag) => {
  const { featuresFlags } = useAppSelector(selectCommonsState)

  const isFeatureFlagEnabled = useMemo(
    () => featuresFlags.includes(toCheck),
    [featuresFlags, toCheck],
  )

  return isFeatureFlagEnabled
}
