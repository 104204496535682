import { LbButton } from "@components/feedback/LbButton/LbButton"
import { ROUTES } from "@constants/index"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { Link as RouterLink } from "react-router-dom"

const ErrorScreen = () => {
  return (
    <Box
      id="error-screen"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <iframe
          src="https://my.spline.design/rx3QFEv5JKbkIWmnDCTSGHML/"
          width="550px"
          height="520px"
          frameBorder={0}
          style={{ transform: "scale(0.9)", marginTop: "50px" }}
        />

        <Box sx={{ mb: 5 }}>
          <Typography
            variant="h6"
            sx={{ maxWidth: "820px" }}
            align="center"
            gutterBottom
          >
            We are still building and working hard to construct the future of
            sales technology. Get in touch and let us know what you were looking
            for by writing at{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:help@leadbay.ai"
            >
              help@leadbay.ai
            </a>
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 1, mb: 5, flexDirection: "column" }}>
          <LbButton component={RouterLink} to={ROUTES.ROOT} size="large">
            Return to Home
          </LbButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorScreen
