import {
  useGetUsersMeQuery,
  usePostOrganizationsByOrgIdMutation,
  type UserWithOrg,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { Close } from "@mui/icons-material"
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material"

import { useState } from "react"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { object, string } from "yup"

// TODO: remove all project useApiRequestFeedback, manage api response in async functions

const validationSchema = object().shape({
  newName: string().min(3).max(255).required("Name is required"),
})

interface FormValues {
  newName: string
}

interface InformationProps {
  user: UserWithOrg
}

export const Information = (props: InformationProps) => {
  const { user } = props

  const [showNameUpdate, setShowNameUpdate] = useState(false)

  const { refetch: refetchUserInfos } = useGetUsersMeQuery()

  const [postOrganizations, { isLoading, isSuccess, error }] =
    usePostOrganizationsByOrgIdMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      newName: user.organization?.name ?? "",
    },
  })

  const handleFormSubmit: SubmitHandler<FormValues> = async ({ newName }) => {
    try {
      if (newName === user.organization?.name)
        throw new Error("Name is the same, please choose another one")

      await postOrganizations({
        orgId: user.organization?.id ?? "",
        orgUpdate: {
          name: newName,
        },
      })

      await refetchUserInfos()
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  const handleShowNameUpdate = () => setShowNameUpdate((prev) => !prev)

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        message: {
          text: "Your organization name has been updated successfully",
        },
        action: handleShowNameUpdate,
      },
    },
  })

  return (
    <>
      <Divider sx={{ mb: 5, mt: 6 }} />

      <Box component="section">
        <Box component="header" sx={{ mb: 3 }}>
          <Typography variant="h6" fontFamily="Hanken Grotesk">
            Information
          </Typography>

          <Typography color="text.secondary">
            Update your organization information.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="subtitle1"
              fontFamily="Hanken Grotesk"
              fontWeight="bold"
            >
              Organization name
            </Typography>
          </Grid>

          <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="subtitle2" sx={{ px: 1 }}>
                {user?.organization?.name}
              </Typography>

              <LbButton
                sx={{ textTransform: "none", mt: 1 }}
                onClick={handleShowNameUpdate}
                size="small"
              >
                Update organization name
              </LbButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={showNameUpdate}
        onClose={handleShowNameUpdate}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(255,255,255,0.8)",
            },
          },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 600,
            backgroundColor: "white",
            p: 4,
            borderRadius: 1,
            border: "1px solid rgba(0, 0, 0, 0.08)",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="h2" sx={{ lineHeight: 1 }}>
                  Update organization name
                </Typography>

                <IconButton size="small" onClick={handleShowNameUpdate}>
                  <Close />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Controller
                  control={control}
                  name="newName"
                  render={({ field }) => (
                    <TextField
                      label="New organization name"
                      error={!!errors.newName}
                      helperText={errors.newName?.message}
                      fullWidth
                      required
                      {...field}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <LbButton
                variant="contained"
                color="primary"
                size="large"
                disabled={!isValid || isLoading}
                type="submit"
                loading={isLoading}
              >
                Save
              </LbButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
