import Box from "@mui/material/Box"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { LbButton } from "../../../../../components/feedback/LbButton/LbButton"
import { useAppSelector } from "../../../../../hooks/useAppSelector"
import {
  selectOnboardingState,
  setCurrentStep,
} from "../../../../../redux/onboardingSlice"

interface StepsDict {
  id: number
  label: string
  active: boolean
}

export const StepNavigation = () => {
  const dispatch = useDispatch()

  const { currentStep } = useAppSelector(selectOnboardingState)

  const [localCurrentStep, setLocalCurrentStep] = useState(currentStep ?? 0)

  const steps = useMemo(() => {
    const steps: StepsDict[] = [
      {
        id: 0,
        label: "You",
        active: false,
      },
      {
        id: 1,
        label: "Upload",
        active: false,
      },
      {
        id: 2,
        label: "Mapping",
        active: false,
      },
      /*
      {
        id: 3,
        label: "Wishlist",
        active: false,
      },
      */
      {
        id: 4,
        label: "Results",
        active: false,
      },
    ]

    return steps.map((step) =>
      step.id === currentStep ? { ...step, active: true, success: true } : step,
    )
  }, [currentStep])

  const handleGoToStep = (step: number) => {
    dispatch(setCurrentStep(step))
  }

  useEffect(() => {
    if (!currentStep) return

    if (currentStep >= localCurrentStep) setLocalCurrentStep(currentStep)
  }, [currentStep])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
        textAlign: "right",
        alignItems: "flex-end",
      }}
    >
      {steps.map(({ id, label, active }) => (
        <LbButton
          onClick={() => {
            handleGoToStep(id)
          }}
          key={id}
          size="large"
          variant={active ? "contained" : "text"}
          color={active ? "secondary" : "primary"}
          disabled={false}
          sx={{ textTransform: "capitalize" }}
        >
          {label}
        </LbButton>
      ))}
    </Box>
  )
}
