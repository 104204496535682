import { type SerializedError } from "@reduxjs/toolkit"
import { type FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { type ApiError } from "@typing/index"
import { useEffect } from "react"
import { toast } from "react-toastify"

interface MessageConfig {
  text: string
  type?: "success" | "error" | "warning" | "info"
}

interface StatusCodeConfig {
  message?: MessageConfig
  action?: () => void
}

interface HookConfig {
  onSuccess?: {
    message?: MessageConfig
    action?: () => void
  }
  statusCodeConfig?: Record<number, StatusCodeConfig>
}

interface UseAuthSnackbarEffectProps {
  isSuccess: boolean
  error: FetchBaseQueryError | SerializedError | undefined
  config: HookConfig
}

export const useApiRequestFeedback = (params: UseAuthSnackbarEffectProps) => {
  const { isSuccess, error, config } = params

  useEffect(() => {
    if (isSuccess && config.onSuccess) {
      if (config.onSuccess.message) {
        toast(config.onSuccess.message.text)
      }
      if (config.onSuccess.action) {
        config.onSuccess.action()
      }
    }

    if (error) {
      const statusCode = (error as ApiError).status

      const currentConfig = config.statusCodeConfig
        ? config.statusCodeConfig[statusCode]
        : undefined

      if (currentConfig) {
        if (currentConfig.message) {
          toast.error(currentConfig.message.text)
        }
        if (currentConfig.action) {
          currentConfig.action()
        }
      } else {
        toast.error("An unexpected error occurred.")
      }
    }
  }, [isSuccess, error])
}
