import { useAppSelector } from "@hooks/useAppSelector"
import { Box, Typography } from "@mui/material"
import { selectAuthState } from "@redux/authSlice"

import { DangerZone } from "./partials/DangerZone/DangerZone"
import { Information } from "./partials/Information/Information"
import { Invitations } from "./partials/Invitations/Invitations"
import { Members } from "./partials/Members/Members"

const OrganisationScreen = () => {
  const { user } = useAppSelector(selectAuthState)

  return (
    <Box
      id="organization-screen"
      component="article"
      sx={{
        minWidth: "600px",
        mt: 6,
        mb: 14,
      }}
    >
      <Box component="header">
        <Typography variant="h4" fontFamily="BasementGrotesque">
          Organization
        </Typography>

        <Typography sx={{ my: 2 }} variant="h6" color="text.secondary">
          Manage your organization and team members.
        </Typography>
      </Box>

      {user?.organization?.name && <Information user={user} />}

      {user && <Members user={user} />}

      <Invitations />

      {user && <DangerZone user={user} />}
    </Box>
  )
}

export default OrganisationScreen
