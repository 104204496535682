import { Coordinate } from "@typing/index"

export const openGoogleMapDirection = (marker: Coordinate) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const originLat = position.coords.latitude
        const originLng = position.coords.longitude
        const origin = `${originLat},${originLng}`
        const destination = `${marker.lat},${marker.lng}`

        const encodedOrigin = encodeURIComponent(origin)
        const encodedDestination = encodeURIComponent(destination)

        const url = `https://www.google.com/maps/dir/?api=1&origin=${encodedOrigin}&destination=${encodedDestination}`

        window.open(url, "_blank")
      },
      () => {
        alert("Erreur lors de l'obtention de la géolocalisation:")
      },
    )
  } else {
    alert("La géolocalisation n'est pas supportée par ce navigateur.")
  }
}
