import { CENTERED_FLEX_ROW } from "@constants/index"
import { Info, Search } from "@mui/icons-material"
import {
  Box,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import { type ChangeEvent } from "react"

interface LbDataGridSearchProps {
  onSearch: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  searchValue?: string
}

export const LbTrashSearch = (props: LbDataGridSearchProps) => {
  const { onSearch, searchValue } = props

  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex-end",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        width: "100%",
        mb: 1.5,
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <TextField
          value={searchValue}
          sx={{ mr: 2, width: 350 }}
          onChange={onSearch}
          InputProps={{
            type: "search",
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          size="small"
          label="Search in Trash"
        />
      </Box>

      <Paper
        elevation={0}
        sx={{
          ...CENTERED_FLEX_ROW,
          gap: 1,
          px: 3,
          py: 2,
          mt: 1.5,
          maxWidth: 800,
        }}
      >
        <Info />

        <Typography variant="subtitle2">
          Leads become stale and end up in Trash if they are shown to you for
          too long and you don’t export them, or if you explicitly dislike them.
          Leads that have been in Trash for more than 30 days will be
          automatically deleted
        </Typography>
      </Paper>
    </Box>
  )
}
