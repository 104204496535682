import { useGetAuthInvitesByInviteIdQuery } from "@api/leadbayApi"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { selectAuthState, setAccessRight } from "@redux/authSlice"
import { useAsyncEffect } from "ahooks"
import { useState } from "react"
import { CheckForm } from "./partials/CheckForm"
import { RegisterForm } from "./partials/RegisterForm"

const CheckAccessScreen = () => {
  const { accessRight } = useAppSelector(selectAuthState)

  const [invite, setInvite] = useState<string | undefined>()

  const { data, isFetching, isError, error, isSuccess } =
    useGetAuthInvitesByInviteIdQuery(
      {
        inviteId: invite!,
      },
      {
        skip: !invite,
      },
    )

  const dispatch = useAppDispatch()

  useAsyncEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search)
    const invite = queryParams.get("invite")

    if (invite) {
      dispatch(setAccessRight("can_register"))

      setInvite(invite)
    }
  }, [])

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      statusCodeConfig: {
        400: {
          message: {
            text: "Your invite isn't valid",
          },
        },
      },
    },
  })

  return accessRight === "can_register" && !isFetching && !isError ? (
    <div id="register-form-wrapper">
      <RegisterForm invite={invite} inviteData={data} />
    </div>
  ) : (
    <div id="check-form-wrapper">
      <CheckForm />
    </div>
  )
}

export default CheckAccessScreen
