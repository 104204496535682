import { CENTERED_FLEX_COL } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box, Modal } from "@mui/material"
import { selectOnboardingState } from "@redux/onboardingSlice"
import { StepMapping } from "../StepMapping/StepMapping"
import { StepResults } from "../StepResults/StepResults"
import { StepUpload } from "../StepUpload/StepUpload"

export interface UploadModalProps {
  open: boolean
  onClose: (config?: { noConfirm: boolean }) => void
}

export const UploadDataSourceModal = ({ open, onClose }: UploadModalProps) => {
  const { currentStep } = useAppSelector(selectOnboardingState)

  if (currentStep === undefined) {
    return null
  }

  return (
    <Modal onClose={() => onClose()} open={open} sx={{ ...CENTERED_FLEX_COL }}>
      <Box
        className="hide-scrollbar"
        sx={{
          position: "relative",
          backgroundColor: "white",
          width: "100%",
          maxWidth: "900px",
          maxHeight: "90vh",
          borderRadius: "20px",
          py: "40px",
          px: 4,
          overflowY: "auto",
        }}
      >
        {currentStep === 1 && <StepUpload />}

        {currentStep === 2 && <StepMapping />}

        {currentStep === 4 && (
          <StepResults
            onClose={() =>
              onClose({
                noConfirm: true,
              })
            }
          />
        )}
      </Box>
    </Modal>
  )
}
