import { LbLottie } from "@components/animations/LbLottie/LbLottie"
import { CENTERED_FLEX_COL } from "@constants/index"
import { Box, CircularProgress, Typography } from "@mui/material"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"

const quotes = [
  "\"In sales, it's not about having the right answers; it's about asking the right questions.\" - Bob Burg",
  '"The best way to sell something is to stop selling and start helping." - Zig Ziglar',
  '"Your attitude, not your aptitude, will determine your altitude in sales." - Tom Hopkins',
  '"Sales are contingent upon the attitude of the salesman, not the attitude of the prospect." - W. Clement Stone',
  '"Every sale has five basic obstacles: no need, no money, no hurry, no desire, no trust." - Zig Ziglar',
  '"In the world of B2B, your network is your net worth." - Porter Gale',
  '"The key to successful leadership today is influence, not authority." - Ken Blanchard',
  '"Success in sales is the result of discipline, dedication, and effort." - Zig Ziglar',
  '"Don\'t find customers for your products, find products for your customers." - Seth Godin',
  '"The harder the conflict, the greater the triumph." - George S. Patton',
  '"The best salespeople are the ones who can build relationships and add value." - Jeb Blount',
  "\"It's not about having the best product; it's about being the best at connecting with people.\" - Unknown",
  '"The difference between a successful person and others is not a lack of strength, not a lack of knowledge, but rather a lack in will." - Vince Lombardi',
  "\"Your time is precious, so don't waste it living someone else's life.\" - Steve Jobs",
  '"Success is not final; failure is not fatal: It is the courage to continue that counts." - Winston S. Churchill',
  '"The only place where success comes before work is in the dictionary." - Vidal Sassoon',
  '"Don\'t watch the clock; do what it does. Keep going." - Sam Levenson',
  '"The best sales strategy is authenticity." - Unknown',
  '"The successful warrior is the average man, with laser-like focus." - Bruce Lee',
  '"Success is not just about making money. It\'s about making a difference." - Unknown',
  '"Don\'t be afraid to give up the good to go for the great." - John D. Rockefeller',
  '"The successful man is the one who finds out what is the matter with his business before his competitors do." - Roy L. Smith',
  '"Opportunities don\'t happen. You create them." - Chris Grosser',
  '"It\'s not about how much you do, but how much love you put into what you do that counts." - Mother Teresa',
  '"The successful warrior is the one who manages to harness both the positive and negative energy around him." - Paulo Coelho',
  '"Success is not in what you have, but who you are." - Bo Bennett',
  '"The only limit to our realization of tomorrow will be our doubts of today." - Franklin D. Roosevelt',
  '"Great things are not done by impulse but by a series of small things brought together." - Vincent Van Gogh',
  '"You don\'t have to be a genius or a visionary or even a college graduate to be successful. You just need a framework and a dream." - Michael Dell',
  '"Success usually comes to those who are too busy to be looking for it." - Henry David Thoreau',
  '"Don\'t be pushed around by the fears in your mind. Be led by the dreams in your heart." - Roy T. Bennett',
  '"Do not wait to strike till the iron is hot, but make it hot by striking." - William Butler Yeats',
  '"Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful." - Albert Schweitzer',
  '"The best way to predict the future is to create it." - Peter Drucker',
  '"You don\'t close a sale; you open a relationship if you want to build a long-term, successful enterprise." - Patricia Fripp',
  '"Success is not the result of spontaneous combustion. You must set yourself on fire." - Arnold H. Glasow',
  '"The secret of getting ahead is getting started." - Mark Twain',
  '"You miss 100% of the shots you don\'t take." - Wayne Gretzky',
  '"Success is walking from failure to failure with no loss of enthusiasm." - Winston S. Churchill',
  '"Your most unhappy customers are your greatest source of learning." - Bill Gates',
]

interface LbIaLoaderProps {
  progress?: number
  enableQuotes?: boolean
}

export const LbIaLoader = ({
  progress,
  enableQuotes = true,
}: LbIaLoaderProps) => {
  const [randomQoteIndex, setRandomQoteIndex] = useState(0)

  useEffect(() => {
    if (!enableQuotes) return

    const interval = setInterval(() => {
      setRandomQoteIndex(Math.floor(Math.random() * quotes.length))
    }, 8000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Box
      sx={{
        minWidth: "700px",
        margin: "0 auto",
        ...CENTERED_FLEX_COL,
        textAlign: "center",
      }}
    >
      <LbLottie type="loading" loop style={{ width: 350 }} />

      {enableQuotes && (
        <motion.div
          key={randomQoteIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{ height: 50 }}
        >
          <Typography color="text.secondary" sx={{ fontStyle: "italic" }}>
            {quotes[randomQoteIndex]}
          </Typography>
        </motion.div>
      )}

      <Box sx={{ position: "relative", display: "inline-flex", mt: 9 }}>
        {progress && (
          <CircularProgress
            size={60}
            variant="determinate"
            value={Math.ceil(progress * 100)}
          />
        )}

        {progress && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontFamily="BasementGrotesque"
              variant="caption"
              component="div"
            >{`${Math.ceil(progress * 100)}%`}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
