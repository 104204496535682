import { LbLottie } from "@components/animations/LbLottie/LbLottie"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL, MAPPING_STEP } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import { useUpload } from "@hooks/useUploadCsv"
import { Close, Error, Upload } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import {
  selectOnboardingState,
  setMappingStep,
  setNextStep,
  setSelectedFile,
} from "@redux/onboardingSlice"
import { useAsyncEffect, useDrop } from "ahooks"
import localforage from "localforage"

import { useRef, useState, type ChangeEvent } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Step } from "../Step/Step"

export const StepUpload = () => {
  const dispatch = useDispatch()

  const { selectedFile } = useAppSelector(selectOnboardingState)

  const {
    setProcessingFinished,
    handleFileUpload,
    processingFinished,
    csvParsingLoading,
    uploadError,
    setUploadError,
  } = useUpload()

  const dropRef = useRef<HTMLDivElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const [isHovering, setIsHovering] = useState<boolean>(false)

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleRemoveFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }

    localforage.removeItem(MAPPING_STEP).catch(console.error)

    dispatch(setMappingStep(0))

    dispatch(setSelectedFile(undefined))
  }

  const validateFile = (file: File) => {
    if (file.type === "text/csv" || file.name.endsWith(".csv")) {
      dispatch(setSelectedFile(file))
    } else {
      toast.error("Only CSV files are accepted")

      if (fileInputRef.current) {
        handleRemoveFile()
      }
    }
  }

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0]

      setUploadError(undefined)

      setProcessingFinished(false)

      validateFile(selectedFile)
    }
  }

  useDrop(dropRef, {
    onFiles: (files, e) => {
      if (files.length > 1) {
        toast.error("Please, add one file at time")

        return
      }

      validateFile(files[0])
    },
    onDragEnter: () => setIsHovering(true),
    onDragLeave: () => setIsHovering(false),
  })

  const handleNext = async () => {
    if (selectedFile) {
      await handleFileUpload(selectedFile)
    }
  }

  useAsyncEffect(async () => {
    if (processingFinished) {
      dispatch(setNextStep(2))
    }
  }, [processingFinished])

  return csvParsingLoading && selectedFile ? (
    <Box sx={{ minWidth: "700px", margin: "0 auto", ...CENTERED_FLEX_COL }}>
      <LbLottie type="loading" loop style={{ width: 350 }} />

      <Typography className="animate-pulse" color="text.secondary">
        Leadbay COMPASS parses your file
      </Typography>
    </Box>
  ) : (
    <Step
      stepIndex={1}
      title="💽 Upload your CRM export in CSV format"
      ctaTitle="Start mapping my leads data"
      ctaDisabled={!selectedFile || uploadError !== undefined}
      onValidate={handleNext}
      sx={{
        maxWidth: "700px",
        margin: "0 auto",
      }}
    >
      {selectedFile?.name ? (
        <Box
          ref={dropRef}
          sx={[
            (theme) => ({
              display: "flex",
              height: "250px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              border: `2px solid ${theme.palette.primary.main}`,
              borderSpacing: "10px",
              borderRadius: "35px",
              padding: "50px",
              gap: 1,
              position: "relative",
            }),
          ]}
        >
          <Box sx={{ position: "absolute", top: 15, right: 15 }}>
            <IconButton onClick={handleRemoveFile}>
              <Close sx={{ fontSize: 24 }} color="primary" />
            </IconButton>
          </Box>

          {uploadError ? (
            <Error color="error" sx={{ fontSize: 40 }} />
          ) : (
            <LbLottie type="upload" style={{ width: 200 }} />
          )}

          <Typography
            variant="h6"
            marginBottom="10px"
            textAlign="center"
            sx={[
              (theme) => ({
                color: uploadError
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
              }),
            ]}
          >
            {selectedFile.name}
          </Typography>

          <Typography
            textAlign="center"
            sx={[
              (theme) => ({
                color: theme.palette.error.main,
              }),
            ]}
          >
            {uploadError}
          </Typography>
        </Box>
      ) : (
        <Box
          ref={dropRef}
          sx={[
            (theme) => ({
              display: "flex",
              height: "250px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: theme.palette.secondary.main,
              border: `2px dashed #737791`,
              borderSpacing: "10px",
              borderRadius: "35px",
              padding: "50px",
              gap: 1,
            }),
          ]}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            accept=".csv"
          />

          <Upload
            sx={{ fontSize: 50, color: "#737791" }}
            onClick={handleFileInputClick}
          />

          <Typography variant="h6" marginBottom="10px">
            {isHovering ? "release here" : "Drag your file here"}
          </Typography>

          <LbButton size="large" onClick={handleFileInputClick}>
            Browse
          </LbButton>
        </Box>
      )}
    </Step>
  )
}
