import AnimatedNumber from "@crossfox/react-animated-number"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box, LinearProgress, Tooltip, Typography } from "@mui/material"
import { selectCommonsState } from "@redux/commonsSlice"
import { useAsyncEffect } from "ahooks"
import JSConfetti from "js-confetti"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

export const LbNewLeadSeenProgress = () => {
  const { selectedIds, initialCountData } = useAppSelector(selectCommonsState)

  const [progress, setProgress] = useState(0)
  const [goalReached, setGoalReached] = useState(false)

  useAsyncEffect(async () => {
    if (!initialCountData) return

    const newMonitorLeads = initialCountData?.monitor ?? 0
    const newWishedLeads = initialCountData?.wished ?? 0

    const totalNewLeads = newMonitorLeads + newWishedLeads

    if (totalNewLeads === 0) {
      setProgress(0)
    } else {
      const seenLeads = (selectedIds.length / totalNewLeads) * 100

      setProgress(Math.min(seenLeads, 100))
    }
  }, [initialCountData?.monitor, initialCountData?.wished, selectedIds])

  useEffect(() => {
    const jsConfetti = new JSConfetti()

    if (progress === 100 && !goalReached) {
      toast.success(
        "🎉 you're all caught up with new leads. Go close some deals.",
      )

      jsConfetti.addConfetti().catch((err) => {
        console.log(err)
      })

      setGoalReached(true)
    }
  }, [progress])

  const newMonitorLeads = initialCountData?.monitor ?? 0
  const newWishedLeads = initialCountData?.wished ?? 0

  const totalNewLeads = newMonitorLeads + newWishedLeads

  if (!initialCountData || totalNewLeads === 0) return null

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <Box sx={{ width: "81px" }}>
        <LinearProgress
          color={progress === 100 ? "success" : "primary"}
          variant="determinate"
          value={progress}
        />
      </Box>

      <Box sx={{ minWidth: 35, mt: "8px" }}>
        <Typography
          component="div"
          sx={{
            lineHeight: "0",
            whiteSpace: "nowrap",
          }}
          fontSize="0.7rem"
          color="text.secondary"
          fontWeight="bold"
        >
          <Tooltip title="Amount of new leads seen">
            <AnimatedNumber
              rate={100}
              value={Math.floor(progress)}
              duration={1000}
              suffix="% lead seen"
            />
          </Tooltip>
        </Typography>
      </Box>
    </Box>
  )
}
