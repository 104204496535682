import {
  useGetImportsByImportIdQuery,
  usePostImportsMutation,
} from "@api/leadbayApi"
import { selectOnboardingState, setImportId } from "@redux/onboardingSlice"
import { useDebounceEffect } from "ahooks"
import { useState } from "react"
import { useAppDispatch } from "./useAppDispatch"
import { useAppSelector } from "./useAppSelector"

export const useImportUpload = () => {
  const dispatch = useAppDispatch()

  const { importId } = useAppSelector(selectOnboardingState)

  const [uploadError, setUploadError] = useState<string | undefined>()
  const [pollingInterval, setPollingInterval] = useState<number>(0)
  const [launchPolling, setLaunchPolling] = useState<boolean>(false)
  const [processingFinished, setProcessingFinished] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [crmUploadFile] = usePostImportsMutation()

  const { data: crmStatusData } = useGetImportsByImportIdQuery(
    { importId: importId! },
    {
      pollingInterval,
      skip: !launchPolling,
    },
  )

  const handleFileUpload = async (selectedFile: File) => {
    try {
      setLaunchPolling(true)
      setPollingInterval(1000)
      setLoading(true)

      const response = await crmUploadFile({
        fileName: selectedFile.name,
        body: selectedFile,
      })

      if ("error" in response)
        throw new Error("Something went wrong, please try again later")

      if ("data" in response) dispatch(setImportId(response.data.id))
    } catch (error) {
      const err = error as Error

      setLaunchPolling(false)
      setPollingInterval(0)
      setUploadError(err.message)
      setLoading(false)
    }
  }

  useDebounceEffect(
    () => {
      if (
        crmStatusData?.pre_processing?.error &&
        crmStatusData?.pre_processing?.error?.length > 0
      ) {
        setLaunchPolling(false)
        setLoading(false)
        setPollingInterval(0)

        console.error(crmStatusData.pre_processing.error)

        setUploadError("Something went wrong, please try again later")
      } else if (crmStatusData?.pre_processing?.finished === true) {
        setLaunchPolling(false)
        setLoading(false)
        setPollingInterval(0)

        setProcessingFinished(true)
      }
    },
    [crmStatusData, launchPolling],
    {
      wait: 2000,
    },
  )

  return {
    setProcessingFinished,
    setUploadError,
    handleFileUpload,
    uploadError,
    processingFinished,
    csvParsingLoading: loading,
  }
}
