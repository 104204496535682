import { ROUTES } from "@constants/index"
import { selectAuthState } from "@redux/authSlice"
import { Navigate } from "react-router-dom"
import { useAppSelector } from "./useAppSelector"

export const useRedirectUnauthenticatedUser = () => {
  const { user } = useAppSelector(selectAuthState)

  if (
    [
      ...Object.values(ROUTES.PRIVATE).map((r) => `/${r}`),
      ...Object.values(ROUTES.PRIVATE).map((r) => `${ROUTES.APP}/${r}`),
      ROUTES.APP,
    ].includes(window.location.pathname) &&
    !user?.email
  ) {
    return (
      <Navigate
        to={ROUTES.ROOT + ROUTES.PUBLIC.LOGIN}
        state={{ from: window.location.pathname + window.location.search }}
      />
    )
  }
}
