import store, { RootState } from "@redux/store"
import { useAsyncEffect } from "ahooks"
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

export enum MessageType {
  IMPORT_PROGRESS = "import_progress",
  LENS_REFRESHED = "lens_refreshed",
  PING = "ping",
  PONG = "pong",
  SCORES_UPDATED = "scores_updated",
}

export interface ImportProgress {
  type: MessageType.IMPORT_PROGRESS
  import_id: string
  step: "PREPROCESSING" | "PROCESSING"
  progress: number
  done: boolean
}

export interface LensRefreshed {
  type: MessageType.LENS_REFRESHED
  lens_id: string
}

export interface Ping {
  type: MessageType.PING
}

export interface Pong {
  type: MessageType.PONG
}

export interface ScoresUpdated {
  type: MessageType.SCORES_UPDATED
  lens_id: string
  scores: Record<string, number>
}

type WebSocketMessage =
  | ImportProgress
  | LensRefreshed
  | Ping
  | Pong
  | ScoresUpdated

const WebSocketContext = createContext<
  { messages: WebSocketMessage[] } | undefined
>(undefined)

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const wsRef = useRef<WebSocket | null>(null)

  const [messages, setMessages] = useState<WebSocketMessage[]>([])

  useAsyncEffect(async () => {
    const state = store.getState() as RootState

    const apiUrl = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/auth/ws?v=1.0`

    if (!wsRef.current) {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.auth.token}`,
        },
      })

      if (!response.ok) {
        console.error("Network response was not ok")
        return
      }

      const { url } = await response.json()

      const ws = new WebSocket(url)
      wsRef.current = ws

      ws.onmessage = (event) => {
        const message: WebSocketMessage = JSON.parse(event.data)

        setMessages((prevMessages) => [...prevMessages, message])
      }

      ws.onerror = (error) => {
        console.error("WebSocket Error:", error)
      }

      ws.onclose = (event) => {
        console.error("WebSocket Closed:", event)
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      if (wsRef.current) {
        wsRef.current.close()
        wsRef.current = null
      }
    }
  }, [])

  return (
    <WebSocketContext.Provider value={{ messages }}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const useLeadbayWSNotification = () => {
  const context = useContext(WebSocketContext)

  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider")
  }
  return context
}
