import imageSmall from "@assets/images/commons/leadbay-logo-small.svg"
import imagePng from "@assets/images/commons/leadbay-logo.png"
import image from "@assets/images/commons/leadbay-logo.svg"

interface LBLogoProps {
  width?: number
  small?: boolean
  png?: boolean
}

export const LBLogo = ({ width = 128, small, png }: LBLogoProps) => (
  <img
    style={{ width, objectFit: "cover" }}
    src={png ? imagePng : small ? imageSmall : image}
    alt="leadbay logo"
  />
)
