import {
  usePostAuthCheckEmailMutation,
  type PostAuthCheckEmailApiArg,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { RESET_PASSWORD_EMAIL, ROUTES } from "@constants/index"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { Grid, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { setAccessRight } from "@redux/authSlice"
import type { ApiError } from "@typing/index"
import { checkEmail } from "@utils/checkNonProEmail"
import localforage from "localforage"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import type * as Yup from "yup"
import { object, string } from "yup"

interface UserInfosFormValues {
  email: string
}

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
})

export type RegisterScreenValues = Yup.InferType<typeof validationSchema>

export const CheckForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [checkEmailMutation, { isLoading, isSuccess, error }] =
    usePostAuthCheckEmailMutation()

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<UserInfosFormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  })

  const handleFormSubmit: SubmitHandler<UserInfosFormValues> = async ({
    email,
  }) => {
    checkEmail(email)

    try {
      const checkEmail: PostAuthCheckEmailApiArg = {
        checkEmail: {
          email,
        },
      }

      await checkEmailMutation(checkEmail)
    } catch (error) {}
  }

  const handleCheckEmail = async () => {
    await localforage.setItem(RESET_PASSWORD_EMAIL, getValues("email"))

    dispatch(setAccessRight("can_register"))
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        message: {
          text: "Welcome, you can now create your Leadbay account",
        },
        action: handleCheckEmail,
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "You already have an account, please login",
          },
          action: () => {
            navigate(ROUTES.PUBLIC.LOGIN)
          },
        },
        403: {
          message: {
            text: "You don't have beta access to Leadbay, please request an access",
          },
        },
      },
    },
  })

  const statusCode = (error as ApiError)?.status

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="check-form">
      <Grid
        container
        spacing={2}
        rowGap={7}
        sx={{
          maxWidth: "550px",
          margin: "0 auto",
        }}
      >
        <Grid item xs={12}>
          <Box component="header">
            <Typography
              component="h1"
              variant="h2"
              fontFamily="BasementGrotesque"
              fontSize={40}
              sx={{ textTransform: "uppercase" }}
            >
              welcome to <br /> the future of
              <Typography
                fontFamily="Reenie Beanie"
                component="span"
                variant="h2"
                fontSize={64}
                marginLeft={1}
                sx={{ lineHeight: 0.9 }}
              >
                Sales
              </Typography>
              <Box
                sx={{ mb: 1, width: "94%", height: 6, background: "#61E786" }}
              />
            </Typography>

            <Typography variant="h6" component="h2" color="text.secondary">
              AI made for B2B Sales Reps
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                error={!!errors.email}
                type="email"
                fullWidth
                sx={{
                  ".MuiFormHelperText-root": {
                    position: "absolute",
                    top: "105%",
                  },
                }}
                helperText={errors.email?.message}
                label="Email"
                placeholder="name@company.com"
                {...field}
                required
              />
            )}
          />
        </Grid>
        {statusCode === 403 ? (
          <Grid item xs={12}>
            <Typography sx={{ mb: 2 }}>
              If you don&apos;t have an access yet, request an access here:
            </Typography>

            <a
              href="https://airtable.com/appAF5Qt556zU5sgB/shrr9Q9SNhkbdJ40u"
              target="_blank"
              rel="noreferrer"
            >
              <LbButton variant="contained" color="primary" size="large">
                Request access
              </LbButton>
            </a>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <LbButton
              id="check-email-button"
              variant="contained"
              color="primary"
              sx={{ marginBottom: 3 }}
              size="large"
              disabled={!isValid || isLoading}
              type="submit"
              loading={isLoading}
            >
              Verify email
            </LbButton>

            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <Typography>Already member?</Typography>

              <LbButton
                disabled={isLoading}
                variant="text"
                color="primary"
                component={RouterLink}
                to={ROUTES.PUBLIC.LOGIN}
                sx={{ textTransform: "capitalize" }}
              >
                Login
              </LbButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </form>
  )
}
