type FlagDictionary = Record<string, string>

export const countryToFlagEmoji = (countryCode?: string): string => {
  if (!countryCode) return ""

  const flags: FlagDictionary = {
    FR: "🇫🇷", // France
    US: "🇺🇸", // États-Unis
    UK: "🇬🇧", // Royaume-Uni
    DE: "🇩🇪", // Allemagne
    ES: "🇪🇸", // Espagne
    IT: "🇮🇹", // Italie
    JP: "🇯🇵", // Japo
    CN: "🇨🇳", // Chine
    IN: "🇮🇳", // Inde
    BR: "🇧🇷", // Brésil
  }
  return flags[countryCode] || countryCode
}
