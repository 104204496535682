import { type UserWithOrg } from "@api/leadbayApi"
import { AUTH_TOKEN } from "@constants/index"
import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { type AccessRight } from "@typing/index"
import localforage from "localforage"
import { daysUntil } from "utils/globals"
import { type RootState } from "./store"

interface AuthState {
  accessRight: AccessRight
  token: string | undefined
  user: UserWithOrg | undefined
  subscriptionRequired: boolean
  trialDaysLeft: number
}

const initialState: AuthState = {
  accessRight: "idle",
  token: undefined,
  user: undefined,
  subscriptionRequired: false,
  trialDaysLeft: 0,
}

export const auth = createSlice({
  name: "auth",

  initialState,

  reducers: {
    setAccessRight: (state, action: PayloadAction<AccessRight>) => {
      state.accessRight = action.payload
    },

    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload

      localforage.setItem(AUTH_TOKEN, action.payload).catch((err) => {
        console.error(err)
      })
    },

    setUser(state, action: PayloadAction<UserWithOrg | undefined>) {
      state.user = action.payload

      state.trialDaysLeft = daysUntil(
        action.payload?.organization.billing.deadline,
      )
    },

    setSubscriptionRequired(state, action: PayloadAction<boolean>) {
      state.subscriptionRequired = action.payload
    },
  },
})

export const { setAccessRight, setToken, setUser, setSubscriptionRequired } =
  auth.actions

export const selectAuthState = (state: RootState): AuthState => state.auth

export default auth.reducer
