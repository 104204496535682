import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Search } from "@mui/icons-material"
import {
  Box,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import {
  selectCommonsState,
  setFavoriteViewMode,
  type FavoriteViewMode,
} from "@redux/commonsSlice"
import { type ChangeEvent } from "react"

interface LbDataGridSearchProps {
  onSearch: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  searchValue?: string
}

export const LbFavoritesSearch = (props: LbDataGridSearchProps) => {
  const { onSearch, searchValue } = props

  const dispatch = useAppDispatch()

  const { favoriteViewMode } = useAppSelector(selectCommonsState)

  const toggleViewMode = (mode: FavoriteViewMode) => {
    dispatch(setFavoriteViewMode(mode))
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        width: "100%",
        mb: 1.5,
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
        }}
      >
        <ToggleButtonGroup value={favoriteViewMode} size="small">
          <ToggleButton
            value="wanted"
            onClick={() => toggleViewMode("wanted")}
            sx={{ textTransform: "capitalize" }}
          >
            Liked leads
          </ToggleButton>

          <ToggleButton
            value="all"
            onClick={() => toggleViewMode("unwanted")}
            sx={{ textTransform: "capitalize" }}
          >
            Disliked leads
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          value={searchValue}
          sx={{ mr: 2, width: 300, maxWidth: 300 }}
          onChange={onSearch}
          InputProps={{
            type: "search",
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          size="small"
          label="Search in favorites"
        />
      </Box>
    </Box>
  )
}
