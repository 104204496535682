import {
  useGetImportsByImportIdQuery,
  useGetLensesByLensIdFilterQuery,
  usePostLensesByLensIdFilterMutation,
  type Filter,
} from "@api/leadbayApi"
import { LbLottie } from "@components/animations/LbLottie/LbLottie"
import { LbIcpCard } from "@components/display/LbIcpCard/LbIcpCard"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL } from "@constants/index"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppSelector } from "@hooks/useAppSelector"
import { Mail } from "@mui/icons-material"
import { Box, CircularProgress, Typography } from "@mui/material"
import { selectCommonsState } from "@redux/commonsSlice"
import { setNextStep } from "@redux/onboardingSlice"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { reconstructCriteria } from "utils/globals"
import { Step } from "../Step/Step"

export const Icp = () => {
  const dispatch = useDispatch()

  const { currentLensId } = useAppSelector(selectCommonsState)

  const [icpIsReadeable, setIcpIsReadeable] = useState<boolean>(false)
  const [icpIsReady, setIcpIsReady] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const [organizationsByOrgIdIcp, { isSuccess, error }] =
    usePostLensesByLensIdFilterMutation()

  const { data: crmStatusData } = useGetImportsByImportIdQuery(
    {
      importId: currentLensId,
    },
    {
      pollingInterval: 3000,
      skip: icpIsReady || !currentLensId,
    },
  )
  const { data } = useGetLensesByLensIdFilterQuery(
    {
      lensId: currentLensId,
    },
    {
      pollingInterval: icpIsReadeable ? undefined : 2000,
      skip: !icpIsReady || !currentLensId,
    },
  )

  useEffect(() => {
    if (!data?.items.length) return

    setIcpIsReadeable(true)
  }, [data])

  const criteria = data?.items

  const [localCriteriaStatus, setLocalCriteriaStatus] = useState<
    Filter | undefined
  >()

  const handleNext = async () => {
    try {
      setLoading(true)

      if (!localCriteriaStatus) return

      await organizationsByOrgIdIcp({
        lensId: currentLensId,
        filter: localCriteriaStatus,
      })
    } catch (error) {
      setLoading(false)
    }
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        action: () => {
          setTimeout(async () => {
            dispatch(setNextStep(4))
          }, 3000)
        },
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "Bad Request",
            type: "error",
          },
        },
        401: {
          message: {
            text: "Missing or invalid Authorization header, or invalid token",
            type: "error",
          },
        },
        403: {
          message: {
            text: "Forbidden",
            type: "error",
          },
        },
      },
    },
  })

  const handleChnage = (criteriaStatus: Record<string, boolean>) => {
    if (!data?.items) return

    setLocalCriteriaStatus(reconstructCriteria(criteriaStatus, data.items))
  }

  useEffect(() => {
    if (!crmStatusData?.processing?.finished) return

    setIcpIsReady(true)
  }, [crmStatusData])

  useEffect(() => {
    setLocalCriteriaStatus(data)
  }, [data])

  return crmStatusData?.processing?.error ? (
    <Box sx={{ ...CENTERED_FLEX_COL, gap: 3 }}>
      <a href="mailto:help@leadbay.ai" rel="noopenner">
        <LbButton startIcon={<Mail />}>Contact us</LbButton>
      </a>
    </Box>
  ) : !icpIsReady ? (
    <Box sx={{ minWidth: "700px", margin: "0 auto", ...CENTERED_FLEX_COL }}>
      <LbLottie type="loading" loop style={{ width: 350 }} />

      <Typography className="animate-pulse" color="text.secondary">
        Leadbay AI parses your data, please wait...
      </Typography>

      <Box sx={{ position: "relative", display: "inline-flex", mt: 9 }}>
        {crmStatusData?.processing?.progress && (
          <CircularProgress
            size={60}
            variant="determinate"
            value={Math.ceil(crmStatusData.processing.progress * 100)}
          />
        )}

        {crmStatusData?.processing?.progress && (
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontFamily="BasementGrotesque"
              variant="caption"
              component="div"
            >{`${Math.ceil(
              crmStatusData?.processing?.progress * 100,
            )}%`}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Step
      stepIndex={3}
      title="Leadbay ai suggests the following wishlist criteria"
      ctaTitle="Get my results"
      onValidate={handleNext}
      ctaLoading={loading}
      ctaDisabled={loading || !localCriteriaStatus}
      sx={{
        minWidth: "700px",
        margin: "0 auto",
        mb: 10,
      }}
    >
      <Box sx={{ ...CENTERED_FLEX_COL, gap: 1.5 }}>
        {criteria?.length === 0 ? (
          <Box sx={{ height: "150px", ...CENTERED_FLEX_COL }}>
            <Typography
              variant="body1"
              fontWeight="500"
              lineHeight={2.5}
              color="text.secondary"
            >
              No criteria yet
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant="body1"
              fontWeight="500"
              lineHeight={2.5}
              color="text.secondary"
            >
              No worries little shark, you can change criteria later
            </Typography>

            {criteria?.map((criterion, index) => (
              <LbIcpCard
                key={index}
                criterion={criterion}
                onChange={handleChnage}
              />
            ))}
          </>
        )}
      </Box>
    </Step>
  )
}
