import { CENTERED_FLEX_COL } from "@constants/index"
import { Typography } from "@mui/material"
import { useMemo } from "react"

interface LbCopyRightProps {
  color?: string
}

export const LbCopyright = ({ color = "text.secondary" }: LbCopyRightProps) => {
  const stage = useMemo(() => {
    const location = window.location.href

    if (location.includes("localhost")) return "dev"
    if (location.includes("staging")) return "staging"
    return "beta"
  }, [window.location.href])

  return (
    <Typography
      variant="h6"
      component="i"
      sx={{
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 11,
        height: 60,
        mx: "auto",
        ...CENTERED_FLEX_COL,
      }}
      color={color}
    >
      Leadbay COMPASS {import.meta.env.VITE_LEADBAY_VERSION}-{stage}
    </Typography>
  )
}
