import { CENTERED_FLEX_COL } from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box, Typography } from "@mui/material"
import { selectAuthState } from "@redux/authSlice"
import { toggleNavDrawer } from "@redux/commonsSlice"
import { useEffect, useRef } from "react"
import { TimelinePart } from "./components/TimelinePart/TimelinePart"
import { useTimeline } from "./hooks/use-timeline"

interface TimelineScreenProps {
  enableSelection?: boolean
}

export const TimelineScreen = ({ enableSelection }: TimelineScreenProps) => {
  const dispatch = useAppDispatch()

  const endOfListElementRef = useRef<HTMLDivElement>(null)

  const { user } = useAppSelector(selectAuthState)

  const { timeline, noData, isFetching } = useTimeline({
    endOfListElementRef,
  })

  useEffect(() => {
    if (noData)
      dispatch(
        toggleNavDrawer({
          isOpen: false,
          partial: "LEAD_INFOS",
        }),
      )
  }, [noData])

  return (
    <Box
      id="timeline-screen"
      flex={1}
      pt={1}
      px={2}
      pb={2}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      {!noData && user?.computing_daily_timeline && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            zIndex: 1,
            right: 20,
          }}
        >
          <Typography variant="subtitle2">Timeline updating</Typography>
        </Box>
      )}

      {(!noData || timeline.length < 2) && (
        <Typography
          component="p"
          sx={{ mr: 10, ml: 0.5 }}
          color="text.secondary"
          variant="subtitle2"
        >
          💡 To receive more personalized recommendations, like and export leads
          you find interesting
        </Typography>
      )}

      <Box flex={1} display="flex" flexDirection="column">
        {timeline.map((timelinePart, timelinePartIndex) => (
          <TimelinePart
            key={timelinePart.id + timelinePart.type + timelinePart.value}
            timelinePart={timelinePart}
            enableSelection={enableSelection}
            firstTableBlockId={
              timeline.filter((part) => part.type === "table")[0]?.id
            }
            isFirstOfDate={
              timeline[timelinePartIndex - 1]?.type === "date" ||
              timelinePartIndex === 0
            }
            isLastOfDate={
              timeline[timelinePartIndex + 1]?.type === "date" ||
              timelinePartIndex === timeline.length - 1
            }
          />
        ))}
      </Box>

      {noData && !isFetching && (
        <Box
          sx={{
            ...CENTERED_FLEX_COL,
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <Typography>
            {user?.computing_daily_timeline
              ? "Timeline updating, please check back later. 🕒"
              : "Nothing to show at the moment. Please check back later. 🕒"}
          </Typography>
        </Box>
      )}

      <div ref={endOfListElementRef} />
    </Box>
  )
}
