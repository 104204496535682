import {
  useGetOrganizationsByOrgIdQuery,
  usePostOrganizationsByOrgIdMutation,
} from "@api/leadbayApi"
import { LbQuery } from "@components/feedback/LbQuery/LbQuery"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box, Typography } from "@mui/material"
import { selectAuthState } from "@redux/authSlice"
import { setNextStep } from "@redux/onboardingSlice"
import { OnboardingScreenSkeletons } from "@screens/onboarding/OnboardingScreen/OnboardingScreen.skeletons"
import { useDispatch } from "react-redux"
import { Step } from "../Step/Step"

interface TextInputProps {
  width?: number | string
  value?: string
}

const TextInput = ({ value }: TextInputProps) => (
  <span
    style={{
      display: "inline-block",
      border: "1px solid #000",
      backgroundColor: "#F2F2F2",
      borderRadius: "20px",
      fontSize: "1.1rem",
      fontFamily: "inherit",
      padding: "4px 15px",
      maxWidth: "100%",
      wordBreak: "normal",
      overflowWrap: "break-word",
    }}
  >
    {value}
  </span>
)

export const OrganizationInfo = () => {
  const dispatch = useDispatch()

  const { user } = useAppSelector(selectAuthState)

  const [organizationsByOrgId, { isError, isLoading }] =
    usePostOrganizationsByOrgIdMutation()

  const { data, isSuccess, error } = useGetOrganizationsByOrgIdQuery(
    {
      orgId: user?.organization.id!,
    },
    {
      skip: !user?.organization.id,
    },
  )

  const handleValidateStep = async () => {
    await organizationsByOrgId({
      orgId: user?.organization.id!,
      orgUpdate: {
        name: data?.name,
        description: data?.description!,
      },
    })
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
      },
    },
  })

  const handleNext = async () => {
    try {
      await handleValidateStep()

      dispatch(setNextStep(1))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <LbQuery
      isError={isError}
      isLoading={isLoading}
      skeleton={<OnboardingScreenSkeletons />}
      content={
        <Step
          stepIndex={0}
          title="🤘🏽 We know you quite well"
          ctaTitle="Configure ai"
          onValidate={handleNext}
        >
          <Box>
            <Typography variant="h6" fontWeight="500" lineHeight={2.5}>
              Hello <TextInput value={user?.name} /> from{" "}
              <TextInput value={data?.name} /> <br />
              We have been waiting for you.
            </Typography>

            {data?.description && (
              <>
                <Typography variant="h6" fontWeight="500" lineHeight={2.5}>
                  Here is what we know about your company:
                </Typography>

                <TextInput value={data?.description} />
              </>
            )}

            <Typography variant="h6" fontWeight="500" lineHeight={2.5}>
              We will assist you in identifying the leads that are most likely
              to convert.
            </Typography>
          </Box>
        </Step>
      }
    />
  )
}
