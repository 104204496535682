import { DARK_COLORS, GLOBAL_MUI_STYLES, LIGHT_COLORS } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import { createTheme, type PaletteOptions, ThemeProvider } from "@mui/material"
import { selectCommonsState } from "@redux/commonsSlice"
import { type ReactNode, useMemo } from "react"

interface MuiThemeProviderProps {
  children: ReactNode
}

export const MuiThemeProvider = (props: MuiThemeProviderProps) => {
  const { children } = props

  const { themeMode } = useAppSelector(selectCommonsState)

  const getDesignToken = (): PaletteOptions =>
    themeMode === "dark" ? DARK_COLORS : LIGHT_COLORS

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          ...getDesignToken(),
        },
        ...GLOBAL_MUI_STYLES,
      }),
    [themeMode],
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
