import {
  useDeleteOrganizationsByOrgIdMutation,
  type UserWithOrg,
} from "@api/leadbayApi"
import { ROUTES } from "@constants/index"
import { useLogout } from "@hooks/useLogout"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material"

import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

interface DangerZoneProps {
  user: UserWithOrg
}

export const DangerZone = (props: DangerZoneProps) => {
  const { user } = props

  const { logout } = useLogout()
  const navigate = useNavigate()

  const [deleteOrganisation, { isLoading: deleteOrganisationIsLoading }] =
    useDeleteOrganizationsByOrgIdMutation()

  const handleRemoveOrganisation = async () => {
    try {
      const confirm = window.confirm(
        `Are you sure you want to delete ${user?.organization.name} organization?`,
      )

      if (!confirm) return

      const response = await deleteOrganisation({
        orgId: user?.organization.id,
      })

      // @ts-ignore:next-line
      if (response?.error?.data?.error)
        throw new Error("Something went wrong, please try again later")

      navigate(ROUTES.ROOT)

      await logout()
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    }
  }

  return (
    <>
      <Divider sx={{ mb: 5, mt: 6 }} />

      <Box component="section">
        <Box component="header" sx={{ mb: 3 }}>
          <Typography variant="h6" fontFamily="Hanken Grotesk">
            Danger zone
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="subtitle1"
              fontFamily="Hanken Grotesk"
              fontWeight="bold"
            >
              Remove organization
            </Typography>
          </Grid>

          <Grid item xs={7} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%" }}>
              <Button
                onClick={handleRemoveOrganisation}
                variant="text"
                sx={{ textTransform: "none" }}
                color="error"
              >
                {deleteOrganisationIsLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <> Remove {user?.organization.name}</>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
