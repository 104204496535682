import { type Lead1 } from "@api/leadbayApi"
import { Box, Tooltip, Typography } from "@mui/material"
import { countryToFlagEmoji } from "@utils/countryToFlagEmoji"

export type HighlightedFields = "LOCATION" | "SECTOR" | "SIZE"

interface LbDatableCellProps {
  row: Lead1
  value: [number, number]
  type: "SIZE" | "LOCATION" | "SECTOR"
}

export const LbDatableCell = (props: LbDatableCellProps) => {
  const { row, value, type } = props

  const isHighlighted = row.highlighted_fields?.some((field) => field === type)

  const styles = {
    fontFamily: isHighlighted ? "BasementGrotesque" : "Hanken Grotesk",
    fontSize: isHighlighted ? "0.70rem" : "0.85rem",
  }

  const displaySizeValue = () => {
    if (value === null || value === undefined) return "N/A"

    if (typeof value === "number" || typeof value === "string") return value

    if (!Array.isArray(value)) return "N/A"

    const [start, end] = value

    if (
      (start === 0 && end === 0) ||
      (start === undefined && end === undefined)
    )
      return "N/A"
    if (end === undefined || end > 5000) return end > 5000 ? "5000+" : start
    if (start === undefined) return end
    return `${start} - ${end}`
  }

  if (type === "SIZE") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" sx={styles}>
          {displaySizeValue()}
        </Typography>
      </Box>
    )
  } else if (type === "LOCATION") {
    const currentValue = value as unknown as string
    const parts = currentValue.split(", ")
    const country = parts.pop()
    const locationWithFlag = [...parts, countryToFlagEmoji(country)].join(", ")

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={locationWithFlag} placement="bottom">
          <Typography sx={styles} variant="subtitle2">
            {locationWithFlag}
          </Typography>
        </Tooltip>
      </Box>
    )
  } else {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={value} placement="bottom">
          <Typography sx={styles} variant="subtitle2">
            {value}
          </Typography>
        </Tooltip>
      </Box>
    )
  }
}
