import {
  NewableLead,
  useGetExportTargetsQuery,
  useGetLensesByLensIdLeadsNewCountsQuery,
  useGetTimelineNewCountQuery,
  usePostExportTargetsByTargetIdSetDefaultMutation,
} from "@api/leadbayApi"
import { LbGoogleMapExport } from "@components/display/LbGoogleMapExport/LbGoogleMapExport"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { CENTERED_FLEX_COL } from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { useExportLeads } from "@hooks/useExportLeads"
import {
  FeatureFlagEnum,
  useFeatureFlagCheck,
} from "@hooks/useFeatureFlagCheck"
import { Close, ExpandMore, Search } from "@mui/icons-material"
import {
  Badge,
  Box,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material"
import { type GridRowSelectionModel } from "@mui/x-data-grid"
import {
  selectCommonsState,
  setInitialCountData,
  setWishlistViewMode,
  type WishlistViewMode,
} from "@redux/commonsSlice"
import { analytics } from "@utils/analytics"
import { getAvailableTargets } from "@utils/getAvailableTargets"
import { useAsyncEffect } from "ahooks"
import React, { useMemo, useState, type ChangeEvent } from "react"
import { toast } from "react-toastify"
import { LbNewLeadSeenProgress } from "./LbNewLeadSeenProgress"

interface LbDataGridSearchProps {
  onSearch: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onToggleFilters: () => void
  onToggleScoringParameters: () => void
  onEnableSelection: (value?: boolean) => void
  onResetSelection: () => void
  onRefetchWishlist: () => void
  onRefetchMonitor: () => void
  selectedLeadsData: NewableLead[]
  searchValue?: string
  exportData: {
    selectedLeads: GridRowSelectionModel
    enableSelection: boolean
  }
}

export const LbDataGridSearch = (props: LbDataGridSearchProps) => {
  const {
    onSearch,
    onEnableSelection,
    onResetSelection,
    onRefetchWishlist,
    onRefetchMonitor,
    selectedLeadsData,
    exportData,
    searchValue,
  } = props

  const dispatch = useAppDispatch()

  const { data: targetsList, refetch } = useGetExportTargetsQuery()

  const targets = getAvailableTargets(targetsList)

  const { selectedLeads, enableSelection } = exportData

  const {
    wishlistViewMode,
    initialCountData,
    lastNavDrawerPartial,
    currentLensId,
  } = useAppSelector(selectCommonsState)

  const { data: countData } = useGetLensesByLensIdLeadsNewCountsQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const toggleViewMode = (mode: WishlistViewMode) => {
    onResetSelection()

    onEnableSelection(false)

    dispatch(setWishlistViewMode(mode))
  }

  const handleEnableSelection = () => {
    onEnableSelection()

    if (enableSelection) {
      onResetSelection()
    } else {
      analytics.startExportingLeads()
    }
  }

  const selectedTarget = useMemo(() => {
    if (!targets?.default && !targets?.last) {
      return "f"
    }

    if (targets.default) {
      return targets.default
    }

    return targets.last
  }, [targets])

  const { handleExportLeads, handleExportAllLeads } = useExportLeads({
    onSuccess: () => {
      if (selectedTarget === "f") {
        onRefetchWishlist()
        onRefetchMonitor()

        if (selectedLeads.length > 0) {
          toast.success(
            <div>
              <Typography sx={{ mb: 2 }}>
                {selectedLeads.length} leads exported. Find them now in your
                Monitor view.
              </Typography>

              <Box sx={{ ...CENTERED_FLEX_COL }}>
                <LbButton onClick={() => toggleViewMode("monitor")}>
                  Open Monitor view
                </LbButton>
              </Box>
            </div>,
          )
        } else {
          toast.success("Exported all leads to CSV")
        }
      } else {
        toast.success("Exporting leads to selected Zapier connector")
      }

      onResetSelection()
    },
    onError: (err: Error) => {
      toast.error(err.message ?? "Failed to export leads")
    },
    onFinished: () => {
      if (selectedTarget)
        analytics.finishExportingLeads({
          numLeads: selectedLeads.length,
          target: selectedTarget,
        })

      onResetSelection()
      onEnableSelection(false)
    },
  })

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpenExportMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [setDefaultTarget] = usePostExportTargetsByTargetIdSetDefaultMutation()

  const handleSetDefaultTarget = async (targetId: string) => {
    try {
      const payload = {
        targetId,
      }

      await setDefaultTarget(payload)

      await refetch()
    } catch (error) {
      toast.error("Failed to set default target")

      console.error(error)
    } finally {
      handleClose()
    }
  }

  const defaultConnectorName = useMemo(() => {
    if (targets?.default === "f") {
      return "CSV"
    }

    if (targets?.default) {
      const target = targets?.targets?.find(
        (target) => target.id === targets.default,
      )

      return target?.connector?.name
    }

    return ""
  }, [targets])

  useAsyncEffect(async () => {
    if (!initialCountData) dispatch(setInitialCountData(countData))
  }, [countData])

  const { data: newCountTimelineData } = useGetTimelineNewCountQuery()

  const exportIsAvailable = ["discover", "monitor"].includes(wishlistViewMode)

  const [openMap, setOpenMap] = useState(false)

  const handleOpenMap = () => {
    setOpenMap(!openMap)
  }

  const isMapExportEnabled = useFeatureFlagCheck(FeatureFlagEnum.MAP_VIEW)

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        width: "100%",
        mb: 1.5,
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1.5,
          alignItems: "flex-start",
        }}
      >
        <ToggleButtonGroup value="" size="small">
          {exportIsAvailable && (
            <Tooltip title="Export leads to get more personalized recommendations">
              <ToggleButton
                value="discover"
                sx={{ textTransform: "capitalize" }}
                onClick={handleEnableSelection}
              >
                {enableSelection ? <Close sx={{ fontSize: 16 }} /> : "Export"}
              </ToggleButton>
            </Tooltip>
          )}

          {!exportIsAvailable && (
            <Tooltip title="You must be in Discover mode to export leads">
              <Box display="flex">
                <ToggleButton
                  value="discover"
                  sx={{ textTransform: "capitalize" }}
                  disabled
                >
                  Export
                </ToggleButton>
              </Box>
            </Tooltip>
          )}

          {enableSelection && exportIsAvailable && (
            <Box display="flex">
              <ToggleButton
                disabled={selectedLeads.length === 0}
                value="discover"
                sx={{ textTransform: "capitalize" }}
                onClick={async () =>
                  selectedTarget
                    ? await handleExportLeads({
                        wishlistViewMode,
                        selectedTarget,
                        selectedLeads: selectedLeads as string[],
                      })
                    : toast.error("Please select a target to export leads")
                }
              >
                Export
              </ToggleButton>

              <Tooltip title="Export all lead">
                <ToggleButton
                  value="discover"
                  sx={{ textTransform: "capitalize" }}
                  disabled={selectedLeads.length === 0}
                  onClick={() =>
                    handleExportAllLeads({ wishlistViewMode, selectedTarget })
                  }
                >
                  Export All
                </ToggleButton>
              </Tooltip>

              {isMapExportEnabled && (
                <>
                  <Tooltip title="Open map visualization">
                    <ToggleButton
                      id="map-export-button"
                      value="discover"
                      sx={{ textTransform: "capitalize" }}
                      onClick={handleOpenMap}
                      disabled={selectedLeads.length === 0}
                    >
                      Map
                    </ToggleButton>
                  </Tooltip>

                  <LbGoogleMapExport
                    open={openMap}
                    onClose={handleOpenMap}
                    selectedLeadsData={selectedLeadsData}
                  />
                </>
              )}
            </Box>
          )}

          {enableSelection && (
            <Box display="flex">
              <Tooltip title="Set default target">
                <ToggleButton
                  value="discover"
                  sx={{ textTransform: "capitalize", pr: 2 }}
                  onClick={handleOpenExportMenu}
                >
                  <ExpandMore sx={{ fontSize: 17 }} />

                  {defaultConnectorName}
                </ToggleButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {targets?.targets?.map((target) => (
                  <MenuItem
                    key={target.id}
                    onClick={async () =>
                      await handleSetDefaultTarget(target.id)
                    }
                  >
                    <Typography
                      sx={{ mx: 1 }}
                      variant="subtitle2"
                      fontWeight="bold"
                      color={
                        targets.default === target.id
                          ? "primary"
                          : "text.secondary"
                      }
                    >
                      {target.connector?.name ?? "CSV"}
                    </Typography>

                    {targets.default === target.id && (
                      <Typography variant="caption" color="primary">
                        (Default)
                      </Typography>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </ToggleButtonGroup>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <ToggleButtonGroup
            value={[wishlistViewMode, lastNavDrawerPartial]}
            size="small"
          >
            <Tooltip title="View a timeline of all activities">
              <Badge
                invisible={!newCountTimelineData || newCountTimelineData <= 0}
                color="primary"
                badgeContent={newCountTimelineData}
              >
                <ToggleButton
                  value="timeline"
                  onClick={() => toggleViewMode("timeline")}
                  sx={{ textTransform: "capitalize" }}
                >
                  Timeline
                </ToggleButton>
              </Badge>
            </Tooltip>

            <Tooltip title="Discover new leads">
              <Badge
                invisible={countData && countData?.wished < 0}
                badgeContent={countData?.wished}
                color="primary"
              >
                <ToggleButton
                  value="discover"
                  onClick={() => toggleViewMode("discover")}
                  sx={{ textTransform: "capitalize" }}
                >
                  Discover
                </ToggleButton>
              </Badge>
            </Tooltip>

            <Tooltip title="Track previously identified leads">
              <Badge
                invisible={countData && countData?.monitor < 0}
                badgeContent={countData?.monitor}
                color="primary"
              >
                <ToggleButton
                  value="monitor"
                  onClick={() => toggleViewMode("monitor")}
                  sx={{ textTransform: "capitalize" }}
                >
                  Monitor
                </ToggleButton>
              </Badge>
            </Tooltip>
          </ToggleButtonGroup>

          <Box sx={{ width: "100%" }}>
            <LbNewLeadSeenProgress />
          </Box>
        </Box>
      </Box>

      {wishlistViewMode !== "timeline" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            value={searchValue}
            sx={{ width: 220 }}
            onChange={onSearch}
            InputProps={{
              type: "search",
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            size="small"
            label="Search"
          />
        </Box>
      )}
    </Box>
  )
}
