import { Interaction } from "@api/leadbayApi"
import { LbTimelineGrid } from "@components/display/LbTimelineGrid/LbTimelineGrid"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { useExportLeads } from "@hooks/useExportLeads"
import { useRecordUserInteractions } from "@hooks/useRecordUserInteractions"
import { Box, Typography } from "@mui/material"
import { GridRowSelectionModel, GridToolbarContainer } from "@mui/x-data-grid"
import { useAsyncEffect } from "ahooks"
import { formatDate } from "date-fns"
import localforage from "localforage"
import { useEffect, useMemo, useRef, useState } from "react"
import { toast } from "react-toastify"
import { type useTimeline } from "../../hooks/use-timeline"

type TimelinePartType = ReturnType<
  typeof useTimeline
>["timeline"][number]["type"]

interface TimelinePartProps<T extends TimelinePartType = TimelinePartType> {
  enableSelection?: boolean
  timelinePart: Extract<
    ReturnType<typeof useTimeline>["timeline"][number],
    { type: T }
  >
  firstTableBlockId: string
  isFirstOfDate: boolean
  isLastOfDate: boolean
}

export const TimelinePart = (props: TimelinePartProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <TimelineThead withBall={props.timelinePart.type === "date"} />
      <Box
        mt={props.isFirstOfDate ? 0 : 2}
        mb={props.isLastOfDate ? 0 : 2}
        width="0"
        flexGrow={1}
      >
        <_TimelinePart {...props} />
      </Box>
    </Box>
  )
}

const TimelineThead = ({ withBall }: { withBall?: boolean }) => {
  const ballSize = 20

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={0.5}
      minHeight="50px"
      height="100%"
      minWidth={ballSize}
      width={ballSize}
      maxWidth={ballSize}
    >
      <Box
        sx={{ backgroundColor: "#e0e0e0" }}
        borderRadius={9999}
        width="2px"
        flex={1}
      />

      {withBall && (
        <>
          <Box
            sx={{
              width: ballSize,
              height: ballSize,
              borderRadius: "100px",
              background: "#e0e0e0",
            }}
          />

          <Box
            sx={{ backgroundColor: "#e0e0e0" }}
            borderRadius={9999}
            width="2px"
            flex={1}
          />
        </>
      )}
    </Box>
  )
}

const _TimelinePart = (props: TimelinePartProps) => {
  switch (props.timelinePart.type) {
    case "date":
      return <TimelineDatePart {...(props as TimelinePartProps<"date">)} />
    case "text":
      return <TimelineTextPart {...(props as TimelinePartProps<"text">)} />
    case "table":
      return <TimelineTablePart {...(props as TimelinePartProps<"table">)} />
  }
}

const TimelineDatePart = (props: TimelinePartProps<"date">) => {
  const { date, text } = props.timelinePart.value

  const formattedDate = useMemo(() => {
    return formatDate(date, "EEEE, MMMM 'the' do")
  }, [date])

  return (
    <Typography sx={{ fontWeight: "bold" }} variant="subtitle1" my={4}>
      {formattedDate}
      {text ? ` - ${text}` : ""}
    </Typography>
  )
}

const TimelineTextPart = (props: TimelinePartProps<"text">) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <Box bgcolor="background.paper" p={4} borderRadius={1}>
        <Typography>{props.timelinePart.value.text}</Typography>
      </Box>
    </Box>
  )
}

const TimelineTablePart = (props: TimelinePartProps<"table">) => {
  const tablePartRef = useRef<HTMLDivElement>(null)

  const { handleExportTimelineBlock } = useExportLeads({
    onSuccess: () => {
      toast.success("Leads exported successfully")
    },
    onError: (err) => {
      toast.error("Error exporting leads")
      console.error(err)
    },
    onFinished: async () => {
      setRowSelectionModel([])

      await localforage.removeItem(
        `rowSelectionModel_timeline_${props.timelinePart.id}`,
      )
    },
  })

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const { handleRecordUserInteractions } = useRecordUserInteractions()

  useAsyncEffect(async () => {
    const savedSelectionModel =
      await localforage.getItem<GridRowSelectionModel>(
        `rowSelectionModel_timeline_${props.timelinePart.id}`,
      )
    if (savedSelectionModel) {
      setRowSelectionModel(savedSelectionModel)
    }
  }, [])

  useEffect(() => {
    localforage.setItem(
      `rowSelectionModel_timeline_${props.timelinePart.id}`,
      rowSelectionModel,
    )
  }, [rowSelectionModel])

  useAsyncEffect(async () => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            const interactions = props.timelinePart.value.rows.map((item) => ({
              type: "BLOCK_LEAD_SEEN",
              lead_id: item.id,
              block_id: item.block_id,
            })) as Interaction[]

            await handleRecordUserInteractions(interactions)

            observer.disconnect()
          }
        })
      },
      { threshold: 0.1 },
    )

    if (tablePartRef.current) {
      observer.observe(tablePartRef.current)
    }
  }, [])

  const CustomToolbar = () => {
    const handleExport = async () => {
      await handleExportTimelineBlock({
        blockId: props.firstTableBlockId,
        leadIds: rowSelectionModel as string[],
      })
    }

    return (
      <Box
        sx={{
          opacity: rowSelectionModel.length ? 1 : 0.2,
          pointerEvents: rowSelectionModel.length ? "auto" : "none",
        }}
      >
        <GridToolbarContainer>
          <Box sx={{ flexGrow: 1 }} />

          <LbButton onClick={handleExport} size="small" variant="outlined">
            Export
          </LbButton>
        </GridToolbarContainer>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      ref={tablePartRef}
      width={"100%"}
      bgcolor="background.paper"
      borderRadius={2}
      p={4}
    >
      <Box display="flex" flexDirection="column" width={"100%"}>
        <Typography mb={2} variant="subtitle1" fontStyle="italic">
          {props.timelinePart.value.title}
        </Typography>

        <Box p={1} bgcolor="#fff" borderRadius={1}>
          <LbTimelineGrid
            toolbarExportText={`Export ${rowSelectionModel.length ? rowSelectionModel.length : ""}`}
            rows={props.timelinePart.value.rows}
            rowSelectionModel={{ rowSelectionModel, setRowSelectionModel }}
            columnsToHide={["score", "new"]}
            toolbar={CustomToolbar}
            firstTableBlockId={props.firstTableBlockId}
          />
        </Box>
      </Box>
    </Box>
  )
}
