import {
  usePostAuthLoginMutation,
  usePostAuthPasswordResetChangePasswordMutation,
  type PostAuthLoginApiArg,
  type PostAuthPasswordResetChangePasswordApiArg,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { LbPasswordField } from "@components/inputs/LbPasswordField/LbPasswordField"
import {
  CENTERED_FLEX_COL,
  RESET_PASSWORD_EMAIL,
  ROUTES,
} from "@constants/index"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useQuery } from "@hooks/useQuery"
import { Grid, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { setToken } from "@redux/authSlice"
import { type LoginScreenValues } from "@screens/auth/LoginScreen/LoginScreen"
import localforage from "localforage"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { object, string, type InferType } from "yup"

const validationSchema = object().shape({
  password: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase",
  ),
  passwordConfirmation: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase",
  ),
})

export type ResetPasswordScreenValues = InferType<typeof validationSchema>

const ResetPasswordScreen = () => {
  const query = useQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [
    resetPasswordChange,
    {
      isLoading: resetPasswordChangeIsLoading,
      isSuccess: resetPasswordChangIsSuccess,
      error: resetPasswordChangeError,
    },
  ] = usePostAuthPasswordResetChangePasswordMutation()

  const [
    authLogin,
    {
      data: loginData,
      isLoading: loginIsLoading,
      isSuccess: loginIsSuccess,
      error: loginError,
    },
  ] = usePostAuthLoginMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ResetPasswordScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  })

  const handleLoginUser = async (data: LoginScreenValues) => {
    try {
      const payload: PostAuthLoginApiArg = {
        userLogin: {
          email: data.email,
          password: data.password,
        },
      }

      const result = await authLogin(payload).unwrap()

      navigate(ROUTES.ROOT)
      dispatch(setToken(result.token))
    } catch (error) {
      console.log(error)
    }
  }

  const handleFormSubmit: SubmitHandler<ResetPasswordScreenValues> = async ({
    passwordConfirmation,
  }) => {
    try {
      if (!passwordConfirmation) throw new Error("Password is required")

      const token = query.get("token")

      if (!token) throw new Error("Token is required")

      const headers = {
        Authorization: `Bearer ${token}`,
      }

      const payload: PostAuthPasswordResetChangePasswordApiArg = {
        passwordReset: {
          new: passwordConfirmation,
        },
      }

      await resetPasswordChange({
        // @ts-ignore:next-line
        payload,
        headers,
      })

      const email = await localforage.getItem(RESET_PASSWORD_EMAIL)

      if (!email) throw new Error("Email is required")

      await handleLoginUser({
        email: email as string,
        password: passwordConfirmation,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetAuthState = async () => {
    try {
      const token = loginData?.token

      if (!token) {
        console.error("Token is required")

        return
      }

      await localforage.removeItem(RESET_PASSWORD_EMAIL)

      dispatch(setToken(loginData.token))
      navigate(ROUTES.ROOT)
    } catch (error) {
      console.log(error)
    }
  }

  useApiRequestFeedback({
    isSuccess: resetPasswordChangIsSuccess,
    error: resetPasswordChangeError,
    config: {
      onSuccess: {
        message: {
          text: "Your password has been reset successfully",
        },
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
        401: {
          message: {
            text: "You are not authorized to perform this action",
          },
        },
      },
    },
  })

  useApiRequestFeedback({
    isSuccess: loginIsSuccess,
    error: loginError,
    config: {
      onSuccess: {
        message: {
          text: "You have been logged in successfully",
        },
        action: handleSetAuthState,
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
        401: {
          message: {
            text: "Invalid email or password",
          },
        },
      },
    },
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="reset-password-screen">
      <Grid
        container
        spacing={2}
        rowGap={7}
        sx={{
          maxWidth: "550px",
          margin: "0 auto",
        }}
      >
        <Grid item xs={12}>
          <Box component="header">
            <Typography
              component="h1"
              variant="h2"
              fontFamily="BasementGrotesque"
              fontSize={40}
              sx={{ textTransform: "uppercase" }}
            >
              welcome to <br /> the future of
              <Typography
                fontFamily="Reenie Beanie"
                component="span"
                variant="h2"
                fontSize={64}
                marginLeft={1}
                sx={{ lineHeight: 0.9 }}
              >
                Sales
              </Typography>
              <Box sx={{ width: "94%", height: 6, background: "#61E786" }} />
            </Typography>

            <Typography
              sx={{ mt: 1 }}
              variant="h6"
              component="h2"
              color="text.secondary"
            >
              Enter your email address below and we’ll send you a link to reset
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box sx={{ ...CENTERED_FLEX_COL, gap: 2 }}>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <LbPasswordField
                  label="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  placeholder="********"
                  fullWidth
                  required
                  {...field}
                />
              )}
            />

            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field }) => (
                <LbPasswordField
                  label="Confirm password"
                  placeholder="********"
                  fullWidth
                  required
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation?.message}
                  {...field}
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <LbButton
            variant="contained"
            color="primary"
            size="large"
            disabled={
              !isValid || resetPasswordChangeIsLoading || loginIsLoading
            }
            type="submit"
            sx={{ marginBottom: 3 }}
            loading={resetPasswordChangeIsLoading || loginIsLoading}
          >
            Reset my password
          </LbButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default ResetPasswordScreen
