import { useGetLensesQuery, useGetUsersMeQuery } from "@api/leadbayApi"
import { LbFade } from "@components/animations/LbFade/LbFade"
import {
  APP_BAR_HEIGHT,
  AUTH_TOKEN,
  CENTERED_FLEX_COL,
  IMPORT_ID,
  ROUTES,
} from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Container } from "@mui/material"
import Box from "@mui/material/Box"
import { selectAuthState } from "@redux/authSlice"
import { selectOnboardingState, setImportId } from "@redux/onboardingSlice"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { Link, Navigate, Outlet } from "react-router-dom"
import { LBLogo } from "../../components/display/LbLogo/LBLogo"
import { StepNavigation } from "../../screens/onboarding/OnboardingScreen/partials/StepNavigation/StepNavigation"

const NAV_WIDTH = "200px"

export const OnboardingLayout = () => {
  const dispatch = useAppDispatch()

  const { refetch: refetchUser } = useGetUsersMeQuery()

  const { refetch: refetchLenses } = useGetLensesQuery()

  const { user, token } = useAppSelector(selectAuthState)

  const { currentStep } = useAppSelector(selectOnboardingState)

  useAsyncEffect(async () => {
    if (!user || !token) return

    await localforage.setItem(AUTH_TOKEN, token)

    const savedImportId = await localforage.getItem<string>(IMPORT_ID)

    if (savedImportId) {
      dispatch(setImportId(savedImportId))
    }

    await refetchUser()

    await refetchLenses()
  }, [])

  if (user?.organization?.onboarding_step === "FINISHED")
    return <Navigate to={ROUTES.APP} />

  if (!user) return <Navigate to={ROUTES.ROOT} />

  return (
    <>
      <Box
        component="nav"
        sx={{
          px: 4,
          height: APP_BAR_HEIGHT,
          display: "flex",
          alignItems: "center",
          position: "fixed",
          justifyContent: "space-between",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <Link to={ROUTES.ROOT}>
          <LBLogo />
        </Link>

        <Box
          component="a"
          href="https://docs.leadbay.app/leadbay-user-docs/product-guides/quick-start"
          target="_blank"
          sx={{
            alignSelf: "center",
            fontSize: 14,
            color: "text.secondary",
            fontWeight: 600,
          }}
        >
          💡 <span style={{ marginLeft: 5 }}>Help</span>
        </Box>
      </Box>

      <Box
        sx={{
          ...CENTERED_FLEX_COL,
          minHeight: `100vh`,
        }}
        component="main"
      >
        <Container
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              margin: "0 auto",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 15,
            }}
            component="article"
            aria-label="onboarding"
          >
            {currentStep !== undefined && (
              <Box
                sx={{
                  height: `calc(100vh + ${APP_BAR_HEIGHT})`,
                  position: "fixed",
                  top: 0,
                  left: { xs: "50px", lg: "100px", xl: "18vw" },
                  ...CENTERED_FLEX_COL,
                  pointerEvents: "none",
                }}
              >
                <LbFade>
                  <Box
                    sx={{
                      width: NAV_WIDTH,
                      display: "flex",
                    }}
                    component="nav"
                    aria-label="onboarding nav"
                  >
                    <StepNavigation />
                  </Box>
                </LbFade>
              </Box>
            )}

            <LbFade
              fullWidth
              styles={{ maxWidth: 800, mx: "auto", marginLeft: NAV_WIDTH }}
            >
              <Outlet />
            </LbFade>
          </Box>
        </Container>
      </Box>
    </>
  )
}
