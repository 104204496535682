import { type ButtonProps, CircularProgress } from "@mui/material"
import { type ReactNode } from "react"
import { StyledButton } from "./LbButton.styles"

interface LbButtonProps extends ButtonProps {
  children: ReactNode
  to?: string
  loading?: boolean
}

export const LbButton = (props: LbButtonProps) => {
  const { children, type = "button", color = "primary", size, loading } = props

  return (
    <StyledButton
      type={type}
      size={size}
      disableElevation
      variant="contained"
      color={color}
      {...props}
    >
      {loading ? <CircularProgress size={20} /> : children}
    </StyledButton>
  )
}
