import { type FileImport } from "@api/leadbayApi"
import { LbDataSourceCard } from "@components/display/LbDataSourceCard/LbDataSourceCard"
import { CENTERED_FLEX_COL } from "@constants/index"
import { Box, Typography } from "@mui/material"
import { formatDate } from "date-fns"

interface DataSourceProps {
  imported: FileImport
}

export const DataSource = ({ imported }: DataSourceProps) => {
  const icon = (
    <Box
      sx={{
        background: "black",
        ...CENTERED_FLEX_COL,
        height: "50px",
        width: "50px",
        borderRadius: "5px",
      }}
    >
      <Typography color="white" fontWeight="bold">
        CSV
      </Typography>
    </Box>
  )

  return (
    <>
      <LbDataSourceCard
        isActive
        title={imported.file_name}
        infos={[formatDate(new Date(imported.date), "dd/MM/yyyy HH:mm")]}
        icon={<Box mr="20px">{icon}</Box>}
      />
    </>
  )
}
