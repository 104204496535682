import { type PaletteOptions, type ThemeOptions } from "@mui/material"

/* Routes
 ********************************************* */
export const COMMONS_ROUTES = {
  ALL: "*",
  ROOT: "/",
  APP: "/app",
}

export const PUBLIC_ROUTES = {
  LOGIN: "login",
  INVITE_REGISTER: "register",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "password-reset",
  VERIFY_EMAIL: "verify-email",
  CHANGELOG_SCREEN: "changelog",
  MAP: "map",
}

export const PRIVATE_ROUTES = {
  ONBOARDING: "onboarding",
  DASHBOARD: "dashboard",
  ACCOUNT: "account",
  TRASH: "trash",
  ORGANIZATION: "organization",
  SUBSCRIPTION: "subscription",
  STRIPE_SUCCESS: "stripe/success",
  STRIPE_CANCEL: "stripe/cancel",
  INTEGRATIONS: "integrations",
  FAVORITES: "favorite-leads",
  OAUTH: "oauth/authorize",
  HUBSPOT_OAUTH_CALLBACK: "oauth/hubspot/callback",
}

export const ROUTES = {
  ...COMMONS_ROUTES,
  PUBLIC: PUBLIC_ROUTES,
  PRIVATE: PRIVATE_ROUTES,
}

/* INDEXED KEYS
 ********************************************* */
export const AUTH_TOKEN = "auth_token"
export const RESET_PASSWORD_EMAIL = "reset_password_email"
export const MAPPING_STEP = "mapping_step"
export const MAPPING_FIELD = "mapping_field"
export const IMPORT_ID = "importId"
export const CURRENT_LENS_ID = "current_lens_id"
export const TOTAL_NEW_LEADS = "total_new_leads"
export const SELECTED_IDS = "selected_ids"
export const SEEN_LEADS_PERCENTAGE = "seen_leads_percentage"
export const INITIAL_COUNT_DATA = "initial_count_data"

/* LENSES
 ********************************************* */
export const DEFAULT_LENS = "Default"

/* MUI styles
 ********************************************* */
export const APP_NAV_WIDTH = "64px"
export const APP_BAR_HEIGHT = "60px"
export const APP_DRAWER_WIDTH = window.innerWidth > 1536 ? "27vw" : "450px"

export const CENTERED_FLEX_COL = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}

export const CENTERED_FLEX_ROW = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}

export const CENTERED_FLEX_ROW_BETWEEN = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}

export const CENTERED_FLEX_ROW_AROUND = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
}

export const CENTERED_FLEX_ROW_START = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
}

export const CENTERED_FLEX_ROW_END = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
}

export const CENTERED_FLEX_COL_START = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}

export const CENTERED_FLEX_COL_END = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
}

export const CENTERED_FLEX_COL_AROUND = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
}

export const CENTERED_FLEX_COL_BETWEEN = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
}

export const CENTERED_FLEX_COL_AROUND_START = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-around",
}

export const CENTERED_FLEX_COL_AROUND_END = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "space-around",
}

export const BORDERS_COLORS = "rgba(0, 0, 0, 0.08)"

export const DARK_COLORS: PaletteOptions = {
  primary: {
    main: "#ffffff",
  },
  secondary: {
    main: "#F3F6F5",
  },
  background: {
    default: "#0F0F0F",
    paper: "#151515",
  },
  text: {
    secondary: "#737791",
  },
  success: {
    main: "#61E786",
  },
  error: {
    main: "#e74c3c",
  },
  info: {
    main: "#E1FFE9",
  },
  warning: {
    main: "#FFCDD2",
  },
}

export const LIGHT_COLORS: PaletteOptions = {
  primary: {
    main: "#000000",
  },
  secondary: {
    main: "#F3F6F5",
  },
  background: {
    default: "#ffffff",
    paper: "#FAFAFA",
  },
  text: {
    secondary: "#737791",
  },
  success: {
    main: "#61E786",
  },
  error: {
    main: "#e74c3c",
  },
  info: {
    main: "#E1FFE9",
  },
  warning: {
    main: "#FFCDD2",
  },
}

export const GLOBAL_MUI_STYLES: ThemeOptions = {
  shape: {
    borderRadius: 20,
  },
  typography: {
    fontFamily:
      "Hanken Grotesk, Reenie Beanie, Basement Grotesque, Arial, sans-serif",
    h1: {
      fontFamily: "Basement Grotesque, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h3: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h4: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h5: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    h6: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
    },
    button: {
      fontFamily: "Hanken Grotesk, sans-serif",
      fontWeight: 800,
      fontSize: "1rem",
    },
  },
}
