import { Box, Typography } from "@mui/material"
import { setDeferredPrompt } from "@redux/commonsSlice"
import { BeforeInstallPromptEvent } from "@typing/index"
import { useCallback, useEffect, useLayoutEffect } from "react"
import { toast } from "react-toastify"
import { useRegisterSW } from "virtual:pwa-register/react"
import { useAppDispatch } from "./useAppDispatch"

export const useUpdateApp = () => {
  const dispatch = useAppDispatch()

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered: " + r)
    },
    onRegisterError(error) {
      console.log("SW registration error", error)
    },
  })

  useLayoutEffect(() => {
    const beforeInstallPromptHandler = (e: Event) => {
      e.preventDefault()
      dispatch(setDeferredPrompt(e as BeforeInstallPromptEvent))
    }
    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler)
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler,
      )
    }
  }, [dispatch])

  const close = useCallback(() => {
    setNeedRefresh(false)
  }, [setNeedRefresh])

  const handleClick = useCallback(async () => {
    if (needRefresh) {
      await updateServiceWorker(true)

      close()
    }
  }, [needRefresh, updateServiceWorker, close])

  useEffect(() => {
    if (needRefresh) {
      toast(
        <Box
          onClick={handleClick}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            New leadbay version available!
          </Typography>

          <Typography sx={{ my: 1 }} variant="body1" fontWeight="bold">
            Click to update
          </Typography>
        </Box>,
        {
          onClick: handleClick,
          autoClose: false,
          closeButton: false,
          position: "bottom-center",
        },
      )
    }
  }, [needRefresh])
}
