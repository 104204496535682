import { selectAuthState } from "@redux/authSlice"
import posthog from "posthog-js"
import { useEffect } from "react"
import { useAppSelector } from "./useAppSelector"

export const usePostHog = () => {
  const { user } = useAppSelector(selectAuthState)

  useEffect(() => {
    if (!user) return

    posthog.identify(user.email, {
      leadbayId: user.id,
      leadbayName: user.name,
      leadbayEmail: user.email,
      leadbayOrganization: user.organization.name,
      leadbayOrganizationId: user.organization.id,
    })
  }, [user])
}
