import { PostAuthLoginApiArg, usePostAuthLoginMutation } from "@api/leadbayApi"
import { setToken } from "@redux/authSlice"
import { toast } from "react-toastify"
import { useAppDispatch } from "./useAppDispatch"

type LoginData = {
  email: string
  password: string
}

export const useLogin = () => {
  const dispatch = useAppDispatch()

  const [authLogin, { isLoading }] = usePostAuthLoginMutation()

  const login = async ({ email, password }: LoginData) => {
    try {
      const payload: PostAuthLoginApiArg = {
        userLogin: {
          email,
          password,
        },
      }

      const response = await authLogin(payload).unwrap()

      dispatch(setToken(response.token))
    } catch (error) {
      console.log(error)

      const err = error as { status: number }

      if (err.status === 401) toast.error("Invalid email or password")
      else toast.error("An error occurred, please try again")
    }
  }

  return { login, isLoading }
}
