import {
  usePostAuthPasswordResetStartMutation,
  type PostAuthPasswordResetStartApiArg,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { RESET_PASSWORD_EMAIL, ROUTES } from "@constants/index"
import { yupResolver } from "@hookform/resolvers/yup"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { Grid, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import localforage from "localforage"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import { object, string } from "yup"

interface ForgotPasswordScreenValues {
  email: string
}

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
})

const ForgotPasswordScreen = () => {
  const [resetPasswordStart, { isLoading, isSuccess, error }] =
    usePostAuthPasswordResetStartMutation()

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordScreenValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  })

  const handleFormSubmit: SubmitHandler<ForgotPasswordScreenValues> = async ({
    email,
  }) => {
    try {
      const payload: PostAuthPasswordResetStartApiArg = {
        authPasswordResetStartPostRequest: {
          email,
        },
      }

      await resetPasswordStart(payload)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSetTempEmail = async () => {
    try {
      await localforage.setItem(RESET_PASSWORD_EMAIL, getValues("email"))
    } catch (error) {
      console.log(error)
    }
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      onSuccess: {
        action: handleSetTempEmail,
        message: {
          text:
            "A password reset link has been sent to " +
            getValues("email") +
            ". you can close this tab now",
        },
      },
      statusCodeConfig: {
        400: {
          message: {
            text: "An error occurred, please try again",
            type: "info",
          },
        },
      },
    },
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} id="forgot-password-screen">
      <Grid
        container
        spacing={2}
        rowGap={7}
        sx={{
          maxWidth: "550px",
          margin: "0 auto",
        }}
      >
        <Grid item xs={12}>
          <Box component="header">
            <Typography
              component="h1"
              variant="h2"
              fontFamily="BasementGrotesque"
              fontSize={40}
              sx={{ textTransform: "uppercase" }}
            >
              welcome to <br /> the future of
              <Typography
                fontFamily="Reenie Beanie"
                component="span"
                variant="h2"
                fontSize={64}
                marginLeft={1}
                sx={{ lineHeight: 0.9 }}
              >
                Sales
              </Typography>
              <Box sx={{ width: "94%", height: 6, background: "#61E786" }} />
            </Typography>

            <Typography
              sx={{ mt: 1 }}
              variant="h6"
              component="h2"
              color="text.secondary"
            >
              Enter your email address below and we’ll send you a link to reset
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                error={!!errors.email}
                type="email"
                fullWidth
                helperText={errors.email?.message}
                sx={{
                  ".MuiFormHelperText-root": {
                    position: "absolute",
                    top: "105%",
                  },
                }}
                label="Email"
                placeholder="name@company.com"
                {...field}
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <LbButton
            variant="contained"
            color="primary"
            size="large"
            disabled={!isValid || isLoading}
            type="submit"
            sx={{ marginBottom: 3 }}
            loading={isLoading}
          >
            Reset my password
          </LbButton>

          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography>Already member?</Typography>

            <LbButton
              variant="text"
              color="primary"
              component={RouterLink}
              to={ROUTES.PUBLIC.LOGIN}
              sx={{ textTransform: "capitalize" }}
            >
              Login
            </LbButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default ForgotPasswordScreen
