import { Box, Divider } from "@mui/material"
import { CustomFields } from "./partials/CustomFields/CustomFields"
import { DataSources } from "./partials/DataSources/DataSources"
import { Integrations } from "./partials/Integrations/Integrations"

export const IntegrationsScreen = () => (
  <Box
    id="integrations-screen"
    component="article"
    sx={{ maxWidth: "600px", width: "100%", my: 7 }}
  >
    <Integrations />

    <Divider sx={{ my: 6 }} />

    <DataSources />

    <Divider sx={{ my: 6 }} />

    <CustomFields />
  </Box>
)
