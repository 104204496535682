import {
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsTrashQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
  usePostLensesByLensIdLeadsAndLeadIdUntrashMutation,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { useAppSelector } from "@hooks/useAppSelector"
import { Update } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { selectCommonsState } from "@redux/commonsSlice"
import { toast } from "react-toastify"

interface LbUntrashButtonProps {
  leadId: string
}

export const LbUntrashButton = ({ leadId }: LbUntrashButtonProps) => {
  const { paginationModel, pageSize, currentLensId, currentFilter, sortModel } =
    useAppSelector(selectCommonsState)

  const { refetch: untrashLeadRefetch } = useGetLensesByLensIdLeadsTrashQuery({
    lensId: currentLensId,
  })
  const { refetch: wishlistRefetch } = useGetLensesByLensIdLeadsWishlistQuery({
    lensId: currentLensId,
    count: pageSize,
    page: paginationModel.page,
    q: currentFilter,
    order: sortModel,
  })

  const { refetch: leadsRefetch } = useGetLensesByLensIdLeadsQuery({
    lensId: currentLensId,
    page: paginationModel.page,
    count: pageSize,
    wished: false,
    q: currentFilter,
    order: sortModel,
  })

  const [untrashLead] = usePostLensesByLensIdLeadsAndLeadIdUntrashMutation()

  const handleUntrashLead = async () => {
    try {
      await untrashLead({ lensId: currentLensId, leadId }).unwrap()

      await untrashLeadRefetch()
      await wishlistRefetch()
      await leadsRefetch()

      toast("Lead has been declared wished")
    } catch (err) {
      console.error(err)
      toast.error("Failed to untrash lead")
    }
  }

  return (
    <Box>
      <LbButton onClick={handleUntrashLead} startIcon={<Update />} size="small">
        Declare Wished
      </LbButton>

      <Typography
        component="p"
        sx={{ mt: 1, mr: 10, ml: 0.5 }}
        color="text.secondary"
        variant="caption"
      >
        Want to move this lead out of trash?
      </Typography>
    </Box>
  )
}
