import { LbFade } from "@components/animations/LbFade/LbFade"
import { LbCopyright } from "@components/display/LbCopyright/LbCopyright"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import { LbTrialBanner } from "@components/display/LbTrialBanner/LbTrialBanner"
import { LbMainDrawer } from "@components/navigation/LbMainDrawer/LbMainDrawer"
import { LbMainNav } from "@components/navigation/LbMainNav/LbMainNav"
import { CENTERED_FLEX_COL, ROUTES } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box } from "@mui/material"
import { WebSocketProvider } from "@providers/WebSocketProvider"
import { selectAuthState } from "@redux/authSlice"
import { selectOnboardingState } from "@redux/onboardingSlice"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

export const DashboardLayout = () => {
  const location = useLocation()

  const { user, subscriptionRequired } = useAppSelector(selectAuthState)

  const { onboardingComplete } = useAppSelector(selectOnboardingState)

  const isWishlistScreen = location.pathname === ROUTES.APP

  const isFavoritesScreen =
    location.pathname === ROUTES.APP + "/" + ROUTES.PRIVATE.FAVORITES

  const isTrashScreen =
    location.pathname === ROUTES.APP + "/" + ROUTES.PRIVATE.TRASH

  const showDrawer = isWishlistScreen || isFavoritesScreen || isTrashScreen

  if (subscriptionRequired) {
    return <Navigate to={ROUTES.ROOT + ROUTES.PRIVATE.SUBSCRIPTION} />
  }

  if (!onboardingComplete && user?.organization?.onboarding_step !== "FINISHED")
    return <Navigate to={ROUTES.ROOT + ROUTES.PRIVATE.ONBOARDING} />

  return (
    <WebSocketProvider>
      <Box
        sx={{
          display: "flex",
          overflowX: "hidden",
        }}
      >
        <Box
          component="main"
          sx={{
            width: "100%",
            mt: subscriptionRequired ? 0 : "30px",
          }}
        >
          {!subscriptionRequired && (
            <Box
              component="nav"
              sx={{
                display: "flex",
                borderRadius: 0,
                height: 30,
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                zIndex: 50,
                alignItems: "center",
                px: 2,
                pl: 1,
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <LbMainNav />

              <Link to={ROUTES.ROOT} style={{ zIndex: 10 }}>
                <Box sx={{ height: "100%", ...CENTERED_FLEX_COL }}>
                  <LBLogo png width={22} />
                </Box>
              </Link>

              <Box sx={{ flex: 1 }} />

              <LbCopyright />

              <LbTrialBanner />
            </Box>
          )}

          <LbFade
            styles={{
              minHeight: "calc(100vh - 30px)",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              width: "100vw",
            }}
          >
            <Outlet />

            {showDrawer && <LbMainDrawer />}
          </LbFade>
        </Box>
      </Box>
    </WebSocketProvider>
  )
}
