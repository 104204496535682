import { NewableLead } from "@api/leadbayApi"
import { CENTERED_FLEX_COL, ROUTES } from "@constants/index"
import { Close, CopyAll, Share } from "@mui/icons-material"
import { Box, IconButton, Modal, Tooltip } from "@mui/material"
import { LightLead } from "@typing/index"
import { openGoogleMapDirection } from "@utils/openGoogleMapDirection"
import { Map, useMap } from "@vis.gl/react-google-maps"
import { useEffect, useMemo } from "react"
import { toast } from "react-toastify"
import { LbMarker } from "./LbMarker"

const BUTTON_OFFSET = -50

interface LbGoogleMapExportProps {
  selectedLeadsData: NewableLead[]
  open: boolean
  onClose: () => void
}

export const LbGoogleMapExport = ({
  selectedLeadsData,
  open,
  onClose,
}: LbGoogleMapExportProps) => {
  if (!open) return null

  const map = useMap()

  const generateLightLeads = (selectedLeadsData: NewableLead[]) => {
    return selectedLeadsData
      .map((lead) => {
        const lat = lead?.location?.pos?.[0]
        const lng = lead?.location?.pos?.[1]

        if (lat !== undefined && lng !== undefined) {
          return { lat, lng, name: lead.name, logo: lead.logo }
        }

        return null
      })
      .filter((marker) => marker !== null) as LightLead[]
  }

  const markers = useMemo(
    () => generateLightLeads(selectedLeadsData),
    [selectedLeadsData],
  ) as unknown as LightLead[]

  useEffect(() => {
    if (map && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat!, marker.lng!))
      })
      map.fitBounds(bounds)
    }
  }, [map, markers])

  const generateGoogleMapsUrl = () => {
    const baseUrl = `${window.location.protocol}//${window.location.host}/${ROUTES.PUBLIC.MAP}`

    const markersParam = generateLightLeads(selectedLeadsData)

    const encodedMarkersParam = btoa(
      encodeURIComponent(JSON.stringify(markersParam)),
    )

    return `${baseUrl}?markers=${encodedMarkersParam}`
  }

  const handleCopyMapLink = async () => {
    const url = generateGoogleMapsUrl()

    if (!url) return

    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(url)
        toast("Map link copied to clipboard")
      }
    } catch (err) {
      console.error("Could not copy text: ", err)
    }
  }

  const handleShareMapWithMarkers = async () => {
    const url = generateGoogleMapsUrl()

    if (!url) return

    try {
      if (navigator.share) {
        await navigator.share({
          title: "Share Leads Map",
          text: "Check out these leads",
          url: url,
        })
      } else {
        await navigator.clipboard.writeText(url)
        alert("Map link copied to clipboard!")
      }
    } catch (error) {
      console.error("Error sharing:", error)
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        ...CENTERED_FLEX_COL,
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Tooltip title="Close map">
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: BUTTON_OFFSET,
              zIndex: 9999,
              background: "white",
              borderRadius: "100%",
            }}
          >
            <IconButton id="map-close-button" onClick={onClose} color="primary">
              <Close />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Copy to clipboard">
          <Box
            sx={{
              position: "absolute",
              top: 55,
              right: BUTTON_OFFSET,
              zIndex: 9999,
              background: "white",
              borderRadius: "100%",
            }}
          >
            <IconButton
              id="map-copy-button"
              onClick={handleCopyMapLink}
              color="primary"
            >
              <CopyAll />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Share map with markers">
          <Box
            sx={{
              position: "absolute",
              top: 110,
              right: BUTTON_OFFSET,
              zIndex: 9999,
              background: "white",
              borderRadius: "100%",
            }}
          >
            <IconButton
              id="map-share-button"
              onClick={handleShareMapWithMarkers}
              color="primary"
            >
              <Share />
            </IconButton>
          </Box>
        </Tooltip>

        <Map
          defaultCenter={{ lat: 51.5074, lng: 0.1278 }}
          defaultZoom={10}
          mapId="bf51a910020fa25a"
          style={{
            width: "65vw",
            height: "65vh",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          {markers.map((marker) => (
            <div key={marker.name}>
              <LbMarker
                position={marker}
                lead={marker}
                onClick={() => openGoogleMapDirection(marker)}
              />
            </div>
          ))}
        </Map>
      </Box>
    </Modal>
  )
}
