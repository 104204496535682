import { FeatureFlag, LeadOrder, NewCounts, type Lead1 } from "@api/leadbayApi"
import { LikeStatus } from "@components/navigation/LbMainDrawer/partials/LbLeadInfos/LbFavoriteModule"
import { CURRENT_LENS_ID, SELECTED_IDS } from "@constants/index"
import { type GridRowParams } from "@mui/x-data-grid"
import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { type BeforeInstallPromptEvent, type ThemeMode } from "@typing/index"
import { calcPageSize } from "@utils/calcPageSize"
import localforage from "localforage"
import { type RootState } from "./store"

const rowHeight = 45
const getAvailableSpace = () => window.innerHeight - 167 - rowHeight

export type NavDrawerState =
  | "SCORING_PARAMETERS"
  | "WISHLIST_FILTERS"
  | "LEAD_INFOS"
  | "LENS_CRUD"

export type WishlistViewMode = "discover" | "monitor" | "timeline"

export type FavoriteViewMode = "all" | "wanted" | "unwanted"

export interface ToggleDrawerParams {
  isOpen: boolean
  partial?: NavDrawerState
  data?: GridRowParams
}

export interface PaginationModel {
  page: number
  pageSize: number
}

export interface TempLikedLead {
  id: string
  liked: LikeStatus
}

interface CommonsState {
  navDrawerIsOpen: boolean
  navDrawerPartial?: NavDrawerState
  lastNavDrawerPartial: NavDrawerState
  themeMode: ThemeMode
  drawerData?: GridRowParams
  wishListLoading?: boolean
  wishlistViewMode: WishlistViewMode
  favoriteViewMode: FavoriteViewMode
  leadHistory: Lead1[]
  paginationModel: PaginationModel
  pageSize?: number
  deferredPrompt?: BeforeInstallPromptEvent | null
  currentLensId: string
  tempLikedLeads: Array<TempLikedLead>
  currentFilter?: string
  sortModel: LeadOrder[] | undefined
  selectedIds: string[]
  initialCountData?: NewCounts
  featuresFlags: FeatureFlag[]
}

const DEFAULT_NAV_DRAWER_PARTIAL: NavDrawerState = "WISHLIST_FILTERS"

const initialState: CommonsState = {
  navDrawerIsOpen: true,
  navDrawerPartial: DEFAULT_NAV_DRAWER_PARTIAL,
  lastNavDrawerPartial: DEFAULT_NAV_DRAWER_PARTIAL,
  themeMode: "light",
  drawerData: undefined,
  wishListLoading: false,
  wishlistViewMode: "discover",
  favoriteViewMode: "wanted",
  leadHistory: [],
  paginationModel: {
    page: 0,
    pageSize: 10,
  },
  pageSize: calcPageSize(getAvailableSpace(), rowHeight),
  deferredPrompt: null,
  currentLensId: "",
  tempLikedLeads: [],
  sortModel: ["SCORE:DESC"],
  currentFilter: undefined,
  selectedIds: [],
  initialCountData: undefined,
  featuresFlags: [],
}

export const commons = createSlice({
  name: "commons",

  initialState,

  reducers: {
    toggleNavDrawer: (state, action: PayloadAction<ToggleDrawerParams>) => {
      if (state.navDrawerPartial)
        state.lastNavDrawerPartial = state.navDrawerPartial

      state.navDrawerIsOpen = action.payload.isOpen
      state.navDrawerPartial = action.payload.partial
      // @ts-expect-error drawerData is not always defined
      state.drawerData = action.payload.data
      state.leadHistory = []
    },

    goToDefaultNavDrawerPartial: (
      _,
      action: PayloadAction<Omit<ToggleDrawerParams, "isOpen" | "partial">>,
    ) => {
      toggleNavDrawer({
        isOpen: true,
        partial: DEFAULT_NAV_DRAWER_PARTIAL,
        data: action.payload.data,
      })
    },

    setLeadHistory: (state, action: PayloadAction<Lead1[]>) => {
      state.leadHistory = action.payload
    },

    setDrawerData: (state, action: PayloadAction<GridRowParams>) => {
      // @ts-expect-error drawerData is not always defined
      state.drawerData = action.payload
    },

    setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
      state.themeMode = action.payload
    },

    setWishListLoading: (state, action: PayloadAction<boolean>) => {
      state.wishListLoading = action.payload
    },

    setFavoriteViewMode: (state, action: PayloadAction<FavoriteViewMode>) => {
      state.favoriteViewMode = action.payload
    },

    setWishlistViewMode: (state, action: PayloadAction<WishlistViewMode>) => {
      state.wishlistViewMode = action.payload
    },

    setPaginationModel(state, action: PayloadAction<PaginationModel>) {
      state.paginationModel = {
        ...action.payload,
        pageSize: action.payload.pageSize > 0 ? action.payload.pageSize : 10,
      }
    },

    setTempLikedLeads(state, action: PayloadAction<TempLikedLead>) {
      const index = state.tempLikedLeads.findIndex(
        (lead) => lead.id === action.payload.id,
      )

      if (index !== -1) {
        state.tempLikedLeads[index] = action.payload
      } else {
        state.tempLikedLeads.push(action.payload)
      }
    },

    setDeferredPrompt(
      state,
      action: PayloadAction<BeforeInstallPromptEvent | null>,
    ) {
      state.deferredPrompt = action.payload
    },

    setCurrentLensId(state, action: PayloadAction<string>) {
      state.currentLensId = action.payload

      state.paginationModel = {
        page: 0,
        pageSize: state.pageSize && state.pageSize > 0 ? state.pageSize : 10,
      }

      localforage.setItem(CURRENT_LENS_ID, action.payload).catch(console.error)
    },

    recalculatePageSize(state) {
      state.pageSize = calcPageSize(getAvailableSpace(), rowHeight)
    },

    setCurrentFilter(state, action: PayloadAction<string>) {
      state.currentFilter = action.payload
    },

    setSortModel(state, action: PayloadAction<LeadOrder[] | undefined>) {
      state.sortModel = action.payload
    },

    setSelectedIds(state, action: PayloadAction<string[]>) {
      state.selectedIds = action.payload

      localforage.setItem(SELECTED_IDS, action.payload).catch(console.error)
    },

    setInitialCountData(state, action: PayloadAction<NewCounts | undefined>) {
      state.initialCountData = action.payload
    },

    setFeaturesFlags(state, action: PayloadAction<FeatureFlag[]>) {
      state.featuresFlags = action.payload
    },
  },
})

export const {
  toggleNavDrawer,
  goToDefaultNavDrawerPartial,
  setThemeMode,
  setWishListLoading,
  setDrawerData,
  setWishlistViewMode,
  setLeadHistory,
  setPaginationModel,
  setDeferredPrompt,
  setFavoriteViewMode,
  setCurrentLensId,
  recalculatePageSize,
  setTempLikedLeads,
  setCurrentFilter,
  setSortModel,
  setSelectedIds,
  setInitialCountData,
  setFeaturesFlags,
} = commons.actions

export const selectCommonsState = (state: RootState): CommonsState =>
  state.commons

export default commons.reducer
