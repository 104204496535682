import { toast } from "react-toastify"

export const checkEmail = (email: string): boolean => {
  const personalEmailProviders: string[] = [
    "gmail",
    "yahoo",
    "hotmail",
    "aol",
    "outlook",
    "live",
    "msn",
    "icloud",
    "mail",
    "yandex",
    "inbox",
    "lavabit",
    "protonmail",
    "gmx",
    "zoho",
    "mailinator",
    "tutanota",
    "comcast",
    "verizon",
    "att",
    "sbcglobal",
    "bellsouth",
  ]

  const emailDomain = email.split("@")[1]?.split(".")[0]

  const isNonProEmail =
    emailDomain && personalEmailProviders.includes(emailDomain.toLowerCase())

  if (isNonProEmail) {
    toast.error("Please Enter a business email")
  }

  return !!isNonProEmail
}
