import {
  useGetImportsByImportIdQuery,
  usePostImportsByImportIdUpdateMappingsMutation,
  type LeadStatus,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { LbIaLoader } from "@components/feedback/LbIaLoader/LbIaLoader"
import { CENTERED_FLEX_COL, MAPPING_STEP } from "@constants/index"
import { Warning } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import {
  selectOnboardingState,
  setMappingStep,
  setNextStep,
} from "@redux/onboardingSlice"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"

import { LbStatusRow } from "@components/display/LbStatusRow/LbStatusRow"
import { useAppSelector } from "@hooks/useAppSelector"
import { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Step } from "../../Step/Step"

export type StatuesTypes = Record<string, LeadStatus>

export const OurStatuses = () => {
  const dispatch = useDispatch()

  const { importId } = useAppSelector(selectOnboardingState)

  const [statuses, setStatuses] = useState<StatuesTypes | undefined>()
  const [mappingFields, setMappingFields] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [statusInterval, setStatusInterval] = useState<undefined | number>(
    undefined,
  )

  const { data, refetch } = useGetImportsByImportIdQuery(
    {
      importId: importId!,
    },
    {
      pollingInterval: statusInterval,
      skip: !importId,
    },
  )

  const [crmUpdateMappings] = usePostImportsByImportIdUpdateMappingsMutation()

  const handleNext = async () => {
    try {
      setLoading(true)

      if (
        !data ||
        (data?.mappings?.fields &&
          Object.keys(data?.mappings?.fields).length === 0)
      )
        throw new Error("No mappings")

      if (!statuses) throw new Error("Missing statuses")

      const response = await crmUpdateMappings({
        importId: importId!,
        mappings1: {
          statuses,
          fields: { ...data?.mappings?.fields },
          default_status:
            Object.values(statuses).length > 1
              ? "WANTED"
              : Object.values(statuses)[0],
        },
      })

      if ("error" in response) throw new Error("Error updating mappings")

      setStatusInterval(2000)
    } catch (error) {
      setStatusInterval(undefined)

      toast.error("An unexpected error occurred.")

      console.error(error)
    }
  }

  useAsyncEffect(async () => {
    if (loading) return

    if (
      data?.pre_processing?.status_samples &&
      data?.pre_processing?.status_samples?.length > 0
    ) {
      setMappingFields(data.pre_processing.status_samples)
    } else {
      setMappingFields(["Default Status"])
    }

    const hintStatuses = data?.pre_processing?.hints?.statuses

    setStatuses(hintStatuses)
  }, [data, loading])

  useAsyncEffect(async () => {
    try {
      if (!data?.processing?.finished || !loading) return

      setTimeout(async () => {
        await refetch()

        dispatch(setNextStep(4))

        setLoading(false)
      }, 2000)
    } catch (error) {
      setStatusInterval(undefined)

      toast.error("An unexpected error occurred.")

      setLoading(false)

      console.error(error)
    }
  }, [data])

  const handleChange = (value: StatuesTypes | null, remove: boolean) => {
    if (!value) return

    if (remove) {
      const newStatuses = { ...statuses }

      delete newStatuses[Object.keys(value)[0]]

      setStatuses(newStatuses)

      return
    }

    setStatuses({
      ...statuses,
      ...value,
    })
  }

  const handleRetry = async () => {
    dispatch(setNextStep(0))
    dispatch(setMappingStep(0))
    setStatusInterval(undefined)

    localforage.removeItem(MAPPING_STEP).catch(console.error)
  }

  useEffect(() => {
    if (mappingFields.length >= 99)
      toast.warn(
        "It seems there might be an error in your field mapping. Please review it in the previous step.",
      )
  }, [mappingFields])

  const currentProgress = useMemo(() => {
    if (!data?.processing?.progress || data?.processing?.progress < 0.01)
      return 0.01

    return data?.processing?.progress < 0.82 ? data?.processing?.progress : 0.82
  }, [data])

  return statusInterval ? (
    <>
      {!data?.processing?.error ? (
        <>
          {data?.processing?.progress !== undefined && (
            <LbIaLoader progress={currentProgress} />
          )}
        </>
      ) : (
        <Box sx={{ ...CENTERED_FLEX_COL, gap: 3 }}>
          <Warning color="error" sx={{ fontSize: 40 }} />

          <Typography color="error" fontWeight="bold">
            An error occurred while parsing your data.
          </Typography>

          <Box>
            <LbButton onClick={handleRetry} sx={{ mr: 2 }}>
              Retry
            </LbButton>

            <a href="mailto:help@leadbay.ai" rel="noopenner">
              <LbButton>Contact us</LbButton>
            </a>
          </Box>
        </Box>
      )}
    </>
  ) : (
    <Step
      stepIndex={3}
      title={`🧠 We are going to match your CRM data together (2/2)`}
      ctaTitle="Get my results"
      onValidate={handleNext}
      ctaLoading={loading}
      ctaDisabled={loading || Object.keys(statuses ?? {}).length === 0}
      sx={{
        maxWidth: "700px",
        margin: "0 auto",
      }}
    >
      <Box
        className="hide-scrollbar"
        sx={{
          display: "flex",
          gap: 1.5,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "35px",
          }}
        >
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              fontSize: "14px",
            }}
          >
            Your statuses
          </Typography>

          <Typography
            variant="h6"
            color="text.secondary"
            sx={{
              width: 250,
              fontSize: "14px",
            }}
          >
            Our statuses
          </Typography>
        </Box>

        {mappingFields?.slice(0, 99).map((field, index) => (
          <LbStatusRow
            key={field + index}
            value={statuses}
            onChange={handleChange}
            row={{
              title: field,
              userFiled: mappingFields,
            }}
          />
        ))}

        {Object.keys(statuses ?? {}).length === 0 && (
          <Typography sx={{ mt: 2 }} color="text.secondary">
            Please choose at least one status to proceed to the next step.
          </Typography>
        )}
      </Box>
    </Step>
  )
}
