import { useLottie } from "lottie-react"
import { useEffect, type CSSProperties } from "react"

import dangerAnim from "./danger-anim.json"
import errorAnim from "./error-anim.json"
import loadingAnim from "./loading-anim.json"
import releaseSphere from "./release-sphere.json"
import successAnim from "./success-anim.json"
import uploadAnim from "./upload-anim.json"

export interface FaLottieProps {
  className?: string
  type: "error" | "loading" | "danger" | "upload" | "success" | "releaseSphere"
  loop?: boolean
  speed?: number
  style?: CSSProperties
  isPaused?: boolean
  isStopped?: boolean
}

export const LbLottie = (props: FaLottieProps) => {
  const {
    type,
    loop = true,
    speed = 1,
    style,
    isPaused = false,
    isStopped = false,
  } = props

  const animations = {
    error: errorAnim,
    loading: loadingAnim,
    danger: dangerAnim,
    upload: uploadAnim,
    success: successAnim,
    releaseSphere: releaseSphere,
  }

  const options = {
    animationData: animations[type],
    loop,
    autoplay: !isStopped,
    speed,
    style,
  }

  const { View, play, stop, pause, setSpeed } = useLottie(options)

  useEffect(() => {
    setSpeed(speed)
  }, [speed, setSpeed])

  useEffect(() => {
    if (isStopped) {
      stop()
    } else if (isPaused) {
      pause()
    } else {
      play()
    }
  }, [isPaused, isStopped, play, stop, pause])

  return <>{View}</>
}
