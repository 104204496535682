import { type Lead1 } from "@api/leadbayApi"
import AnimatedNumber from "@crossfox/react-animated-number"
import { Box, Chip, Grid, LinearProgress, Typography } from "@mui/material"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { upperCaseFirstLetter } from "utils/globals"

interface LbResultRowProps {
  row: Lead1
  index: number
}

export const LbResultRow = (props: LbResultRowProps) => {
  const { row, index } = props
  const { score, name, location, size, description } = row

  const [localesSore, setLocalScore] = useState(0)

  useEffect(() => {
    if (!score) return

    setLocalScore(score)
  }, [score])

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        component={motion.span}
        animate={{ opacity: 1, translateY: 0 }}
        initial={{ opacity: 0, translateY: -10 }}
        transition={{ ease: "linear", delay: 0.4 }}
        variant="h5"
        fontFamily="BasementGrotesque"
        sx={{
          position: "absolute",
          left: -55,
        }}
      >
        {index + 1}
      </Typography>

      <Box sx={{ width: "100%", flex: 1 }}>
        <Grid
          container
          sx={[
            (theme) => ({
              background: theme.palette.secondary.main,
              px: "25px",
              py: "30px",
              borderRadius: "24px",
            }),
          ]}
          alignItems="center"
        >
          <Grid item xs={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100px",
                height: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{ mb: 0.5, fontFamily: "BasementGrotesque" }}
              >
                <AnimatedNumber
                  rate={100}
                  value={localesSore}
                  duration={1000}
                  suffix="%"
                />
              </Typography>

              <LinearProgress
                sx={{ width: "100%", height: "8px" }}
                variant="determinate"
                color="success"
                value={localesSore}
              />
            </Box>
          </Grid>

          <Grid item xs={4} sx={{ px: 2 }}>
            <Typography
              component="h3"
              fontFamily="BasementGrotesque"
              fontSize={16}
            >
              {upperCaseFirstLetter(name)}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 0.5,
                mt: 1.5,
                mr: 2,
              }}
            >
              {location.city && (
                <Chip color="primary" label={location.city} size="small" />
              )}
              {location.state && (
                <Chip color="primary" label={location.state} size="small" />
              )}
              {location.country && (
                <Chip color="primary" label={location.country} size="small" />
              )}
              {size?.max && size.max > 0 ? (
                <Chip
                  color="primary"
                  label={`${size.min} - ${size.max} employees`}
                  size="small"
                />
              ) : null}
            </Box>
          </Grid>

          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              color="text.secondary"
              variant="subtitle2"
              lineHeight={1.1}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
