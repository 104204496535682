import { LbFade } from "@components/animations/LbFade/LbFade"
import { APP_DRAWER_WIDTH, ROUTES } from "@constants/index"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Box, Tab, Tabs } from "@mui/material"
import {
  commons,
  selectCommonsState,
  type NavDrawerState,
} from "@redux/commonsSlice"
import { useEffect, useMemo, useState, type ReactNode } from "react"
import { LbLeadInfos } from "./partials/LbLeadInfos/LbLeadInfos"
import { LbLensSelector } from "./partials/LbLensSelector"
import { LbLensesCrud } from "./partials/LbLensesCrud/LbLensesCrud"
import { LbScoringParameters } from "./partials/LbScoringParameters/LbScoringParameters"
import { LbWishListFilters } from "./partials/LbWishListFilters/LbWishlistFilters"

const { toggleNavDrawer } = commons.actions

export const LbMainDrawer = () => {
  const { navDrawerPartial, drawerData, navDrawerIsOpen, wishlistViewMode } =
    useAppSelector(selectCommonsState)

  const dispatch = useAppDispatch()

  const handleNavigate = (menu: NavDrawerState) => {
    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: menu,
      }),
    )
  }

  const partials: Record<NavDrawerState, ReactNode> = useMemo(
    () => ({
      WISHLIST_FILTERS: <LbWishListFilters />,
      SCORING_PARAMETERS: <LbScoringParameters />,
      LEAD_INFOS: <LbLeadInfos leadData={drawerData?.row} />,
      LENS_CRUD: <LbLensesCrud />,
    }),
    [drawerData],
  )

  const isFavoritesScreen =
    location.pathname === ROUTES.APP + ROUTES.PRIVATE.FAVORITES
  const isTimeLine = wishlistViewMode === "timeline"

  const currentPartial = useMemo(() => {
    if (isTimeLine) {
      return "LEAD_INFOS"
    }

    const current = isFavoritesScreen
      ? "LEAD_INFOS"
      : (navDrawerPartial as NavDrawerState)

    if (current === "LEAD_INFOS" && !drawerData?.row) {
      return "SCORING_PARAMETERS"
    }

    return current
  }, [isFavoritesScreen, navDrawerPartial, drawerData, wishlistViewMode])

  useEffect(() => {
    toggleNavDrawer({
      isOpen: navDrawerIsOpen,
      partial: currentPartial,
    })
  }, [])

  const [tabs, setTabs] = useState<
    Array<{ label: string; value: NavDrawerState }> | undefined
  >(
    currentPartial === "LEAD_INFOS"
      ? undefined
      : [
          {
            label: "Filters",
            value: "WISHLIST_FILTERS",
          },
          {
            label: "Scoring",
            value: "SCORING_PARAMETERS",
          },
        ],
  )

  useEffect(() => {
    if (currentPartial === "LEAD_INFOS") {
      setTabs(undefined)
    } else {
      setTabs([
        {
          label: "Filters",
          value: "WISHLIST_FILTERS",
        },
        {
          label: "Scoring",
          value: "SCORING_PARAMETERS",
        },
      ])
    }
  }, [currentPartial])

  const currentTabs = ["LEAD_INFOS", "LENS_CRUD"].includes(currentPartial)
    ? "SCORING_PARAMETERS"
    : currentPartial

  return (
    <Box
      component="nav"
      className="hide-scrollbar"
      sx={{
        background: "white",
        height: "calc(100vh - 30px)",
        overflowY: "auto",
        position: navDrawerIsOpen ? "relative" : "absolute",
        width: APP_DRAWER_WIDTH,
        right: 0,
        transform: !navDrawerIsOpen ? "translateX(100%)" : "translateX(0)",
        pt: "12px",
        pb: 1,
        transition: "all 0.2s ease",
      }}
    >
      {currentPartial !== "LEAD_INFOS" && (
        <Box mb={0.5} width="100%" px={2}>
          <LbLensSelector />
        </Box>
      )}

      {tabs && (
        <Tabs
          value={currentTabs}
          onChange={(_, value: NavDrawerState) => handleNavigate(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mb: 1,
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.8rem",
              }}
            />
          ))}
        </Tabs>
      )}

      <LbFade key={currentPartial}>{partials[currentPartial]}</LbFade>
    </Box>
  )
}
