import { useGetTimelineBlocksQuery } from "@api/leadbayApi"
import { useAppSelector } from "@hooks/useAppSelector"
import { selectAuthState } from "@redux/authSlice"
import {
  generateWishlistRowsSimplified,
  type TransformedEntry,
} from "@utils/generateWishListRows"
import { useEffect, useMemo, useState } from "react"

interface UseTimelineProps {
  endOfListElementRef: React.RefObject<HTMLDivElement>
}

export const useTimeline = ({ endOfListElementRef }: UseTimelineProps) => {
  const [page, setPage] = useState(0)

  const { user } = useAppSelector(selectAuthState)

  const {
    data: rawTimeline,
    isFetching,
    refetch,
  } = useGetTimelineBlocksQuery({
    page,
    count: 10,
  })

  const [lastComputed, setLastComputed] = useState<boolean>(
    user?.computing_daily_timeline ?? false,
  )

  useEffect(() => {
    if (lastComputed && !user?.computing_daily_timeline) {
      refetch()
    }

    setLastComputed(user?.computing_daily_timeline ?? false)
  }, [user])

  const [_blocks, setBlocks] = useState<
    NonNullable<typeof rawTimeline>["items"]
  >([])

  const blocks = useMemo(
    () =>
      _blocks
        .filter(
          (block, index, self) =>
            self.findIndex((b) => b.id === block.id) === index,
        )
        .sort(
          (a, b) =>
            new Date(b.created_on).getTime() - new Date(a.created_on).getTime(),
        ),
    [_blocks],
  )

  useEffect(() => {
    // On endOfListElementRef is visible, fetch more data
    const observer = new IntersectionObserver(async ([entry]) => {
      const totalPages = rawTimeline?.pagination?.pages

      if (totalPages === undefined) {
        return
      }

      if (!entry.isIntersecting) {
        return
      }

      if (page + 1 >= totalPages) {
        return
      }

      setPage((page) => page + 1)
    })

    if (endOfListElementRef.current) {
      observer.observe(endOfListElementRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [endOfListElementRef, page, rawTimeline])

  useEffect(() => {
    const newBlocks = rawTimeline?.items

    if (newBlocks) {
      setBlocks((blocks) => [...blocks, ...newBlocks])
    }
  }, [rawTimeline])

  const timeline: Array<
    { id: string } & (
      | { type: "date"; value: { date: Date; text?: string } }
      | { type: "text"; value: { text: string } }
      | { type: "table"; value: { rows: TransformedEntry[]; title: string } }
    )
  > =
    blocks?.reduce<typeof timeline>(
      (acc, block) => {
        const lastDateBlock = acc.findLast(
          (block) => block.type === "date",
        ) as unknown as
          | Extract<(typeof acc)[number], { type: "date" }>
          | undefined
        const dateChanged =
          lastDateBlock?.value.date.getTime() !==
          new Date(block.created_on).getTime()

        if (dateChanged) {
          acc.push({
            id: block.id,
            type: "date",
            value: { date: new Date(block.created_on) },
          })
        }

        if (block.type === "horoscope") {
          acc.push({
            id: block.id,
            type: "text",
            value: { text: block.horoscope },
          })
        }

        if (block.type === "leads") {
          const leads = block.leads.map((lead) => ({
            ...lead,
            block_id: block.id,
          }))

          acc.push({
            id: block.id,
            type: "table",
            value: {
              rows: generateWishlistRowsSimplified(leads),
              title: block.title,
            },
          })
        }

        return acc
      },
      [] as typeof timeline,
    ) ?? []

  return { timeline, noData: !rawTimeline?.items?.length, isFetching }
}
