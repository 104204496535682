import { useGetStripePortalQuery } from "@api/leadbayApi"
import { LBLogo } from "@components/display/LbLogo/LBLogo"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { ROUTES } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import { useLogout } from "@hooks/useLogout"
import { Logout } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { selectAuthState } from "@redux/authSlice"
import { Navigate } from "react-router-dom"

const SubscriptionScreenV2 = () => {
  const { user, trialDaysLeft, subscriptionRequired } =
    useAppSelector(selectAuthState)

  const { data: billingUrl } = useGetStripePortalQuery()

  const { logout } = useLogout()

  if (!subscriptionRequired) {
    return <Navigate to={ROUTES.APP} />
  }

  return (
    <Box
      component="article"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 10,
          left: 20,
          right: 20,
          zIndex: 9999,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <LBLogo />
        </Box>

        <IconButton
          sx={{ color: "black" }}
          onClick={async () => await logout()}
        >
          <Logout />
        </IconButton>
      </Box>

      <Box
        component="header"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          px: 4,
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          fontFamily="BasementGrotesque"
          sx={{ mb: 4 }}
        >
          Subscription
        </Typography>

        {user?.organization.billing.suspended ? (
          <Typography variant="h6">
            Your subscription is suspended. Please update your billing
            information.
          </Typography>
        ) : user?.organization.billing.status === "ACTION_NEEDED" ? (
          <Typography variant="h6">
            Your subscription requires action. Please update your billing
            information.
          </Typography>
        ) : user?.organization.billing.status === "NOT_SET_UP" &&
          trialDaysLeft <= 0 ? (
          <Typography variant="h6">
            Your subscription is not set up. Please update your billing
            information.
          </Typography>
        ) : user?.organization.billing.status !== "OK" && trialDaysLeft <= 0 ? (
          <Typography variant="h6">The trial period has ended.</Typography>
        ) : null}

        {user?.organization.billing.missing_seat && (
          <Typography variant="h6">
            You have more users than your subscription allows. Please upgrade
            your subscription seats.
          </Typography>
        )}

        {subscriptionRequired && billingUrl?.url && (
          <Box sx={{ mt: 4 }}>
            <a href={billingUrl.url} target="_blank" rel="noreferrer">
              <LbButton size="large">Manage my subscription</LbButton>
            </a>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SubscriptionScreenV2
