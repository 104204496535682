import { useAppSelector } from "@hooks/useAppSelector"
import { selectOnboardingState } from "@redux/onboardingSlice"
import { OurStatuses } from "./partials/OurStatuses"
import { YourFields } from "./partials/YourFields"

export const StepMapping = () => {
  const { mappingStep } = useAppSelector(selectOnboardingState)

  return mappingStep === 1 ? <OurStatuses /> : <YourFields />
}
