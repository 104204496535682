import { LbScoringCrud } from "@components/navigation/LbMainDrawer/partials/LbScoringParameters/LbScoringCrud"
import { ExpandMore } from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
} from "@mui/material"
import { type ReactNode } from "react"

interface LbScoringCardProps {
  type: "size" | "keywords" | "similar_to_status" | "sectors" | "location"
  title: string
  expand: boolean
  setExpanded: (expanded: boolean) => void
  children?: ReactNode
  isDefaultLens: boolean
}

export const LbScoringCard = ({
  type,
  title,
  expand,
  setExpanded,
  children,
  isDefaultLens,
}: LbScoringCardProps) => {
  const handleSetExpanded = () => {
    setExpanded(!expand)
  }

  return (
    <Accordion
      elevation={0}
      sx={{
        px: 1,
        pb: 1,
        borderRadius: "20px",
        pointerEvents: isDefaultLens ? "none" : "auto",
      }}
      expanded={expand}
      onChange={handleSetExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography
          sx={{
            opacity: isDefaultLens ? 0.6 : 1,
          }}
          fontSize={16}
          fontFamily="Hanken Grotesk"
          fontWeight="bold"
        >
          {title}
        </Typography>

        {isDefaultLens && (
          <>
            <Box sx={{ flex: 1 }} />

            <Chip
              variant="outlined"
              sx={{
                fontWeight: "bold",
                fontSize: "0.7rem",
                mr: 1,
                opacity: 0.6,
              }}
              label="Read only"
            />
          </>
        )}
      </AccordionSummary>

      <AccordionDetails sx={{ mb: 0, pt: 0 }}>
        <LbScoringCrud selectedType={type} />

        {children}
      </AccordionDetails>
    </Accordion>
  )
}
