import {
  useDeleteLensesByLensIdMutation,
  useGetLensesByLensIdScoringQuery,
  useGetLensesQuery,
  usePostLensesByLensIdDemoteMutation,
  usePostLensesByLensIdMutation,
  usePostLensesByLensIdPromoteMutation,
  type Lens,
} from "@api/leadbayApi"
import { DEFAULT_LENS } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import {
  ArrowBack,
  Check,
  Delete,
  Edit,
  North,
  South,
} from "@mui/icons-material"
import {
  Box,
  Chip,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { selectAuthState } from "@redux/authSlice"
import { selectCommonsState } from "@redux/commonsSlice"
import { nameLens } from "@utils/renameDefaultLens"
import { useClickAway } from "ahooks"
import clsx from "clsx"
import { useRef, useState, type SyntheticEvent } from "react"
import { toast } from "react-toastify"

interface LbLensesCardProps {
  lens: Lens
  temporary: boolean
  isPrivate: boolean
  onClose: () => void
}

export const LbLensCard = ({
  lens,
  temporary,
  isPrivate,
  onClose,
}: LbLensesCardProps) => {
  const { token } = useAppSelector(selectAuthState)

  const { refetch } = useGetLensesQuery(undefined, {
    skip: !token,
  })

  const ref = useRef(null)

  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [lensTitle, setLensTitle] = useState("")

  const [deleteLensesByLensId] = useDeleteLensesByLensIdMutation()
  const [postLensesByLensId] = usePostLensesByLensIdMutation()
  const [lensIdPromote] = usePostLensesByLensIdPromoteMutation()
  const [lensIdDemote] = usePostLensesByLensIdDemoteMutation()

  const { currentLensId } = useAppSelector(selectCommonsState)

  const { refetch: refetchScoring } = useGetLensesByLensIdScoringQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const isCurrentLens = currentLensId === lens.id
  const isDefaultLens = lens.name === DEFAULT_LENS
  const isDraftLens = !!lens.draft_of

  const resetFilters = async () => {
    await refetchScoring()
    await refetch()
  }

  const handlePromoteLens = async () => {
    try {
      setLoading(true)

      await lensIdPromote({ lensId: lens.id }).unwrap()

      await resetFilters()
    } catch (error) {
      toast.error("An error occurred")
    } finally {
      setLoading(false)
    }
  }

  const handleDemoteLens = async () => {
    try {
      setLoading(true)

      await lensIdDemote({ lensId: lens.id }).unwrap()

      await resetFilters()
    } catch (error) {
      toast.error("An error occurred")
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteLens = async () => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to delete this lens?",
      )

      if (!confirm) return

      setLoading(true)

      await deleteLensesByLensId({
        lensId: lens.id,
      }).unwrap()

      await resetFilters()
    } catch (error) {
      toast.error("An error occurred")
    } finally {
      setLoading(false)
    }
  }

  const handleEditLense = async () => {
    try {
      setLoading(true)

      await postLensesByLensId({
        lensId: lens.id,
        updateLens: { name: lensTitle },
      }).unwrap()

      await resetFilters()
    } catch (error) {
      toast.error("An error occurred")
    } finally {
      setLoading(false)

      setEditMode(false)
    }
  }

  const handleSetEditMode = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()

    setEditMode(!editMode)
    setLensTitle(lens.name)
  }

  useClickAway(() => {
    if (editMode) {
      setEditMode(false)
      setLensTitle(lens.name)
    }
  }, ref)

  return (
    <Paper
      ref={ref}
      className={clsx(loading && "animate-pulse")}
      elevation={0}
      sx={{
        p: 2,
        width: "100%",
        ...(temporary && {
          opacity: 0.5,
          pointerEvents: "none",
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!editMode && (
              <Typography
                fontFamily="Hanken Grotesk"
                fontWeight="bold"
                color={isDraftLens ? "text.secondary" : "primary"}
              >
                {nameLens(lens.name)}
              </Typography>
            )}

            {isCurrentLens && !editMode && (
              <Chip
                sx={{ fontSize: "0.7rem", fontWeight: "bold", ml: 1.5 }}
                size="small"
                color="primary"
                label="Current"
              />
            )}
          </Box>

          <Box sx={{ display: "flex", justifyItems: "center" }}>
            {editMode ? (
              <>
                <IconButton
                  color="primary"
                  onClick={handleSetEditMode}
                  sx={{ alignSelf: "flex-center" }}
                >
                  <ArrowBack style={{ fontSize: "1.2rem" }} />
                </IconButton>

                <IconButton
                  color="primary"
                  onClick={handleEditLense}
                  sx={{ alignSelf: "flex-center" }}
                >
                  <Check style={{ fontSize: "1.2rem" }} />
                </IconButton>
              </>
            ) : (
              <>
                <Tooltip
                  title={
                    !isPrivate
                      ? "Make this lens private"
                      : "Make this lens public"
                  }
                >
                  <div>
                    <IconButton
                      color="primary"
                      onClick={isPrivate ? handlePromoteLens : handleDemoteLens}
                      sx={{ alignSelf: "flex-center" }}
                      disabled={isDefaultLens || isDraftLens}
                    >
                      {!isPrivate ? (
                        <South style={{ fontSize: "1.2rem" }} />
                      ) : (
                        <North style={{ fontSize: "1.2rem" }} />
                      )}
                    </IconButton>
                  </div>
                </Tooltip>

                <Tooltip title="Edit lens title">
                  <div>
                    <IconButton
                      color="primary"
                      onClick={handleSetEditMode}
                      sx={{ alignSelf: "flex-center" }}
                      disabled={isDefaultLens}
                    >
                      <Edit style={{ fontSize: "1.2rem" }} />
                    </IconButton>
                  </div>
                </Tooltip>

                <Tooltip title="Delete this lens">
                  <div>
                    <IconButton
                      color="primary"
                      onClick={handleDeleteLens}
                      sx={{ alignSelf: "flex-center" }}
                      disabled={isDefaultLens || isCurrentLens}
                    >
                      <Delete style={{ fontSize: "1.2rem" }} />
                    </IconButton>
                  </div>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>

        {editMode ? (
          <Box
            sx={{
              width: "100%",
              flexGrow: 1,
              display: "flex",
              mt: "-50px",
              gap: 1,
            }}
          >
            <TextField
              size="small"
              label="Title"
              defaultValue={lensTitle}
              value={lensTitle}
              onChange={(e) => setLensTitle(e.target.value)}
              onKeyDown={async (e) => {
                if (e.key === "Enter" && lensTitle.length)
                  await handleEditLense()
              }}
              sx={{ width: "70%" }}
            />
          </Box>
        ) : (
          <>
            {lens.description && (
              <Typography color="text.secondary" variant="body2">
                {lens.description}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Paper>
  )
}
