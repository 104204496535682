import { Visibility, VisibilityOff } from "@mui/icons-material"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import React, { useState } from "react"

interface LbPasswordFieldProps {
  error?: boolean
  helperText?: string
  label?: string
  placeholder?: string
  fullWidth?: boolean
  required?: boolean
}

export const LbPasswordField = React.forwardRef<
  HTMLInputElement,
  LbPasswordFieldProps
>(({ error, helperText, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      {...props}
      ref={ref}
      type={showPassword ? "text" : "password"}
      fullWidth
      error={!!error}
      helperText={helperText}
      sx={{
        ".MuiFormHelperText-root": {
          position: "absolute",
          top: "105%",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <Visibility sx={{ fontSize: 17 }} />
              ) : (
                <VisibilityOff sx={{ fontSize: 17 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
        ...props,
      }}
    />
  )
})
