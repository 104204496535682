import { useGetImportsByImportIdQuery } from "@api/leadbayApi"
import { LbIaLoader } from "@components/feedback/LbIaLoader/LbIaLoader"
import { CENTERED_FLEX_COL } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import Box from "@mui/material/Box"
import { selectOnboardingState } from "@redux/onboardingSlice"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

interface StepResultsProps {
  onClose: () => void
}

export const StepResults = ({ onClose }: StepResultsProps) => {
  const { importId } = useAppSelector(selectOnboardingState)

  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    2000,
  )

  const { data: crmStatusData } = useGetImportsByImportIdQuery(
    { importId: importId! },
    {
      pollingInterval,
      skip: !importId,
    },
  )

  useEffect(() => {
    if (crmStatusData?.processing?.finished === true) {
      setPollingInterval(undefined)

      onClose()

      toast.success("Import finished successfully")
    }
  }, [crmStatusData?.processing?.finished])

  return (
    <Box sx={{ ...CENTERED_FLEX_COL, mb: 12, height: "600px" }}>
      <LbIaLoader progress={crmStatusData?.processing?.progress} />
    </Box>
  )
}
