import { selectAuthState } from "@redux/authSlice"
import { selectCommonsState, WishlistViewMode } from "@redux/commonsSlice"
import { useCallback } from "react"
import { toast } from "react-toastify"
import { useAppSelector } from "./useAppSelector"

interface IExportLeads {
  onSuccess: () => void
  onError: (error: Error) => void
  onFinished?: () => void
}

interface HandleExportLeads {
  selectedTarget: string
  selectedLeads: string[]
  wishlistViewMode: WishlistViewMode
}

interface HandleExportAllLeads {
  wishlistViewMode: WishlistViewMode
  selectedTarget?: string
}

interface HandleExportTimelineBlock {
  blockId: string
  leadIds: string[]
}

export const useExportLeads = ({
  onSuccess,
  onError,
  onFinished,
}: IExportLeads) => {
  const { token } = useAppSelector(selectAuthState)
  const { currentLensId } = useAppSelector(selectCommonsState)

  const fetchAndHandleResponse = async (
    url: string,
    payload: Record<string, string | string[]> | undefined | string[],
  ): Promise<string> => {
    const response = await fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      }),
      body: payload ? JSON.stringify(payload) : null,
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    if (response.headers.get("Content-Type") === "application/json") {
      const data = (await response.json()) as { error: string }
      if (data?.error) {
        throw new Error(data?.error)
      }
    }

    return await response.text()
  }

  const downloadCSV = (csvText: string, prefix: string = "leads_") => {
    const blob = new Blob([csvText], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    const date = new Date()
    const timestamp = date.getTime()
    const filename = `${prefix}${timestamp}.csv`
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const handleExportLeads = useCallback(
    async ({
      selectedTarget,
      selectedLeads,
      wishlistViewMode,
    }: HandleExportLeads) => {
      if (selectedLeads.length >= 1000) {
        toast.error("You can only export up to 1000 leads at a time.")

        return
      }

      try {
        const mode = wishlistViewMode === "monitor" ? "monitor" : "wishlist"

        const payload =
          wishlistViewMode === "monitor"
            ? selectedLeads
            : {
                target_id: selectedTarget,
                lead_ids: selectedLeads,
              }

        const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/lenses/${currentLensId}/export/${mode}`
        const csvText = await fetchAndHandleResponse(url, payload)

        if (selectedTarget === "f") {
          downloadCSV(csvText)
        }

        onSuccess()
      } catch (error) {
        const err = error as Error
        console.error(err)
        onError(err)
      } finally {
        onFinished?.()
      }
    },
    [token, currentLensId, onSuccess, onError, onFinished],
  )

  const handleExportAllLeads = useCallback(
    async ({
      wishlistViewMode,
      selectedTarget = "f",
    }: HandleExportAllLeads) => {
      try {
        const confirm = window.confirm(
          "Are you sure you want to export all leads?",
        )

        if (!confirm) {
          return
        }

        const payload =
          wishlistViewMode === "monitor"
            ? undefined
            : { target_id: selectedTarget }

        const mode = wishlistViewMode === "monitor" ? "monitor" : "wishlist"
        const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/lenses/${currentLensId}/export/${mode}/all`
        const csvText = await fetchAndHandleResponse(url, payload)

        downloadCSV(csvText)

        onSuccess()
      } catch (error) {
        const err = error as Error
        console.error(err)
        onError(err)
      } finally {
        onFinished?.()
      }
    },
    [token, currentLensId, onSuccess, onError, onFinished],
  )

  const handleExportTimelineBlock = useCallback(
    async ({ blockId, leadIds }: HandleExportTimelineBlock) => {
      try {
        const payload = {
          target_id: "f",
          lead_ids: leadIds,
        }

        const url = `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/timeline/blocks/${blockId}/export_leads`
        const csvText = await fetchAndHandleResponse(url, payload)

        downloadCSV(csvText)

        onSuccess()
      } catch (error) {
        const err = error as Error
        console.error(err)
        onError(err)
      } finally {
        onFinished?.()
      }
    },
    [token, onSuccess, onError, onFinished],
  )

  return { handleExportLeads, handleExportAllLeads, handleExportTimelineBlock }
}
