import { useGetImportsByImportIdQuery } from "@api/leadbayApi"
import { LbQuery } from "@components/feedback/LbQuery/LbQuery"
import { MAPPING_STEP } from "@constants/index"
import { useAppSelector } from "@hooks/useAppSelector"
import Box from "@mui/material/Box"
import { selectAuthState } from "@redux/authSlice"
import { useAsyncEffect } from "ahooks"
import localforage from "localforage"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import {
  selectOnboardingState,
  setApiStep,
  setCurrentStep,
  setMappingStep,
} from "redux/onboardingSlice"
import { OnboardingScreenSkeletons } from "./OnboardingScreen.skeletons"
import { Icp } from "./partials/Icp/Icp"
import { OrganizationInfo } from "./partials/OrganizationInfo/OrganizationInfo"
import { StepMapping } from "./partials/StepMapping/StepMapping"
import { StepResults } from "./partials/StepResults/StepResults"
import { StepUpload } from "./partials/StepUpload/StepUpload"

const steps = {
  0: <OrganizationInfo />,
  1: <StepUpload />,
  2: <StepMapping />,
  3: <Icp />,
  4: <StepResults />,
}

const stepDict = {
  ORGANIZATION_INFO: 0,
  UPLOAD: 1,
  MAPPING: 2,
  ICP: 4,
  RESULT: 4,
}

const OnboardingScreen = () => {
  const dispatch = useDispatch()

  const { importId } = useAppSelector(selectOnboardingState)
  const { user } = useAppSelector(selectAuthState)

  const {
    data: crmStatus,
    isError,
    isLoading,
  } = useGetImportsByImportIdQuery(
    {
      importId: importId!,
    },
    {
      skip: !importId,
    },
  )

  const { currentStep } = useAppSelector(selectOnboardingState)

  useAsyncEffect(async () => {
    const currentApiStep = user?.organization.onboarding_step

    if (!crmStatus && currentApiStep === "ORGANIZATION_INFO") {
      dispatch(setCurrentStep(stepDict.ORGANIZATION_INFO))

      return
    }

    if (crmStatus?.pre_processing?.error || crmStatus?.processing?.error) {
      dispatch(setCurrentStep(stepDict.UPLOAD))

      toast.error("An error occurred, please try to re upload your file.")

      return
    }

    if (currentStep) {
      dispatch(setCurrentStep(currentStep))

      return
    }

    if (
      typeof crmStatus?.processing?.progress !== "undefined" &&
      crmStatus?.processing?.progress < 1 &&
      currentApiStep === "CRM"
    ) {
      dispatch(setCurrentStep(stepDict.MAPPING))

      return
    }

    switch (currentApiStep) {
      case "ORGANIZATION_INFO":
        dispatch(setCurrentStep(stepDict.ORGANIZATION_INFO))
        dispatch(setApiStep(stepDict.ORGANIZATION_INFO))
        break
      case "CRM":
        if (!crmStatus?.file_name) {
          dispatch(setCurrentStep(stepDict.MAPPING))
          dispatch(setApiStep(stepDict.MAPPING))
        } else {
          dispatch(setCurrentStep(stepDict.UPLOAD))
          dispatch(setApiStep(stepDict.MAPPING))
        }
        break
      case "ICP":
        dispatch(setCurrentStep(stepDict.MAPPING))
        break
      case "FINISHED":
        dispatch(setCurrentStep(stepDict.RESULT))
        break
      default:
        console.error("Unknown step")
        break
    }
  }, [crmStatus])

  useAsyncEffect(async () => {
    if (!crmStatus) return

    if (!crmStatus?.mappings?.fields) {
      await localforage.removeItem(MAPPING_STEP)

      dispatch(setMappingStep(0))
    } else {
      const mappingStep = await localforage.getItem<number>(MAPPING_STEP)

      if (mappingStep) dispatch(setMappingStep(mappingStep))
    }
  }, [crmStatus])

  return (
    <Box
      component="article"
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
      }}
    >
      <LbQuery
        isError={isError}
        isLoading={isLoading && !user}
        skeleton={<OnboardingScreenSkeletons />}
        content={steps[currentStep as keyof typeof steps]}
      />
    </Box>
  )
}

export default OnboardingScreen
