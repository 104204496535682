import { type FilterItem } from "@api/leadbayApi"
import { CENTERED_FLEX_COL } from "@constants/index"
import { Box, Chip, Paper, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { getFilterCriterionData, upperCaseFirstLetter } from "utils/globals"

export interface ChipData {
  key: number
  label: string
  active: boolean
}

interface LbIcpCardProps {
  criterion: FilterItem
  onChange?: (criteriaStatus: Record<string, boolean>) => void
}

export const LbIcpCard = (props: LbIcpCardProps) => {
  const { criterion, onChange } = props

  const initialState: Record<string, boolean> = {}

  criterion.criteria.forEach((crit) => {
    // @ts-ignore:next-line
    getFilterCriterionData(crit).forEach((data) => {
      initialState[data.label] = true
    })
  })

  const [chipsState, setChipsState] = useState(initialState)

  const handleChipClick = useCallback(
    (label: string) => {
      const newState = {
        ...chipsState,
        [label]: !chipsState[label],
      }
      setChipsState(newState)

      onChange?.(newState)
    },
    [chipsState, onChange],
  )

  return (
    <Paper
      elevation={0}
      sx={{
        ...CENTERED_FLEX_COL,
        gap: 3,
        flex: 1,
        minWidth: "700px",
        py: 3,
        px: 2,
      }}
    >
      {criterion.criteria.map((criterion, index) => (
        <Box key={criterion.type + index} sx={{ width: "100%" }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {upperCaseFirstLetter(criterion.type)}
          </Typography>

          <Box sx={{ width: "100%", mt: 2 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 2,
              }}
              className="hide-scrollbar"
            >
              {getFilterCriterionData(criterion)?.map((data) => (
                <Chip
                  key={data.label}
                  label={data.label}
                  onClick={() => handleChipClick(data.label)}
                  color={chipsState[data.label] ? "primary" : "default"}
                />
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Paper>
  )
}
