import { CENTERED_FLEX_COL } from "@constants/index"
import { Box, Skeleton } from "@mui/material"

export const OnboardingScreenSkeletons = () => {
  return (
    <Box sx={{ ...CENTERED_FLEX_COL, gap: 2 }}>
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: "20px" }}
        width={700}
        height={100}
      />
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: "20px" }}
        width={700}
        height={100}
      />
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: "20px" }}
        width={700}
        height={100}
      />
      <Skeleton
        variant="rectangular"
        sx={{ borderRadius: "20px" }}
        width={700}
        height={100}
      />
    </Box>
  )
}
