import { LbButton } from "@components/feedback/LbButton/LbButton"
import { Box, Typography } from "@mui/material"

interface LbToastConfirmProps {
  message: string
  buttonLabel: string
}

export const LbToastConfirm = ({
  message,
  buttonLabel,
}: LbToastConfirmProps) => {
  return (
    <Box>
      <Typography color="primary" variant="body2" fontWeight="bold">
        {message}
      </Typography>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <LbButton size="small">{buttonLabel}</LbButton>
      </Box>
    </Box>
  )
}
