import {
  useDeleteOrganizationsByOrgIdInvitesAndInviteIdMutation,
  useDeleteOrganizationsByOrgIdUsersAndUserIdMutation,
  useGetOrganizationsByOrgIdInvitesQuery,
  useGetOrganizationsByOrgIdUsersQuery,
  useGetStripePortalQuery,
  useGetUsersMeQuery,
  usePostOrganizationsByOrgIdInvitesAndInviteIdMutation,
  usePostOrganizationsByOrgIdInvitesMutation,
  usePostOrganizationsByOrgIdUsersAndUserIdMutation,
  type UserWithOrg,
} from "@api/leadbayApi"
import { LbButton } from "@components/feedback/LbButton/LbButton"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppSelector } from "@hooks/useAppSelector"
import { Add, Check, Close, FlashOn, MoreVert } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material"
import { DataGrid, useGridApiRef, type GridColDef } from "@mui/x-data-grid"
import { selectAuthState } from "@redux/authSlice"
import { useAsyncEffect } from "ahooks"

import React, { useEffect, useState } from "react"
import { Controller, useForm, type SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { bool, object, string } from "yup"

interface RowMenuProps {
  row: UserWithOrg
  isInvited: boolean
}

const RowMenu = (props: RowMenuProps) => {
  const { row, isInvited } = props

  const { user } = useAppSelector(selectAuthState)

  const { refetch: refetchUsers } = useGetOrganizationsByOrgIdUsersQuery(
    {
      orgId: user?.organization.id as string,
    },
    {
      skip: !user?.organization.id,
    },
  )

  const { refetch: inviteRefetch } = useGetOrganizationsByOrgIdInvitesQuery(
    {
      orgId: user?.organization.id as string,
    },
    {
      skip: !user?.organization.id,
    },
  )

  const [deleteUser, { isLoading: deleteUserIsLoading }] =
    useDeleteOrganizationsByOrgIdUsersAndUserIdMutation()

  const [deletInviteUser, { isLoading: deleteUserInviteIsLoading }] =
    useDeleteOrganizationsByOrgIdInvitesAndInviteIdMutation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handledDeleteUser = async () => {
    try {
      const confirm = window.confirm(
        `Are you sure you want to delete ${row.name} from your organization ?`,
      )

      if (!confirm) {
        handleClose()

        return
      }

      if (!user?.organization.id) throw new Error("Organization id is required")

      const response = isInvited
        ? await deletInviteUser({
            orgId: user.organization.id,
            inviteId: row.id,
          })
        : await deleteUser({
            orgId: user.organization.id,
            userId: row.id,
          })

      // TODO: Remove ts-expect-error

      // @ts-ignore:next-line
      if (response?.error?.data?.error?.code === "last_admin")
        throw new Error("You can't delete the last admin of your organization")

      // @ts-ignore:next-line
      if (response?.error?.data?.error)
        throw new Error("Something went wrong, please try again later")

      await refetchUsers()
      await inviteRefetch()

      toast("User deleted successfully")
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    } finally {
      handleClose()
    }
  }

  const [updateRole, { isLoading: updateRoleIsLoading }] =
    usePostOrganizationsByOrgIdUsersAndUserIdMutation()

  const [updateInviteRole, { isLoading: updateRoleInviteIsLoading }] =
    usePostOrganizationsByOrgIdInvitesAndInviteIdMutation()

  const handleUpdateRole = async () => {
    try {
      if (!user?.organization.id) throw new Error("Organization id is required")

      const response = isInvited
        ? await updateInviteRole({
            orgId: user.organization.id,
            inviteId: row.id,
            organizationsOrgIdInvitesInviteIdPostRequest: {
              admin: !row.admin,
            },
          })
        : await updateRole({
            orgId: user.organization.id,
            userId: row.id,
            organizationsOrgIdInvitesInviteIdPostRequest: {
              admin: !row.admin,
            },
          })

      // @ts-ignore:next-line
      if (response?.error?.data?.error.code === "last_admin")
        throw new Error(
          "You can't downgrade the last admin of your organization",
        )

      // @ts-ignore:next-line
      if (response?.error?.data?.error)
        throw new Error("Something went wrong, please try again later")

      await refetchUsers()
      await inviteRefetch()

      toast("Role updated successfully")
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    } finally {
      handleClose()
    }
  }

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      {isInvited ? (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem dense onClick={handleUpdateRole}>
            {updateRoleIsLoading && updateRoleInviteIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography variant="subtitle2">
                {row.admin ? "Downgrade to user role" : "Upgrade to admin role"}
              </Typography>
            )}
          </MenuItem>

          <MenuItem onClick={handledDeleteUser} color="error" dense>
            {deleteUserIsLoading && deleteUserInviteIsLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Typography variant="subtitle2" color="error">
                Cancel member invitation
              </Typography>
            )}
          </MenuItem>
        </Menu>
      ) : (
        <>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem dense onClick={handleUpdateRole}>
              {updateRoleIsLoading && updateRoleInviteIsLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Typography variant="subtitle2">
                  {row.admin
                    ? "Downgrade to user role"
                    : "Upgrade to admin role"}
                </Typography>
              )}
            </MenuItem>

            <MenuItem onClick={handledDeleteUser} color="error" dense>
              {deleteUserIsLoading && deleteUserInviteIsLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Typography variant="subtitle2" color="error">
                  Delete user
                </Typography>
              )}
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  )
}

const columns: GridColDef[] = [
  { field: "name", headerName: "Name", hideable: false },
  { field: "email", headerName: "Email", hideable: false, flex: 1 },
  {
    field: "verified",
    headerName: "Verified",
    hideable: false,

    renderCell({ row }) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {row.verified === true ? (
            <Check sx={{ fontSize: 20 }} />
          ) : (
            <Close sx={{ fontSize: 20 }} />
          )}
        </Box>
      )
    },
  },
  {
    field: "admin",
    headerName: "Role",
    hideable: false,

    renderCell({ row }) {
      return <>{row.admin ? "Admin" : "Member"}</>
    },
  },
  {
    field: "actions",
    headerName: "",
    disableColumnMenu: true,
    disableExport: true,
    disableReorder: true,
    hideSortIcons: true,
    hideable: false,

    renderCell({ row }) {
      return <RowMenu row={row} isInvited={"used" in row} />
    },
  },
]

const validationSchema = object().shape({
  email: string()
    .required()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Please enter a valid email address",
    ),
  admin: bool().required("Role is required"),
})

interface FormVlaues {
  email: string
  admin: boolean
}

interface MembersProps {
  user: UserWithOrg
}

export const Members = (props: MembersProps) => {
  const { user } = props

  const apiRef = useGridApiRef()

  const autosizeOptions = {
    columns: ["name"],
    includeHeaders: true,
    includeOutliers: true,
  }

  const [getPortalUrl, setGetPortalUrl] = useState<boolean | undefined>(
    undefined,
  )

  const { refetch: refetchUser } = useGetUsersMeQuery()

  const { data: billingUrl } = useGetStripePortalQuery(undefined, {
    skip: !getPortalUrl,
  })

  const { data: invitedUsers, refetch: inviteRefetch } =
    useGetOrganizationsByOrgIdInvitesQuery(
      {
        orgId: user?.organization.id,
      },
      {
        skip: !user?.organization.id,
      },
    )

  const { data: users } = useGetOrganizationsByOrgIdUsersQuery(
    {
      orgId: user?.organization.id,
    },
    {
      skip: !user?.organization.id,
    },
  )

  const invitedMembersLength = users?.length ?? 0

  const [inviteUser, { isLoading }] =
    usePostOrganizationsByOrgIdInvitesMutation()

  const [showInviteForm, setSowInviteForm] = useState(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<FormVlaues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      admin: false,
    },
  })

  const handleShowInviteForm = () => {
    if (user?.organization?.billing.seats - invitedMembersLength <= 0)
      setGetPortalUrl(true)
    else setSowInviteForm(!showInviteForm)
  }

  useEffect(() => {
    if (billingUrl && getPortalUrl) {
      window.location.href = billingUrl.url

      setGetPortalUrl(false)
    }
  }, [billingUrl])

  const handleFormSubmit: SubmitHandler<FormVlaues> = async (
    inviteCreation,
  ) => {
    try {
      if (user?.organization?.billing.seats - invitedMembersLength <= 0)
        throw new Error(
          "You have reached the maximum number of seats for your plan",
        )

      const response = await inviteUser({
        orgId: user.organization?.id ?? "",
        inviteCreation,
      })

      // @ts-ignore:next-line
      if (response?.error?.data?.error)
        throw new Error("Something went wrong, please try again later")

      await inviteRefetch()

      await refetchUser()
    } catch (error) {
      const err = error as Error

      toast.error(err.message)
    } finally {
      handleShowInviteForm()

      reset()
    }
  }

  useAsyncEffect(async () => {
    if (apiRef?.current.autosizeColumns)
      setTimeout(async () => {
        await apiRef?.current?.autosizeColumns(autosizeOptions)
      }, 100)
  }, [])

  const membersList = [...(users ?? []), ...(invitedUsers ?? [])]

  return (
    <>
      <Divider sx={{ mb: 5, mt: 6 }} />

      <Box component="section" sx={{ maxWidth: "600px" }}>
        <Box
          component="header"
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Typography variant="h6" fontFamily="Hanken Grotesk">
              Team
            </Typography>

            {user?.organization?.billing.seats > 0 && (
              <Box sx={{ background: "primary" }}>
                <Typography color="text.secondary">
                  {`${user?.organization?.billing.seats} seat${user?.organization?.billing.seats !== 1 ? "s" : ""} available`}
                </Typography>
              </Box>
            )}

            <Typography sx={{ mt: 2 }}>
              Manage team members and invite new people to join your team.
            </Typography>
          </Box>

          <LbButton
            onClick={handleShowInviteForm}
            sx={{ textTransform: "none" }}
            size="small"
            startIcon={
              user?.organization?.billing.seats - invitedMembersLength <= 0 ? (
                <FlashOn />
              ) : (
                <Add />
              )
            }
          >
            {user?.organization?.billing.seats - invitedMembersLength <= 0 ? (
              <>Upgrade my plan to add more seats</>
            ) : (
              <>Add</>
            )}
          </LbButton>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            {membersList?.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ px: 1 }}
                  color="text.secondary"
                >
                  No members yet
                </Typography>
              </Box>
            ) : (
              <DataGrid
                apiRef={apiRef}
                sx={{ "&, [class^=MuiDataGrid]": { border: "none" } }}
                rows={membersList ?? []}
                columns={columns}
                hideFooter={true}
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={showInviteForm}
        onClose={handleShowInviteForm}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(255,255,255,0.8)",
            },
          },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 600,
            backgroundColor: "white",
            p: 4,
            borderRadius: 1,
            border: "1px solid rgba(0, 0, 0, 0.08)",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="h2" sx={{ lineHeight: 1 }}>
                  Invite a new member
                </Typography>

                <IconButton size="small" onClick={handleShowInviteForm}>
                  <Close />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label="Email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      fullWidth
                      required
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="admin"
                  render={({ field }) => (
                    <RadioGroup row {...field} defaultValue={false}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Admin"
                      />

                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="User"
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <LbButton
                variant="contained"
                color="primary"
                size="large"
                disabled={!isValid || isLoading}
                type="submit"
                loading={isLoading}
              >
                Save
              </LbButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
