import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Add } from "@mui/icons-material"
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import { selectCommonsState, setWishListLoading } from "@redux/commonsSlice"
import { useAsyncEffect, useDebounceFn } from "ahooks"

import {
  useGetGeoSearchQuery,
  useGetLensesByLensIdLeadsQuery,
  useGetLensesByLensIdLeadsWishlistQuery,
  useGetLensesByLensIdScoringQuery,
  useGetSectorsAllQuery,
  usePostLensesByLensIdFilterMutation,
  type FilterItem,
} from "@api/leadbayApi"
import { CENTERED_FLEX_COL } from "@constants/index"
import { useCheckLense } from "@hooks/useCheckLense"
import { analytics } from "@utils/analytics"
import {
  generateLocationString,
  parseLocationString,
} from "@utils/generateWishListRows"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import {
  type AutoCompleteOption,
  type ScoringType,
} from "../LbScoringParameters/LbScoringCrud"

const fixedOptions = [
  {
    label: `Paris, Île-de-France, FR`,
    value: `Paris, Île-de-France, FR`,
  },
  {
    label: `Lyon, Auvergne-Rhône-Alpes, FR`,
    value: `Lyon, Auvergne-Rhône-Alpes, FR`,
  },
  {
    label: `Marseille, Provence-Alpes-Côte d'Azur, FR`,
    value: `Marseille, Provence-Alpes-Côte d'Azur, FR`,
  },
  {
    label: `Toulouse, Occitanie, FR`,
    value: `Toulouse, Occitanie, FR`,
  },

  {
    label: `Nice, Provence-Alpes-Côte d'Azur, FR`,
    value: `Nice, Provence-Alpes-Côte d'Azur, FR`,
  },
  {
    label: `Nantes, Pays de la Loire, FR`,
    value: `Nantes, Pays de la Loire, FR`,
  },
  {
    label: `Bordeaux, Nouvelle-Aquitaine, FR`,
    value: `Bordeaux, Nouvelle-Aquitaine, FR`,
  },
  {
    label: `Biarritz, Nouvelle-Aquitaine, FR`,
    value: `Biarritz, Nouvelle-Aquitaine, FR`,
  },
  {
    label: `Montpellier, Occitanie, FR`,
    value: `Montpellier, Occitanie, FR`,
  },
]

interface LbWishlistCrudProps {
  field:
    | { type: Exclude<ScoringType, "custom_field"> }
    | { type: "custom_field"; field: string }
  icp: FilterItem
  setShowInput: (value: boolean) => void
}

export const LbWishlistCrud = (props: LbWishlistCrudProps) => {
  const { field, icp, setShowInput } = props

  const dispatch = useAppDispatch()

  const {
    paginationModel,
    pageSize,
    currentLensId,
    wishlistViewMode,
    currentFilter,
    sortModel,
  } = useAppSelector(selectCommonsState)

  const { checkIfDraftLensNeeded } = useCheckLense()

  const { refetch: refetchICP } = useGetLensesByLensIdScoringQuery(
    {
      lensId: currentLensId,
    },
    {
      skip: !currentLensId,
    },
  )

  const [removedScoringParameter, setRemovedScoringParameter] = useState<
    string[]
  >([])
  const [sectors, setSectors] = useState<string[]>([])
  const [localSizes, setLocalSizes] = useState<
    Array<{ min: number; max: number }>
  >([])
  const [minSize, setMinSize] = useState("")
  const [maxSize, setMaxSize] = useState("")
  const [localKeyword, setLocalKeyword] = useState("")
  const [keywords, setKeywords] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<
    AutoCompleteOption[]
  >([])
  const [inputGeoSearch, setInputGeoSearch] = useState<string>("")
  const [localGeoSearch, setLocalGeoSearch] = useState<string>("")
  const [localCustomField, setLocalCustomField] = useState<string>("")
  const [customFields, setCustomFields] = useState<string[]>([])

  const { data: allSector } = useGetSectorsAllQuery()

  const { refetch: refetchWishlist } = useGetLensesByLensIdLeadsWishlistQuery(
    {
      lensId: currentLensId,
      count: pageSize,
      page: paginationModel.page,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
    },
  )

  const { refetch: refetchLeads } = useGetLensesByLensIdLeadsQuery(
    {
      lensId: currentLensId,
      page: paginationModel.page,
      count: pageSize,
      wished: false,
      q: currentFilter,
      order: sortModel,
    },
    {
      skip: !currentLensId,
    },
  )

  const { data: geoSearchResult, isFetching: geoSearchFetching } =
    useGetGeoSearchQuery(
      {
        q: localGeoSearch,
      },
      {
        skip: localGeoSearch === "",
        refetchOnMountOrArgChange: true,
      },
    )

  const [postOrganizationsByOrgIdIcp, { isLoading }] =
    usePostLensesByLensIdFilterMutation()

  const handleSetSectors = (value: Array<{ label: string; value: string }>) => {
    setSectors([...value.map((v) => v.value)])
  }

  useEffect(() => {
    if (!minSize || !maxSize) {
      setLocalSizes([])
      return
    }

    setLocalSizes([{ min: Number(minSize), max: Number(maxSize) }])
  }, [minSize, maxSize])

  const handleAddKeyword = () => {
    setKeywords([...keywords, localKeyword])
    setLocalKeyword("")
  }

  const handleAddCustomField = () => {
    setCustomFields([...customFields, localCustomField])
    setLocalCustomField("")
  }

  useAsyncEffect(async () => {
    if (keywords.length > 0) {
      await handleCreateWishlistCriteria()
    }

    if (sectors.length > 0) {
      await handleCreateWishlistCriteria()
    }

    if (selectedLocations.length > 0) {
      await handleCreateWishlistCriteria()
    }

    if (customFields.length > 0) {
      await handleCreateWishlistCriteria()
    }
  }, [keywords, sectors, selectedLocations, customFields])

  const handleCreateWishlistCriteria = async () => {
    try {
      const lensId = await checkIfDraftLensNeeded()

      dispatch(setWishListLoading(true))

      const savedIcp = icp?.criteria ?? []

      const savedSizes = savedIcp.find(
        (criterion) => criterion.type === "size",
      ) ?? {
        type: "size",
        sizes: [],
      }

      const savedKeywords = savedIcp.find(
        (criterion) => criterion.type === "keywords",
      ) ?? {
        type: "keywords",
        keywords: [],
      }

      const savedSectors = savedIcp.find(
        (criterion) => criterion.type === "sectors",
      ) ?? {
        type: "sectors",
        sectors: [],
      }

      const savedLocation = savedIcp.find(
        (criterion) => criterion.type === "location",
      ) ?? {
        type: "location",
        locations: [],
      }

      const savedCustomFields =
        savedIcp.filter((criterion) => criterion.type === "custom_field") ?? []

      await postOrganizationsByOrgIdIcp({
        lensId,
        filter: {
          items: [
            {
              // @ts-ignore:next-line
              criteria: [
                // @ts-ignore:next-line
                ...(savedSizes?.sizes?.length || localSizes.length
                  ? [
                      {
                        type: "size",
                        sizes: [
                          // @ts-ignore:next-line
                          ...savedSizes.sizes,
                          ...localSizes,
                        ],
                      },
                    ]
                  : []),
                // @ts-ignore:next-line
                ...(savedKeywords?.keywords?.length || keywords.length
                  ? [
                      {
                        type: "keywords",
                        // @ts-ignore:next-line
                        keywords: [...savedKeywords.keywords, ...keywords],
                      },
                    ]
                  : []),
                // @ts-ignore:next-line
                ...(savedSectors?.sectors?.length || sectors.length
                  ? [
                      {
                        type: "sectors",
                        // @ts-ignore:next-line
                        sectors: [...savedSectors.sectors, ...sectors],
                      },
                    ]
                  : []),
                // @ts-ignore:next-line
                ...(savedLocation?.locations?.length || selectedLocations.length
                  ? [
                      {
                        type: "location",
                        locations: [
                          // @ts-ignore:next-line
                          ...savedLocation.locations,
                          ...selectedLocations.map((location) =>
                            parseLocationString(location.label),
                          ),
                        ],
                      },
                    ]
                  : []),
                ...(field.type === "custom_field"
                  ? customFields.map((value) => ({
                      type: "custom_field",
                      field: field.field,
                      values: [value],
                    }))
                  : []),
                ...savedCustomFields,
              ],
            },
          ],
        },
      }).unwrap()

      await refetchICP()

      if (wishlistViewMode === "discover") await refetchLeads()
      else await refetchWishlist()

      setShowInput(false)
    } catch (error) {
      setShowInput(false)

      console.error(error)

      toast.error("Error updating wishlist criteria")
    } finally {
      setMaxSize("")
      setMinSize("")

      dispatch(setWishListLoading(false))
    }
  }

  const allSectorsData = useMemo(() => {
    const savedSectors = icp.criteria.find(
      (criterion) => criterion.type === "sectors",
    )

    if (!savedSectors && allSector)
      return allSector.map((sector) => ({
        label: sector,
        value: sector,
      }))
    else if (savedSectors && allSector)
      return (
        allSector
          // @ts-ignore:next-line
          .filter((sector) => !savedSectors.sectors.includes(sector))
          .map((sector) => ({
            label: sector,
            value: sector,
          }))
      )
    else return []
  }, [allSector, icp.criteria])

  const locationOptions = useMemo(() => {
    if (geoSearchResult?.length) {
      return geoSearchResult.map(({ city, state, country }) => {
        const locationString = generateLocationString({ city, state, country })
        return { label: locationString, value: locationString }
      })
    }
    return [...fixedOptions]
  }, [geoSearchResult])

  const { run } = useDebounceFn(
    () => {
      setLocalGeoSearch(inputGeoSearch)
    },
    {
      wait: 500,
    },
  )

  useEffect(() => {
    if (inputGeoSearch === "") return
    run()
  }, [inputGeoSearch])

  useEffect(() => {
    // if all sectors or all status are selected, remove the corresponding scoring parameter
    if (allSectorsData.length === 0)
      setRemovedScoringParameter([...removedScoringParameter, "sectors"])
  }, [allSectorsData])

  const showErrorSize =
    minSize !== "" && maxSize !== "" && Number(minSize) > Number(maxSize)

  return (
    <Box sx={{ flex: 1, mb: 2.5 }}>
      {field.type === "size" ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <TextField
              type="number"
              size="small"
              label="Min employees"
              value={minSize}
              onChange={(e) => {
                analytics.wishlistCriteriaUpdated({ criteriaName: "size" })
                setMinSize(e.target.value)
              }}
            />

            <TextField
              error={showErrorSize}
              type="number"
              size="small"
              label="Max employees"
              value={maxSize}
              onChange={(e) => {
                analytics.wishlistCriteriaUpdated({ criteriaName: "size" })
                setMaxSize(e.target.value)
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter" && minSize !== "" && maxSize !== "")
                  await handleCreateWishlistCriteria()
              }}
            />

            <Box sx={{ ...CENTERED_FLEX_COL }}>
              <IconButton
                color="primary"
                onClick={handleCreateWishlistCriteria}
                size="small"
                disabled={minSize === "" || maxSize === ""}
                sx={{ alignSelf: "flex-center" }}
              >
                {!isLoading ? (
                  <Add style={{ fontSize: "1.2rem" }} />
                ) : (
                  <CircularProgress size={12} />
                )}
              </IconButton>
            </Box>
          </Box>

          {showErrorSize && (
            <Typography color="error" sx={{ mt: 1, fontSize: "0.8rem" }}>
              Min size must be less than max size
            </Typography>
          )}
        </>
      ) : field.type === "keywords" ? (
        <Box sx={{ display: "flex" }}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label={`Add  ${field.type}`}
            value={localKeyword}
            onChange={(e) => {
              analytics.wishlistCriteriaUpdated({ criteriaName: "keywords" })
              setLocalKeyword(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && localKeyword !== "") handleAddKeyword()
            }}
          />

          <Tooltip title="Add">
            <span>
              <IconButton
                color="primary"
                onClick={handleAddKeyword}
                size="small"
                disabled={localKeyword === ""}
              >
                <Add style={{ fontSize: "1.2rem" }} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : field.type === "sectors" ? (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Autocomplete
            fullWidth
            size="small"
            ListboxProps={{
              sx: {
                fontSize: "0.9rem",
              },
            }}
            multiple
            onChange={(_, newValue) => {
              analytics.wishlistCriteriaUpdated({ criteriaName: "sectors" })
              handleSetSectors(newValue)
            }}
            disablePortal
            options={allSectorsData}
            renderInput={(params) => (
              <TextField {...params} fullWidth label={`Add  ${field.type}`} />
            )}
          />
        </Box>
      ) : field.type === "location" ? (
        <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
          <Autocomplete
            fullWidth
            disablePortal
            filterOptions={(options) => options}
            options={locationOptions}
            multiple
            size="small"
            ListboxProps={{
              sx: {
                fontSize: "0.9rem",
              },
            }}
            loading={geoSearchFetching}
            noOptionsText={`No location found for ${inputGeoSearch}`}
            onChange={(_, newValue) => {
              analytics.wishlistCriteriaUpdated({ criteriaName: "location" })
              setSelectedLocations(newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={`Add  ${field.type}`}
                onChange={(e) => {
                  analytics.wishlistCriteriaUpdated({
                    criteriaName: "location",
                  })
                  setInputGeoSearch(e.target.value ?? "")
                }}
              />
            )}
          />
        </Box>
      ) : field.type === "custom_field" ? (
        <Box sx={{ display: "flex", gap: 1 }}>
          <TextField
            fullWidth
            size="small"
            label="Custom field"
            value={localCustomField}
            onChange={(e) => setLocalCustomField(e.target.value)}
          />

          <Tooltip title="Add">
            <span>
              <IconButton
                color="primary"
                onClick={handleAddCustomField}
                size="small"
                disabled={localCustomField === ""}
              >
                <Add style={{ fontSize: "1.2rem" }} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ) : null}
    </Box>
  )
}
