import { useGetLensesByLensIdLeadsOnboardingQuery } from "@api/leadbayApi"
import { LbResultRow } from "@components/display/LbResultRow/LbResultRow"
import { LbIaLoader } from "@components/feedback/LbIaLoader/LbIaLoader"
import { LbQuery } from "@components/feedback/LbQuery/LbQuery"
import {
  CENTERED_FLEX_COL,
  IMPORT_ID,
  MAPPING_STEP,
  ROUTES,
} from "@constants/index"
import { useApiRequestFeedback } from "@hooks/useApiRequestFeedback"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import { Grid, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { selectAuthState } from "@redux/authSlice"
import { selectCommonsState } from "@redux/commonsSlice"
import { setOnboardingComplete } from "@redux/onboardingSlice"
import { OnboardingScreenSkeletons } from "@screens/onboarding/OnboardingScreen/OnboardingScreen.skeletons"
import { useAsyncEffect, useRequest } from "ahooks"
import JSConfetti from "js-confetti"
import localforage from "localforage"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Step } from "../Step/Step"

const jsConfetti = new JSConfetti()

export const StepResults = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { token } = useAppSelector(selectAuthState)

  const { currentLensId } = useAppSelector(selectCommonsState)

  const [resultsIsReady, setResultsIsReady] = useState<boolean>(false)

  const getUser = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_LEADBAY_API_BASE_URL}/users/me`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          }),
        },
      )

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`)
      }

      return await response.json()
    } catch (error) {
      console.error("Error fetching user", error)
    }
  }

  const { data: user } = useRequest(getUser, {
    pollingInterval: resultsIsReady ? 0 : 1000,
    pollingWhenHidden: false,
  })

  const [resultTry, setResultTry] = useState<number>(0)

  const { data, isSuccess, error, isError, isLoading } =
    useGetLensesByLensIdLeadsOnboardingQuery(
      {
        lensId: currentLensId,
      },
      {
        skip:
          !currentLensId ||
          user?.organization?.onboarding_step !== "FINISHED" ||
          resultTry > 3,
        pollingInterval:
          user?.organization?.onboarding_step === "FINISHED" ? 0 : 1000,
      },
    )

  const [loading, setLoading] = useState(true)

  const handleNext = async () => {
    await localforage.removeItem(MAPPING_STEP)
    await localforage.removeItem(IMPORT_ID)

    dispatch(setOnboardingComplete(true))

    navigate(ROUTES.APP)
  }

  useApiRequestFeedback({
    isSuccess,
    error,
    config: {
      statusCodeConfig: {
        400: {
          message: {
            text: "Bad Request",
            type: "error",
          },
        },
        401: {
          message: {
            text: "Missing or invalid Authorization header, or invalid token",
            type: "error",
          },
        },
        403: {
          message: {
            text: "Forbidden",
            type: "error",
          },
        },
      },
    },
  })

  useAsyncEffect(async () => {
    if (data && data?.length > 0 && data?.[0]?.score !== undefined) {
      setResultsIsReady(true)

      setLoading(false)

      jsConfetti.addConfetti().catch((err) => {
        console.log(err)
      })
    }
  }, [data])

  useAsyncEffect(async () => {
    if (resultTry > 3 && data && data?.length === 0) {
      setResultsIsReady(true)
      setLoading(false)

      await handleNext()

      setResultTry(4)

      jsConfetti.addConfetti().catch((err) => {
        console.log(err)
      })

      return
    }

    setResultTry(resultTry + 1)
  }, [data])

  return loading ? (
    <Box sx={{ ...CENTERED_FLEX_COL }}>
      <LbIaLoader progress={0.98} />
    </Box>
  ) : (
    <LbQuery
      isError={isError}
      isLoading={isLoading}
      skeleton={<OnboardingScreenSkeletons />}
      content={
        <Step
          stepIndex={4}
          title="🔬 Here we have some leads that might capture your interest."
          ctaTitle="See all leads"
          onValidate={handleNext}
          sx={{
            maxWidth: "900px",
            width: "900px",
            margin: "0 auto",
          }}
        >
          <Box
            className="hide-scrollbar"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 2,
                  }}
                >
                  Score
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 2,
                  }}
                >
                  Company
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography
                  color="text.secondary"
                  variant="h6"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 2,
                  }}
                >
                  Description
                </Typography>
              </Grid>
            </Grid>

            {data?.map((row, index) => (
              <LbResultRow key={row.id} row={row} index={index} />
            ))}
          </Box>
        </Step>
      }
    />
  )
}
