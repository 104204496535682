import { AppStatus, useAuth } from "@hooks/useAuth"
import { useGetWindowSize } from "@hooks/useGetWindowSize"
import { usePostHog } from "@hooks/usePostHog"
import { useRedirectUnauthenticatedUser } from "@hooks/useRedirectUnauthenticatedUser"
import { useUpdateApp } from "@hooks/useUpdateApp"
import { AppRoutes } from "@routes/AppRoutes"

export const App = () => {
  const { appStatus } = useAuth()

  useGetWindowSize()

  useUpdateApp()

  usePostHog()

  useRedirectUnauthenticatedUser()

  if (appStatus === AppStatus.Idle) return null

  return <AppRoutes />
}
