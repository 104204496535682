import {
  leadbayApi,
  useGetLensesByLensIdLeadsTrashQuery,
  type LeadOrder,
  type NewableLead,
} from "@api/leadbayApi"
import { LbDatableCell } from "@components/display/LbDatatableCell/LbDatatableCell"
import { LbTrashSearch } from "@components/inputs/LbTrashSearch/LbTrashSearch"
import {
  APP_DRAWER_WIDTH,
  CENTERED_FLEX_COL,
  CENTERED_FLEX_ROW,
} from "@constants/index"
import AnimatedNumber from "@crossfox/react-animated-number"
import { useAppDispatch } from "@hooks/useAppDispatch"
import { useAppSelector } from "@hooks/useAppSelector"
import {
  ChevronLeft,
  ChevronRight,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material"
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import {
  DataGrid,
  useGridApiRef,
  type GridPaginationModel,
  type GridRowParams,
  type GridSortModel,
} from "@mui/x-data-grid"
import {
  selectCommonsState,
  setPaginationModel,
  toggleNavDrawer,
} from "@redux/commonsSlice"
import { motion } from "framer-motion"
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
  type SyntheticEvent,
} from "react"
import { toast } from "react-toastify"
import { generateWishlistRows } from "utils/generateWishListRows"

const TrashScreen = () => {
  const dispatch = useAppDispatch()

  const { currentLensId } = useAppSelector(selectCommonsState)

  const [gridSortModel, setGridSortModel] = useState<GridSortModel>([])
  const [sortModel, setSortModel] = useState<LeadOrder[] | undefined>()
  const [currentFilter, setCurrentFilter] = useState<string | undefined>()

  const {
    paginationModel,
    pageSize,
    navDrawerIsOpen,
    navDrawerPartial,
    drawerData,
  } = useAppSelector(selectCommonsState)

  const { data, isFetching, isLoading, error } =
    useGetLensesByLensIdLeadsTrashQuery(
      {
        lensId: currentLensId,
        count: pageSize,
        page: paginationModel.page,
        q: currentFilter,
        order: sortModel,
      },
      {
        skip: !currentLensId,
      },
    )

  useEffect(() => {
    if (error) {
      // @ts-ignore:next-line
      toast.error((error?.data?.error?.code as string) ?? "An error occurred")
    }
  }, [error])

  const [rowCountState, setRowCountState] = useState(
    data?.pagination.total || 0,
  )
  useEffect(() => {
    if (!pageSize) return

    dispatch(
      setPaginationModel({
        pageSize,
        page: 0,
      }),
    )
  }, [pageSize])

  const handleSetPaginationModel = (params: GridPaginationModel) => {
    const { page, pageSize } = params

    dispatch(
      setPaginationModel({
        page,
        pageSize,
      }),
    )
  }

  const onSortChange = useCallback((filterModel: GridSortModel) => {
    try {
      if (!filterModel || !filterModel.length) {
        setSortModel(undefined)
        return
      }

      const field = filterModel?.[0]?.field?.toUpperCase()
      const sort = filterModel?.[0]?.sort?.toUpperCase()

      let generatedValue: LeadOrder | undefined

      if (field === "LIKED") {
        generatedValue = sort === "ASC" ? `DISLIKED:DESC` : `LIKED:DESC`
      } else {
        switch (field) {
          case "ID":
            generatedValue = `ID:${sort}` as LeadOrder
            break
          case "NEW":
            generatedValue = `NEW:${sort}` as LeadOrder
            break
          case "NAME":
            generatedValue = `NAME:${sort}` as LeadOrder
            break
          case "SCORE":
            generatedValue = `SCORE:${sort}` as LeadOrder
            break
          case "SECTOR":
            generatedValue = `SECTOR:${sort}` as LeadOrder
            break
          case "SIZE":
            generatedValue = `SIZE:${sort}` as LeadOrder
            break
          case "STATUS":
            generatedValue = `STATUS:${sort}` as LeadOrder
            break
          case "WEBSITE":
            generatedValue = `WEBSITE:${sort}` as LeadOrder
            break
          default:
            generatedValue = undefined
            break
        }
      }

      setSortModel(generatedValue ? [generatedValue] : undefined)

      dispatch(leadbayApi.util.invalidateTags(["Leads"]))
    } catch (error) {
      setGridSortModel(filterModel)
      setSortModel(undefined)
      console.error(error)
    } finally {
      setGridSortModel(filterModel)
    }
  }, [])

  const getWishListData = useMemo(() => data, [data])

  const handleToggleDrawer = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()

    dispatch(
      toggleNavDrawer({
        isOpen: !navDrawerIsOpen,
        partial: navDrawerPartial,
        data: drawerData,
      }),
    )
  }

  const handleToggleLeadInfos = (dataGrid: GridRowParams) => {
    if (getWishListData?.items.length === 0) return

    dispatch(
      toggleNavDrawer({
        isOpen: true,
        partial: "LEAD_INFOS",
        data: dataGrid,
      }),
    )
  }

  const apiRef = useGridApiRef()

  useEffect(() => {
    try {
      if (data && !data.items.length) {
        dispatch(
          toggleNavDrawer({
            isOpen: false,
            partial: "LEAD_INFOS",
          }),
        )
      }

      if (data?.items && data?.items?.length > 0) {
        apiRef.current.selectRow(data.items[0].id)

        const firstRowParams = apiRef.current.getRowParams(data.items[0].id)

        dispatch(
          toggleNavDrawer({
            isOpen: true,
            partial: "LEAD_INFOS",
            data: firstRowParams,
          }),
        )
      }
    } catch (error) {
      console.error(error)
    }
  }, [data])

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.pagination.total !== undefined
        ? data?.pagination.total
        : prevRowCountState,
    )
  }, [setRowCountState, data?.pagination.total])

  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentFilter(event.target.value)
  }

  const tablesRows = useMemo(() => {
    if (!getWishListData?.items) return []
    if (!currentFilter) return getWishListData.items
    else
      return getWishListData?.items?.filter((lead) =>
        lead.name.toLowerCase().includes(currentFilter.toLowerCase()),
      )
  }, [getWishListData, currentFilter])

  const rows = generateWishlistRows(tablesRows)

  const showSkeleton = !isLoading

  return showSkeleton ? (
    <Box
      id="trash-screen"
      sx={{
        display: "flex",
        alignItems: "center",
        height: "calc(100vh - 30px)",
        position: "relative",
        flex: 1,
      }}
      className="hide-scrollbar"
    >
      <Box
        className="hide-scrollbar"
        component="article"
        sx={{
          height: "calc(100vh - 30px)",
          width: `calc(100vw - ${navDrawerIsOpen ? APP_DRAWER_WIDTH : "0px"})`,
          maxWidth: "1600px",
          mx: "auto",
          overflowY: "auto",
          flex: 1,
          px: "40px",
          pt: "30px",
        }}
      >
        <LbTrashSearch onSearch={handleSearch} searchValue={currentFilter} />

        <Box
          sx={{
            position: "relative",
            flex: 1,
            minWidth: "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {tablesRows.length > 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              style={{ width: "100%", height: "100%" }}
            >
              <DataGrid
                apiRef={apiRef}
                sortModel={gridSortModel}
                sortingMode="server"
                paginationMode="server"
                loading={isFetching}
                onSortModelChange={onSortChange}
                onRowClick={handleToggleLeadInfos}
                onPaginationModelChange={handleSetPaginationModel}
                rowCount={rowCountState}
                paginationModel={paginationModel}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
                slots={{
                  toolbar: undefined,
                }}
                sx={{
                  borderRadius: 0,
                  border: 0,
                  m: 0,
                  backgroundColor: "#fff",
                  ".MuiDataGrid-columnHeaderTitle": {
                    color: "text.secondary",
                  },
                }}
                getRowClassName={() => "lead-row"}
                pagination
                // @ts-ignore:next-line
                experimentalFeatures={{ clipboardPaste: true }}
                unstable_ignoreValueFormatterDuringExport
                disableColumnFilter
                disableColumnMenu
                rowHeight={45}
                rows={rows}
                columns={[
                  {
                    field: "score",
                    headerName: "Score",
                    description: "The score of the lead",
                    type: "number",
                    sortable: true,

                    align: "center",
                    headerAlign: "left",
                    width: 80,
                    renderCell: (params) => {
                      const [localesSore, setLocalScore] = useState(0)

                      useEffect(() => {
                        setLocalScore(params.value as number)
                      }, [params.value])

                      return (
                        <>
                          {params.value > 0 &&
                            // @ts-ignore:next-line
                            params.row.state?.status !== "WON" &&
                            // @ts-ignore:next-line
                            params.row.state?.status !== "LOST" && (
                              <Box
                                sx={{
                                  ...CENTERED_FLEX_COL,
                                  width: "40px",
                                  margin: "auto",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: "900",
                                    mb: 0.2,
                                    fontFamily: "BasementGrotesque",
                                    color: "primary.main",
                                  }}
                                >
                                  <AnimatedNumber
                                    value={localesSore}
                                    duration={300}
                                    suffix="&nbsp;"
                                  />
                                </Typography>

                                <LinearProgress
                                  color="success"
                                  sx={{ width: "100%", height: 5 }}
                                  variant="determinate"
                                  value={localesSore}
                                />
                              </Box>
                            )}
                        </>
                      )
                    },
                  },
                  {
                    field: "new",
                    headerName: "New",
                    description:
                      "New leads are leads that have been added to the system.",
                    sortable: false,
                    width: 20,
                    align: "center",
                    sortingOrder: ["desc", "asc"],
                    sortComparator: (v1, v2, param1, param2) => {
                      const value1 = param1.value.new ? 1 : 0
                      const value2 = param2.value.new ? 1 : 0

                      return value2 - value1
                    },
                    renderCell: (params) => {
                      return (
                        params.value === true && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={[
                                (theme) => ({
                                  background: theme.palette.success.main,
                                  width: 7,
                                  height: 7,
                                  borderRadius: "100%",
                                }),
                              ]}
                            />
                          </Box>
                        )
                      )
                    },
                  },
                  {
                    field: "name",
                    headerName: "Name",
                    sortable: true,

                    description: "The name of the lead",
                    minWidth: 250,
                    renderCell: (params) => {
                      const row = params.row as unknown as NewableLead

                      return (
                        <Box
                          width="100%"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              ...CENTERED_FLEX_COL,
                              height: "20px",
                            }}
                          >
                            {row.logo && (
                              <img
                                style={{
                                  objectFit: "contain",
                                  height: "100%",
                                  marginRight: "8px",
                                  borderRadius: "5px",
                                }}
                                src={row.logo}
                                alt={params.value}
                              />
                            )}
                          </Box>

                          <Typography
                            variant="subtitle2"
                            width="100%"
                            overflow="hidden"
                            sx={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                              fontFamily: "Hanken Grotesk",
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Box>
                      )
                    },
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    description: "The status of the lead",
                    sortable: true,
                    width: 85,
                    renderCell: (params) => {
                      const status = params?.value?.status

                      const renderChip = (
                        label: string,
                        color: "default" | "info" | "warning",
                      ) => (
                        <Box
                          sx={{
                            ...CENTERED_FLEX_COL,
                            width: "100%",
                          }}
                        >
                          <Chip
                            label={<Box sx={{ px: 2 }}>{label}</Box>}
                            sx={{
                              fontWeight: 700,
                              fontSize: "0.7rem",
                              paddingLeft: "8px !important",
                              paddingRight: "8px !important",
                            }}
                            color={color}
                            size="small"
                          />
                        </Box>
                      )

                      if (status) {
                        return <>{renderChip(status, "default")}</>
                      } else {
                        return ""
                      }
                    },
                  },
                  {
                    field: "sector",
                    flex: 1,
                    headerName: "Sector",
                    description: "The sector of the lead",
                    sortable: true,

                    renderCell: ({ row, value }) => (
                      <LbDatableCell
                        type="SECTOR"
                        row={row as unknown as NewableLead}
                        value={value}
                      />
                    ),
                  },
                  {
                    field: "location",
                    flex: 1,
                    headerName: "Location",
                    description: "The location of the lead",
                    sortable: false,

                    renderCell: ({ row, value }) => (
                      <LbDatableCell
                        type="LOCATION"
                        row={row as unknown as NewableLead}
                        value={value}
                      />
                    ),
                  },
                  {
                    field: "size",
                    headerName: "Size",
                    headerAlign: "center",
                    description: "The size of the lead",
                    align: "right",
                    minWidth: 70,
                    sortable: true,
                    sortComparator: (v1, v2, param1, param2) => {
                      return param2.value?.[1] - param1.value?.[1]
                    },
                    renderCell: ({ row, value }) => (
                      <LbDatableCell
                        type="SIZE"
                        row={row as unknown as NewableLead}
                        value={value}
                      />
                    ),
                  },
                  {
                    field: "liked",
                    headerName: "",
                    align: "right",
                    description: "Displays if the lead is a favorite",
                    sortable: true,
                    width: 50,
                    sortComparator: (v1, v2, param1, param2) => {
                      const likedValue1 = param1.value.liked
                        ? 1
                        : param1.value.disliked
                          ? -1
                          : 0
                      const likedValue2 = param2.value.liked
                        ? 1
                        : param2.value.disliked
                          ? -1
                          : 0
                      return likedValue2 - likedValue1
                    },
                    renderCell: (params) => {
                      const row = params.row as unknown as NewableLead

                      const icon = row?.liked ? (
                        <Box>
                          <ThumbUp
                            color="primary"
                            sx={{ fontSize: "0.9rem" }}
                          />
                        </Box>
                      ) : row?.disliked ? (
                        <Typography>
                          <ThumbDown
                            color="primary"
                            sx={{ fontSize: "0.9rem" }}
                          />
                        </Typography>
                      ) : (
                        ""
                      )

                      return <Box sx={{ ...CENTERED_FLEX_COL }}>{icon}</Box>
                    },
                  },
                ]}
              />
            </motion.div>
          ) : (
            <motion.div>
              <Box
                sx={{
                  ...CENTERED_FLEX_COL,

                  width: "100%",
                  height: "calc(100vh - 200px)",
                }}
              >
                <Box
                  sx={{
                    ...CENTERED_FLEX_COL,
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      ...CENTERED_FLEX_ROW,
                      transform: "translateY(-5vh)",
                    }}
                  >
                    <Typography sx={{ marginLeft: 1 }}>
                      No leads in the Trash
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          )}
        </Box>
      </Box>

      {data && data?.items?.length > 0 && (
        <Box sx={{ position: "absolute", right: 0 }}>
          <IconButton size="small" onClick={handleToggleDrawer}>
            {!navDrawerIsOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        height: window.innerHeight,
        width: "calc(100vw - 383px)",
        px: "40px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%", mb: 3, mt: 10 }}
        height={50}
      />

      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={window.innerHeight - 300}
      />
    </Box>
  )
}

export default TrashScreen
